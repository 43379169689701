/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo } from 'react';

import Select from '../../../components/form/Select';
import PERMISSION from '../../../constants/Permissions';
import { UNIT_ASSIGNMENT_MODALS } from '../../../constants/UnitAssignment';
import { SHIFT_STATUS } from '../../../constants/ShiftStatus';
import Utils from '../../../utils/utils';

const ActionsDropdown = ({
	canGuarantee,
	getUnitName,
	hasAvailableUnits,
	hasUnitsEnabled,
	onCancel,
	onConfirm,
	onGuaranteedCheckboxChange,
	onGuaranteeBundleChange,
	permissions,
	setShowUnitAssignmentModal,
	setUnitAssignmentSlot,
	slot,
	isFlex = false
}) => {
	const hasEditAssignmentPermission = useMemo(() => Utils.isAuthorizedExact(PERMISSION.EDIT_ASSIGNMENT, permissions), [
		permissions
	]);
	const hasEditStatusPermission = useMemo(() => Utils.isAuthorizedExact(PERMISSION.EDIT, permissions), [permissions]);
	const hasFlexCancelPermission = useMemo(() => Utils.isAuthorizedExact(PERMISSION.FLEX_CANCEL, permissions), [
		permissions
	]);
	const hasCancelPermission = useMemo(
		() => Utils.isAuthorizedExact(PERMISSION.CANCEL, permissions) && hasEditStatusPermission,
		[hasEditStatusPermission, permissions]
	);
	const hasToggleGuaranteePermission = useMemo(
		() => Utils.isAuthorizedExact(PERMISSION.GUARANTEE, permissions) && hasEditStatusPermission,
		[hasEditStatusPermission, permissions]
	);

	const hasFlexEditUnitAssignmentPermission = useMemo(
		() => Utils.isAuthorizedExact(PERMISSION.FLEX_ASSIGN_UNITS, permissions),
		[permissions]
	);

	const canGuaranteeSlot = !isFlex && canGuarantee && !slot.caregiver_id && !slot.is_cancelled;
	const slotHasBeenWorked = slot.caregiver_id && slot.outcome_id;
	const HCPHasCheckedIn = slot.outcome_code === SHIFT_STATUS.ARRIVED.toUpperCase();
	const HCPHasConfirmed = slot.slot_status?.toUpperCase() === SHIFT_STATUS.CONFIRMED.toUpperCase();
	const canConfirmSlot = !isFlex && !slot.is_cancelled && slot.can_confirm && !slotHasBeenWorked && !HCPHasConfirmed;
	const isReplacement = !!slot.replaced_caregiver_name;
	const hasContract = !!slot.contract_id;
	const canCancelOnDemandSlot =
		!isFlex &&
		!slot.is_cancelled &&
		(slot.caregiver_id || !HCPHasConfirmed || isReplacement || hasContract) &&
		(!slot.outcome_id || HCPHasCheckedIn);
	const canCancelFlexSlot = isFlex && !slot.outcome_id;
	const canEditAssignment = !isFlex && hasUnitsEnabled && hasAvailableUnits;

	const getGuaranteeActionLabel = () => {
		if (hasContract) {
			return slot.is_guaranteed ? 'Remove Bundle Guarantee' : 'Guarantee Bundle';
		}
		return slot.is_guaranteed ? 'Remove Guarantee' : 'Guarantee Shift';
	};

	const getGuaranteeActionHandler = () => {
		if (hasContract) {
			return onGuaranteeBundleChange([slot]);
		}
		return onGuaranteedCheckboxChange([slot]);
	};

	const canAssigFlexUnits =
		isFlex && hasFlexEditUnitAssignmentPermission && hasUnitsEnabled && hasAvailableUnits && slot.caregiver_id;

	const ACTION_ITEMS = useMemo(
		() => [
			{
				label: 'Confirm',
				value: 'CONFIRM',
				onSelect: () => onConfirm(slot.slot_id),
				filter: () => hasEditStatusPermission && canConfirmSlot
			},
			{
				label: 'Edit Assignment',
				value: 'EDIT_ASSIGNMENT',
				onSelect: () => {
					setUnitAssignmentSlot({ unit_name: getUnitName(slot.unit_id), ...slot });
					setShowUnitAssignmentModal(
						HCPHasCheckedIn ? UNIT_ASSIGNMENT_MODALS.editArrived : UNIT_ASSIGNMENT_MODALS.edit
					);
				},
				filter: () => (hasEditAssignmentPermission && canEditAssignment) || canAssigFlexUnits
			},
			{
				label: 'Remove Assignment',
				value: 'REMOVE_ASSIGNMENT',
				onSelect: () => {
					setUnitAssignmentSlot({ unit_name: getUnitName(slot.unit_id), ...slot });
					setShowUnitAssignmentModal(
						HCPHasCheckedIn ? UNIT_ASSIGNMENT_MODALS.unassignArrived : UNIT_ASSIGNMENT_MODALS.unassign
					);
				},
				filter: () => ((hasEditAssignmentPermission && canEditAssignment) || canAssigFlexUnits) && slot.unit_id
			},
			{
				label: getGuaranteeActionLabel(),
				value: 'GUARANTEE_SLOT',
				onSelect: getGuaranteeActionHandler,
				filter: () => hasToggleGuaranteePermission && canGuaranteeSlot
			},
			{
				label: 'Cancel',
				value: 'CANCEL',
				onSelect: () => onCancel(slot),
				filter: () => (hasFlexCancelPermission && canCancelFlexSlot) || (hasCancelPermission && canCancelOnDemandSlot)
			}
		],
		[
			slot,
			onConfirm,
			hasEditStatusPermission,
			canConfirmSlot,
			setUnitAssignmentSlot,
			getUnitName,
			setShowUnitAssignmentModal,
			HCPHasCheckedIn,
			hasEditAssignmentPermission,
			canEditAssignment,
			onGuaranteedCheckboxChange,
			hasToggleGuaranteePermission,
			canGuaranteeSlot,
			onCancel,
			hasFlexCancelPermission,
			canCancelFlexSlot,
			hasCancelPermission,
			canCancelOnDemandSlot,
			canAssigFlexUnits
		]
	);

	const handleStatusChange = useCallback(
		({ updatedStatus: action }) => {
			const option = ACTION_ITEMS.find((_option) => _option.value === action);
			if (option) {
				option.onSelect();
			}
		},
		[ACTION_ITEMS]
	);

	const filteredOptions = ACTION_ITEMS.filter((option) => option.filter()).map((option) => ({
		label: option.label,
		value: option.value
	}));

	return (
		<div className="col text-sm-center">
			<Select
				className="select-status-daily-schedule"
				handleChange={handleStatusChange}
				isDisabled={!filteredOptions.length > 0}
				name="updatedStatus"
				options={filteredOptions}
				placeholder="Actions"
				value={slot.status}
			/>
		</div>
	);
};
export default ActionsDropdown;
