import React, { memo } from 'react';
import classNames from 'classnames';
import { AppsIcon, CaretIcon } from '../icons';
import DropdownMenu from '../form/Dropdown';
import UserAvatar from '../Avatar/UserAvatar';

const NavbarMenu = ({ userData, rightMenuRef, menuOpen, menuItems, toggleMenu }) => (
	<div ref={rightMenuRef} className="navbar-menus">
		<div className="vertical-separator" />

		{!userData.agencyId && (
			<div className={classNames('apps-section', { active: menuOpen.apps })}>
				<button
					className="apps-section__avatar"
					key="user-avatar"
					onClick={() => toggleMenu('apps')}
					aria-label="Toggle apps menu"
					type="button"
				>
					<AppsIcon width={34} height={34} />
				</button>
				<DropdownMenu className="apps-section__dropdown" open={menuOpen.apps} menuItems={menuItems.AppsSection} />
			</div>
		)}

		<div className={classNames('user-section', { active: menuOpen.user })}>
			{(userData.name || userData.email) && (
				<>
					<button
						className="user-section__avatar"
						onClick={() => toggleMenu('user')}
						aria-label="Toggle user menu"
						type="button"
					>
						<UserAvatar initials={userData.initials} />
						<CaretIcon pointTo={menuOpen.user ? 'up' : 'down'} className="user-section__carat" width={16} height={16} />
					</button>
					<DropdownMenu
						className="user-section__dropdown"
						open={menuOpen.user}
						header={
							<ul>
								<li className="bold">{userData.name}</li>
								<li>{userData.email}</li>
							</ul>
						}
						menuItems={menuItems.UserSection}
					/>
				</>
			)}
		</div>
	</div>
);

export default memo(NavbarMenu);
