/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import ProfessionalRow from './ProfessionalRow';
import './AvailableProfessionals.css';

const AvailableProfessionals = ({ professionals, addSelectedProfessional, selectedProfessionals }) => {
	const [checkedProfessionalId, setCheckedProfessionalId] = useState(null);

	const handleCheked = (event) => {
		setCheckedProfessionalId(event.target.value);
	};

	useEffect(() => {
		if (checkedProfessionalId) {
			const checkedProfessional = professionals.find(
				(professional) => professional.caregiver_id === checkedProfessionalId
			);

			addSelectedProfessional(checkedProfessional);
		}
	}, [professionals, checkedProfessionalId]);

	useEffect(() => {
		const [selectedProfessional] = selectedProfessionals;

		if (!selectedProfessional && checkedProfessionalId) {
			setCheckedProfessionalId(null);
			return;
		}
		if (selectedProfessional && !checkedProfessionalId) {
			const foundProfessional = professionals.find(
				(professional) => professional.caregiver_id === selectedProfessional.caregiver_id
			);
			if (foundProfessional) {
				setCheckedProfessionalId(foundProfessional.caregiver_id);
			}
		}
	}, [selectedProfessionals]);

	return (
		<section className="available-professionals__container">
			<div className="available-professionals-header">
				<h4>
					<span>Available Professionals</span>
				</h4>
				<span className="available-professionals__container__subtitle">
					These professionals are available for this shift and have no other scheduled shifts at this time.
				</span>
			</div>
			<div disabled={selectedProfessionals?.length} className="available-professionals__container__form">
				{professionals.length ? (
					professionals.length &&
					professionals.map((professional) => (
						<Form.Check
							className="available-professionals__container__form__item"
							key={`check--${professional.caregiver_id}`}
						>
							<Form.Check.Input
								style={{ backgroundColor: 'black' }}
								id={`check--${professional.caregiver_id}`}
								type="radio"
								value={professional.caregiver_id}
								onChange={handleCheked}
								checked={checkedProfessionalId === professional.caregiver_id}
							/>
							<Form.Check.Label htmlFor={`check--${professional.caregiver_id}`}>
								<ProfessionalRow professional={professional} />
							</Form.Check.Label>
						</Form.Check>
					))
				) : (
					<div className="available-professionals__container__empty">
						No professionals submitted availability that match this shift. You can still search and select a
						professional to assign above.
					</div>
				)}
			</div>
		</section>
	);
};

export default AvailableProfessionals;
