import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import PRIVATE_PATH from '../../constants/Paths';
import { showNotification } from '../../redux/notification';
import { useGetAllReportsListQuery } from '../../redux/reports';
import LoadingSpinner from '../../components/spinner/LoadingSpinner';
import './ReportScreen.scss';

const ReportsScreen = () => {
	const dispatch = useDispatch();
	const { isLoading, isFetching, data, error } = useGetAllReportsListQuery(null, {
		refetchOnMountOrArgChange: true
	});

	useEffect(() => {
		if (error && !isLoading && !isFetching) {
			dispatch(showNotification('error', 'Failed to load reports.'));
		}
	}, [error, isLoading, isFetching, dispatch]);

	const groupedData = useMemo(
		() =>
			data?.reduce((acc, report) => {
				const { category_code: code, category_name: name } = report;
				if (!acc[code]) {
					acc[code] = { name, code, reports: [] };
				}
				acc[code].reports.push(report);
				return acc;
			}, {}),
		[data]
	);

	const sortedData = useMemo(() => Object.values(groupedData || {}).sort((a, b) => a.name.localeCompare(b.name)), [
		groupedData
	]);

	if (!error && (isLoading || isFetching)) {
		return <LoadingSpinner />;
	}

	return (
		!error && (
			<div id="report-screen-container" className="report-screen-container">
				<div className="report-screen-page">
					<div className="report-screen-header">
						<div className="report-screen-title">
							<div className="report-screen-title-label with-top-margin">Reports</div>
						</div>
					</div>
					<div className="report-screen-content">
						{sortedData.map((category) => (
							<div key={category.code} className="report-list-section">
								<div className="report-list-category">{category.name}</div>
								<table className="report-list-table table table-bordered">
									<thead>
										<tr>
											<th scope="col">Report Name</th>
											<th scope="col">Description</th>
										</tr>
									</thead>
									<tbody>
										{category.reports
											.sort((a, b) => a.name.localeCompare(b.name))
											.map((report) => (
												<tr key={report.code}>
													<td className="report-name">
														<Link to={`${PRIVATE_PATH.REPORTS}/${report.code}`}>{report.name}</Link>
													</td>
													<td className="report-description">{report.description}</td>
												</tr>
											))}
									</tbody>
								</table>
							</div>
						))}
					</div>
				</div>
			</div>
		)
	);
};

export default ReportsScreen;
