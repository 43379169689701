import React, { useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import WithOnlyOnDesktop from '../../components/onlyOnDesktop/withOnlyOnDesktop';

import './ExternalAgencyScreen.scss';
import './AgencyProfessionalsList.scss';
import AgencyProfessionalsList from './AgencyProfessionalsList';

const AgencyProfessionals = () => {
	const [activeTabIndex, setActiveTabIndex] = useState(0);

	return (
		<WithOnlyOnDesktop>
			<div className="external-agency-screen external-agency-professionals">
				<div className="title">Professionals</div>
				<TabView activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>
					<TabPanel header="All">
						<AgencyProfessionalsList />
					</TabPanel>
				</TabView>
			</div>
		</WithOnlyOnDesktop>
	);
};

export default AgencyProfessionals;
