/* eslint-disable no-param-reassign */
import api from './api';

const apiWithTags = api.enhanceEndpoints({
	addTagTypes: ['ExternalAgencyShifts']
});

const externalAgencyShiftsAPI = apiWithTags.injectEndpoints({
	tagTypes: ['ExternalAgencyShifts'],
	endpoints: (build) => ({
		externalAgencyOpenShifts: build.query({
			query: ({ start, end }) => `/agencies/shifts?start=${start}&end=${end}`,
			providesTags: ['ExternalAgencyShifts']
		})
	})
});

export const { useExternalAgencyOpenShiftsQuery } = externalAgencyShiftsAPI;

export default externalAgencyShiftsAPI;
