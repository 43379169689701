/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import api from './api';

const apiWithTags = api.enhanceEndpoints({
	addTagTypes: ['GlobalOverviewShifts']
});

const globalOverviewAPI = apiWithTags.injectEndpoints({
	tagTypes: ['GlobalOverviewShifts'],
	endpoints: (build) => ({
		shifts: build.query({
			query: ({ start, end }) => `/flex/global-overview/shifts?start=${start}&end=${end}`,
			providesTags: ['GlobalOverviewShifts']
		}),
		reopenShift: build.mutation({
			query: ({ slot }) => ({
				url: `/flex/slot/${slot.slot_id}/reopen`,
				method: 'PUT',
				headers: {
					management_company_id: slot.management_company_id
				},
				responseHandler: async (response) => {
					const responseText = await response.text();
					return responseText === 'OK' ? responseText : JSON.parse(responseText);
				}
			}),
			invalidatesTags: ['GlobalOverviewShifts']
		})
	})
});

export const { useShiftsQuery, useReopenShiftMutation } = globalOverviewAPI;

export const slice = createSlice({
	name: 'globalOverview',
	initialState: {
		periodIndex: 0,
		selectedFacilities: [],
		shiftStatusFilter: null
	},
	reducers: {
		savePeriodIndex: (state, action) => {
			state.periodIndex = action.payload;
		},
		saveSelectedFacilities: (state, action) => {
			state.selectedFacilities = action.payload;
		},
		saveShiftStatusFilter: (state, action) => {
			state.shiftStatusFilter = action.payload;
		}
	}
});

export const globalOverviewState = (state) => state.globalOverview;

export const { savePeriodIndex, saveSelectedFacilities, saveShiftStatusFilter } = slice.actions;

export default slice.reducer;
