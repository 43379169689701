import React from 'react';
import { Modal } from 'react-bootstrap';
import './FlexRequestShiftConfirmationModal.scss';

const FlexRequestShiftConfirmationModal = ({
	title,
	textContent,
	boxTextContent,
	primaryActionLabel,
	primaryActionDisabled,
	onPrimaryAction,
	secondaryActionLabel,
	secondaryActionDisabled,
	onSecondaryAction,
	onHide,
	showModalFooter = true
}) => {
	return (
		<Modal
			size="lg"
			show
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onHide={onHide}
			backdropClassName="modal-backdrop-no-show"
			className="flex-request-shift-modal"
		>
			<Modal.Header closeButton>
				<div>
					<h1 className="title">{title}</h1>
				</div>
				<div className="header-close-spacer" />
			</Modal.Header>
			<Modal.Body>
				<div className="text-content">{textContent}</div>
				<div className="box-text-content">{boxTextContent}</div>
			</Modal.Body>

			{showModalFooter && (
				<Modal.Footer>
					<button
						type="button"
						className="footer-buttons secondary-button"
						disabled={secondaryActionDisabled}
						onClick={onSecondaryAction}
					>
						{secondaryActionLabel}
					</button>
					<button
						type="button"
						className="footer-buttons primary-button"
						disabled={primaryActionDisabled}
						onClick={onPrimaryAction}
					>
						{primaryActionLabel}
					</button>
				</Modal.Footer>
			)}
		</Modal>
	);
};

export default FlexRequestShiftConfirmationModal;
