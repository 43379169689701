import React, { memo, useState } from 'react';
import moment from 'moment-timezone';
import classNames from 'classnames';
import BaseModal from '../../../components/modals/BaseModal/BaseModal';
import './GuaranteeBundleModal.scss';
import Checkbox from '../../../components/form/Checkbox';
import { ArrowLeftIcon, BadgeIcon, BulbIcon, ErrorIcon } from '../../../components/icons';

const GuaranteeBundleModal = ({
	bundleSlots = [],
	onSaveChanges = () => {},
	onCancel = () => {},
	bundleSelectedSlot = {}
}) => {
	const [acknowledgement, setAcknowledgment] = useState(false);
	const [tableViewExpanded, setTableViewExpanded] = useState(false);
	const contractId = bundleSelectedSlot.contract_id;
	const isGuaranteed = bundleSelectedSlot.is_guaranteed;

	const firstShift = bundleSlots[0];
	const lastShift = bundleSlots[bundleSlots.length - 1];
	const weeksCount =
		bundleSlots.length > 1
			? Math.ceil(
					moment.duration(moment.utc(lastShift.raw_start_time).diff(moment.utc(firstShift.raw_start_time))).asWeeks()
			  )
			: 1;

	const slotsToUpdate = bundleSlots.reduce((acc, slot) => {
		acc[slot.slot_id] = slot.is_guaranteed;
		return acc;
	}, {});

	const title = !isGuaranteed ? 'Guarantee Bundle?' : 'Remove Guarantee from this Bundle?';

	const guaranteeDetails = isGuaranteed ? (
		<div className="guarantee-section">
			<div className="section-icon">
				<ErrorIcon />
			</div>
			<div className="section-text">
				<p>
					<b>Removing the Guaranteed Shifts® mean you’re missing out</b>
				</p>
				<p>Guaranteed Bundles achieve 98% arrival rates, boosting both nurse and patient satisfaction.</p>
			</div>
		</div>
	) : (
		<div className="guarantee-section">
			<div className="section-icon">
				<BulbIcon />
			</div>
			<div className="section-text">
				<p>
					<b>Why guarantee a bundle?</b>
				</p>
				<p>Guaranteed Bundles achieve 98% arrival rates, boosting both nurse and patient satisfaction.</p>
			</div>
		</div>
	);

	const expandTableViewButton = (
		<div className="mobile-button-container">
			{!tableViewExpanded ? (
				<button
					type="button"
					className="expand-table-buttons secondary-button"
					onClick={() => setTableViewExpanded(!tableViewExpanded)}
				>
					Expand Table View
				</button>
			) : (
				<button
					type="button"
					className="expand-table-buttons primary-button"
					onClick={() => setTableViewExpanded(!tableViewExpanded)}
				>
					<ArrowLeftIcon /> Shrink Table View
				</button>
			)}
		</div>
	);

	const acknowledgmentText = !isGuaranteed
		? 'I acknowledge that by guaranteeing this bundle, I am committing pay for the caregiver even if I choose to cancel any of these shifts after a healthcare professional has claimed them.'
		: 'I acknowledge that by removing the guaranteed shifts from this bundle, I am reducing the chance of this bundle being claimed by a healthcare professional.';

	return (
		<BaseModal
			key="guarantee-bundle-modal"
			title={title}
			className={classNames('guarantee-bundle-modal', { 'remove-guarantee': isGuaranteed })}
			primaryActionLabel={!isGuaranteed ? 'Guarantee Bundle' : 'Remove Bundle Guarantee'}
			onPrimaryAction={() => onSaveChanges(contractId, slotsToUpdate, !isGuaranteed, acknowledgement)}
			primaryActionDisabled={!acknowledgement}
			secondaryActionLabel="Cancel"
			onSecondaryAction={onCancel}
			hideModalFooter={tableViewExpanded}
			onHide={onCancel}
		>
			<div className={classNames('info-header-container', { hidden: !tableViewExpanded })}>
				<div className="info-header-section">
					<p>Shift assignment</p>
					<p>
						<b>{bundleSelectedSlot.resource_type}</b>
					</p>
				</div>
				<div className="info-header-section">
					<p>Bundle length</p>
					<p>
						<b>
							{weeksCount} {weeksCount > 1 ? 'weeks' : 'week'}
						</b>
					</p>
				</div>
				<div className="info-header-section">
					<p>Number of shifts</p>
					<p>
						<b>{bundleSlots.length}</b>
					</p>
				</div>
			</div>

			<div className="slots-table">
				<div className={classNames('table-container', { expanded: tableViewExpanded })}>
					<table>
						<thead>
							<tr>
								<th>Date</th>
								<th>Shift</th>
								<th>Guarantee</th>
							</tr>
						</thead>
						<tbody>
							{bundleSlots.length > 0 ? (
								bundleSlots.map((slot) => {
									return (
										<tr key={`row-${slot.slot_id}`}>
											<td>
												{moment(slot.raw_start_time).format('MM/DD/YYYY')}, {slot.shift_day}
											</td>
											<td>
												{slot.start_time} - {slot.end_time}
											</td>
											<td className="guarantee-icon">{slot.is_guaranteed === true ? <BadgeIcon /> : ''}</td>
										</tr>
									);
								})
							) : (
								<tr>
									<td>No Data</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>

			{expandTableViewButton}
			{guaranteeDetails}

			<div className={classNames('aknowledgement-section', { hidden: tableViewExpanded })}>
				<Checkbox id="acknowledgment" handleChange={() => setAcknowledgment(!acknowledgement)} />
				<label htmlFor="acknowledgment">{acknowledgmentText}</label>
			</div>
		</BaseModal>
	);
};

export default memo(GuaranteeBundleModal);
