import api from './api';

const apiWithTags = api.enhanceEndpoints({
	addTagTypes: ['Professional', 'FlexWorker']
});

const professionalsApi = apiWithTags.injectEndpoints({
	tagTypes: ['Professional', 'FlexWorker'],
	endpoints: (build) => ({
		professionals: build.query({
			query: () => 'professionals'
		}),
		flexWorkers: build.query({
			query: () => 'flex/internal-staff',
			providesTags: ['FlexWorker']
		}),
		updateFlexWorkerStatus: build.mutation({
			query: ({ workerId, isDisabled }) => ({
				url: `/flex/internal-staff/${workerId}/facility-status`,
				method: 'POST',
				body: { isCaregiverDisabled: isDisabled }
			}),
			invalidatesTags: ['FlexWorker']
		})
	})
});

export const { useProfessionalsQuery, useFlexWorkersQuery, useUpdateFlexWorkerStatusMutation } = professionalsApi;

export default professionalsApi;
