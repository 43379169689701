import { memo } from 'react';
import { connect } from 'react-redux';

import utils from '../../utils/utils';

const mapStateToProps = (state) => {
	return {
		managementCompanyPermissions: state.user.managementCompanyPermissions
	};
};

const IsAuthorizedForManagementCompany = (props) => {
	let isAuthorized;

	if (props.exactPermission) {
		if (Array.isArray(props.exactPermission)) {
			isAuthorized = props.exactPermission.some((permission) =>
				utils.isAuthorizedExact(permission, props.managementCompanyPermissions[props.managementCompanyId])
			);
		} else {
			isAuthorized = utils.isAuthorizedExact(
				props.exactPermission,
				props.managementCompanyPermissions[props.managementCompanyId]
			);
		}
	}

	if (isAuthorized) {
		return props.children;
	}
	if (props.readOnlyRender) {
		return typeof props.readOnlyRender === 'function' ? props.readOnlyRender() : props.readOnlyRender;
	}
	return null;
};

export default connect(mapStateToProps, null)(memo(IsAuthorizedForManagementCompany));
