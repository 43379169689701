import { memo } from 'react';
import { connect } from 'react-redux';

import utils from '../../utils/utils';

const mapStateToProps = (state) => {
	return {
		globalManagementCompanyPermissions: state.user.globalManagementCompanyPermissions
	};
};

const IsAuthorizedForManagementCompanyGlobal = (props) => {
	let isAuthorized;

	if (props.exactPermission) {
		if (Array.isArray(props.exactPermission)) {
			isAuthorized = props.exactPermission.some((permission) =>
				utils.isAuthorizedExact(permission, props.globalManagementCompanyPermissions)
			);
		} else {
			isAuthorized = utils.isAuthorizedExact(props.exactPermission, props.globalManagementCompanyPermissions);
		}
	} else if (props.section && props.operation) {
		isAuthorized = utils.isAuthorized(props.section, props.operation, props.globalManagementCompanyPermissions);
	}

	if (isAuthorized) {
		return props.children;
	}
	if (props.readOnlyRender) {
		return typeof props.readOnlyRender === 'function' ? props.readOnlyRender() : props.readOnlyRender;
	}
	return null;
};

export default connect(mapStateToProps, null)(memo(IsAuthorizedForManagementCompanyGlobal));
