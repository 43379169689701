/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { loadFeed } from '../../../redux/feed';
import { useUpdateUnitMutation, useUpdateFlexUnitMutation, unwrapError } from '../../../redux/units';
import { showNotification } from '../../../redux/notification';

import { UNIT_ASSIGNMENT_MODALS } from '../../../constants/UnitAssignment';
import EditUnitModal from './EditUnitModal';
import UnassignUnitModal from './UnassignUnitModal';
import EditAlreadyArrivedModal from './EditAlreadyArrivedModal';
import UnassignAlreadyArrivedModal from './UnassignAlreadyArrivedModal';

import './UnitAssignment.scss';

const UnitAssignment = ({ availableUnits, setIsLoading, setShowModal, showModal, slot }) => {
	const {
		resource_rate_id: resourceRateId,
		slot_id: slotId,
		unit_id: unitId,
		unit_name: unitName,
		facility_id: facilityId,
		is_flex: isFlex,
		contract_id: contractId
	} = slot;
	const [selectedUnit, setSelectedUnit] = useState(availableUnits.find((_unit) => _unit?.id === unitId));
	const [updateUnit, updateUnitState] = useUpdateUnitMutation();
	const [updateFlexUnit, updateFlexUnitState] = useUpdateFlexUnitMutation();

	const dispatch = useDispatch();

	const onEditAssignment = () => {
		setIsLoading(true);
		setShowModal(false);
		updateUnit({ ...(!contractId ? { resourceRateId } : null), slotId, unitId: selectedUnit?.id });
	};

	const onEditFlexAssignment = () => {
		setIsLoading(true);
		setShowModal(false);
		updateFlexUnit({ facilityId, slotId, unitId: selectedUnit?.id || null });
	};

	const handleEditUnit = () => {
		if (isFlex) {
			onEditFlexAssignment();
		} else {
			onEditAssignment();
		}
	};

	const onCancelEdit = () => {
		setSelectedUnit(availableUnits.filter((_unit) => _unit?.id === unitId)[0]);
		setShowModal(false);
	};

	const reloadFeed = useCallback(async () => {
		await dispatch(loadFeed());
		setIsLoading(false);
	}, [dispatch, setIsLoading]);

	useEffect(() => {
		const { isLoading, isError, isSuccess, error } = isFlex ? updateFlexUnitState : updateUnitState;
		if (isSuccess) {
			const message = selectedUnit?.unit_name
				? `Unit was successfully updated to ${selectedUnit?.unit_name}.`
				: `Your unit removal of ${unitName} for ${moment.utc(slot.raw_start_time).format('dddd, MMMM Do, YYYY')},
					${moment.utc(slot.raw_start_time).format('hh:mm A')} – ${moment.utc(slot.raw_end_time).format('hh:mm A')} 
					was successful.`;

			dispatch(showNotification('success', message));
		}
		if (isError && error) {
			dispatch(showNotification('error', unwrapError(error) || 'Failed to update unit. Please try again!'));
		}
		if (!isLoading && (isSuccess || isError)) {
			reloadFeed().catch((e) =>
				dispatch(showNotification('error', unwrapError(e) || 'Failed to update unit. Please try again!'))
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateFlexUnitState, updateUnitState, reloadFeed]);

	return (
		<div className="unit-assignment">
			{showModal === UNIT_ASSIGNMENT_MODALS.edit && (
				<EditUnitModal
					slot={slot}
					onCancel={onCancelEdit}
					onEditAssignment={handleEditUnit}
					setShowModal={setShowModal}
					selectedUnit={selectedUnit}
					setSelectedUnit={setSelectedUnit}
					availableUnits={availableUnits}
				/>
			)}
			{showModal === UNIT_ASSIGNMENT_MODALS.unassign && (
				<UnassignUnitModal
					onCancel={() => setShowModal(null)}
					onEditAssignment={handleEditUnit}
					setSelectedUnit={setSelectedUnit}
					selectedUnit={selectedUnit}
					slot={slot}
				/>
			)}
			{showModal === UNIT_ASSIGNMENT_MODALS.editArrived && (
				<EditAlreadyArrivedModal onCancel={() => setShowModal(null)} setShowModal={setShowModal} slot={slot} />
			)}
			{showModal === UNIT_ASSIGNMENT_MODALS.unassignArrived && (
				<UnassignAlreadyArrivedModal
					onCancel={() => setShowModal(null)}
					onEditAssignment={onEditAssignment}
					setSelectedUnit={setSelectedUnit}
					selectedUnit={selectedUnit}
					slot={slot}
				/>
			)}
		</div>
	);
};

export default UnitAssignment;
