import React from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';

import './SearchProfessional.css';
import ProfessionalRow from './ProfessionalRow';

const SearchProfessional = ({
	searchedCaregivers,
	errorSearch,
	searchValue,
	searchTermChange,
	addSelectedCaregiver,
	loading,
	copy,
	searchDisabled,
	searchSectionTitle
}) => {
	return (
		<div className="section-caregiver-search">
			<section className="search-professionals-container">
				<div className="search-professionals-header">
					<h4>
						<span>{searchSectionTitle}</span>
					</h4>
				</div>
				<InputGroup className="search-invite">
					<InputGroup.Text className="search-icon" />
					<FormControl
						disabled={searchDisabled}
						placeholder="Search for Professional"
						aria-label="Search"
						aria-describedby="search professional"
						value={searchValue}
						onChange={searchTermChange}
					/>
				</InputGroup>
				{searchedCaregivers && searchedCaregivers.length > 0 && !errorSearch && searchValue !== '' && (
					<div className="searched-caregivers">
						<ul className="list-search-caregivers">
							{searchedCaregivers.map((caregiver) => (
								<li key={`caregiver-${caregiver.caregiver_id}`}>
									<button type="button" onClick={() => addSelectedCaregiver(caregiver)}>
										<ProfessionalRow professional={caregiver} />
									</button>
								</li>
							))}
						</ul>
					</div>
				)}
				{searchValue !== '' && !errorSearch && searchedCaregivers.length === 0 && (
					<div className="searched-caregivers">
						<ul className="list-search-caregivers">
							<li>
								<button type="button">{loading ? <span>Loading...</span> : <span>No Professional found.</span>}</button>
							</li>
						</ul>
					</div>
				)}
				{searchValue === '' && searchedCaregivers.length === 0 && !errorSearch && (
					<div className="search-professional-info-container">
						<span className="info-icon" />
						<p>{copy}</p>
					</div>
				)}
				{errorSearch && (
					<div className="search-professional-info-container">
						<span className="info-icon" />
						<p> Something went wrong and your search couldn&apos;t be completed</p>
					</div>
				)}
			</section>
		</div>
	);
};

export default SearchProfessional;
