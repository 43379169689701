import React, { useState } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import WithOnlyOnDesktop from '../../components/onlyOnDesktop/withOnlyOnDesktop';
import AgencyOpenShifts from './AgencyOpenShifts';

import './ExternalAgencyScreen.scss';
import './AgencySchedule.scss';

const AgencySchedule = () => {
	const [activeTabIndex, setActiveTabIndex] = useState(0);

	return (
		<WithOnlyOnDesktop>
			<div className="external-agency-screen external-agency-schedule">
				<div className="title">Schedule</div>
				<TabView activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>
					<TabPanel header="Open">
						<AgencyOpenShifts />
					</TabPanel>
					<TabPanel header="Assigned" />
					<TabPanel header="Past" />
				</TabView>
			</div>
		</WithOnlyOnDesktop>
	);
};

export default AgencySchedule;
