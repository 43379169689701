import moment from 'moment-timezone';
import { SHIFT_FEATURES, SHIFT_STATUS } from '../constants';

/**
 * Filter functions for various shift properties.
 *
 * @namespace FilterFunctions
 * @type {Object}
 */
const FilterFunctions = {
	/**
	 * Filter items based on the specified caregivers.
	 *
	 * @function
	 * @memberof FilterFunctions
	 * @param {Object} params - Parameters for filtering.
	 * @param {Array} params.filteredItems - The array of items to be filtered.
	 * @param {Object} params.filterState - The filter state object.
	 * @returns {Array} - The filtered array of items.
	 */
	caregiver: ({ filteredItems, filterState }) =>
		filteredItems.filter((s) => filterState.caregiver.includes(s.caregiver_id || s.caregiverId)),

	/**
	 * Filter items based on the specified date.
	 *
	 * @function
	 * @memberof FilterFunctions
	 * @param {Object} params - Parameters for filtering.
	 * @param {Array} params.filteredItems - The array of items to be filtered.
	 * @param {Object} params.filterState - The filter state object.
	 * @returns {Array} - The filtered array of items.
	 */
	date: ({ filteredItems, filterState }) => {
		const filterDateString = moment(filterState.date[0]).format('YYYY-MM-DD');
		return filteredItems.filter((s) => {
			const shiftDate = moment.utc(s.raw_start_time).format('YYYY-MM-DD');
			return filterDateString === shiftDate;
		});
	},
	/**
	 * Filter items based on the specified resource types.
	 *
	 * @function
	 * @memberof FilterFunctions
	 * @param {Object} params - Parameters for filtering.
	 * @param {Array} params.filteredItems - The array of items to be filtered.
	 * @param {Object} params.filterState - The filter state object.
	 * @returns {Array} - The filtered array of items.
	 */
	resourceType: ({ filteredItems, filterState }) =>
		filteredItems.filter((s) => filterState.resourceType.includes(s.resource_type)),

	/**
	 * Filter items based on the specified shift features.
	 *
	 * @function
	 * @memberof FilterFunctions
	 * @param {Object} params - Parameters for filtering.
	 * @param {Array} params.filteredItems - The array of items to be filtered.
	 * @param {Object} params.filterState - The filter state object.
	 * @returns {Array} - The filtered array of items.
	 */
	shiftFeature: ({ filteredItems, filterState }) => {
		const orientation = filterState.shiftFeature.includes(SHIFT_FEATURES.ORIENTATION);
		const guaranteed = filterState.shiftFeature.includes(SHIFT_FEATURES.GUARANTEED);
		const bonus = filterState.shiftFeature.includes(SHIFT_FEATURES.BONUS);
		const bundle = filterState.shiftFeature.includes(SHIFT_FEATURES.BUNDLES);

		return filteredItems.filter((s) => {
			return (
				(orientation && s.is_orientation) ||
				(guaranteed && s.is_guaranteed) ||
				(bonus && s.facility_payable_bonus) ||
				(bundle && s.contract_id)
			);
		});
	},

	/**
	 * Filter items based on the specified shift slot.
	 *
	 * @function
	 * @memberof FilterFunctions
	 * @param {Object} params - Parameters for filtering.
	 * @param {Array} params.filteredItems - The array of items to be filtered.
	 * @param {Object} params.filterState - The filter state object.
	 * @returns {Array} - The filtered array of items.
	 */
	shiftSlot: ({ filteredItems, filterState }) =>
		filteredItems.filter((s) => !filterState.shiftSlot || s.slot_id === filterState.shiftSlot),

	/**
	 * Filter items based on the specified shift status.
	 *
	 * @function
	 * @memberof FilterFunctions
	 * @param {Object} params - Parameters for filtering.
	 * @param {Array} params.filteredItems - The array of items to be filtered.
	 * @param {Object} params.filterState - The filter state object.
	 * @returns {Array} - The filtered array of items.
	 */
	shiftStatus: ({ filteredItems, filterState }) => {
		const confirmed = filterState.shiftStatus.includes(SHIFT_STATUS.CONFIRMED);
		const arrived = filterState.shiftStatus.includes(SHIFT_STATUS.ARRIVED);
		const unclaimed = filterState.shiftStatus.includes(SHIFT_STATUS.UNCLAIMED);
		const claimed = filterState.shiftStatus.includes(SHIFT_STATUS.CLAIMED);

		return filteredItems.filter(
			(s) =>
				(confirmed && s.slot_status === SHIFT_STATUS.CONFIRMED && !s.outcome_code) ||
				(arrived && s.outcome_code === SHIFT_STATUS.ARRIVED.toUpperCase()) ||
				(unclaimed && !s.caregiver_id) ||
				(claimed && s.caregiver_id && !s.slot_status)
		);
	},

	/**
	 * Filter items based on the specified units.
	 *
	 * @function
	 * @memberof FilterFunctions
	 * @param {Object} params - Parameters for filtering.
	 * @param {Array} params.filteredItems - The array of items to be filtered.
	 * @param {Object} params.filterState - The filter state object.
	 * @returns {Array} - The filtered array of items.
	 */
	unit: ({ filteredItems, filterState }) =>
		filteredItems.filter(
			(s) => filterState.unit.includes(s.unit_id) || (filterState.unit.includes('unassigned') && !s.unit_id)
		),

	facilities: ({ filteredItems, filterState }) =>
		filteredItems.filter((s) => filterState.facilities.includes(s.facility_id)),

	shiftTypes: ({ filteredItems, filterState }) =>
		filteredItems.filter((s) => {
			const specialty = s.specialty ? ` - ${s.specialty}` : '';
			return filterState.shiftTypes.includes(`${s.resource_type}${specialty}`);
		}),

	startTimes: ({ filteredItems, filterState }) =>
		filteredItems.filter((s) => filterState.startTimes.includes(s.start_hour_tz))
};

export const applyFilters = ({ items, filters }) => {
	return Object.keys(filters).reduce(
		(filteredItems, filterType) => {
			const filterValue = filters[filterType];
			return filterValue?.length > 0
				? FilterFunctions[filterType]({ filteredItems, filterState: filters })
				: filteredItems;
		},
		[...items]
	);
};

export const filterNames = Object.keys(FilterFunctions).reduce((acc, key) => {
	acc[key] = [];
	return acc;
}, {});
