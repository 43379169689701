/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { withRouter } from 'react-router-dom';
import AppHeader from '../components/header/AppHeader';
import AgencyRoutes from './AgencyRoutes';
import FacilityRoutes from './FacilityRoutes';

const PrivateSection = ({ userData }) => (
	<>
		<AppHeader />
		{userData.agencyId ? <AgencyRoutes userData={userData} /> : <FacilityRoutes userData={userData} />}
	</>
);

export default withRouter(PrivateSection);
