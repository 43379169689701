import React, { memo } from 'react';
import { Link, NavLink } from 'react-router-dom';
import NavbarMenu from './NavbarMenu';

import './AppHeaderDesktop.scss';

const ExternalAgencyHeader = ({
	userData,
	rightMenuRef,
	menuOpen,
	menuItems,
	toggleMenu,
	handleNavBarNotOnMobileClick
}) => (
	<header className="app-header-desktop">
		<Link to="/" className="logo" />
		<div className="facility-and-menu">
			<div className="right-menu flex-one">
				<NavLink
					to={{ pathname: '/private/agency/shifts' }}
					className="shift-menu-item shift-menu-simple-item"
					onClick={handleNavBarNotOnMobileClick}
					activeClassName="active"
				>
					Schedule
				</NavLink>

				<NavLink
					to={{ pathname: '/private/agency/professionals' }}
					className="shift-menu-item shift-menu-simple-item"
					onClick={handleNavBarNotOnMobileClick}
					activeClassName="active"
				>
					Professionals
				</NavLink>

				<NavbarMenu
					userData={userData}
					rightMenuRef={rightMenuRef}
					menuOpen={menuOpen}
					menuItems={menuItems}
					toggleMenu={toggleMenu}
				/>
			</div>
		</div>
	</header>
);

export default memo(ExternalAgencyHeader);
