/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Utils from '../utils/utils';

const ProtectedSectionRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={() =>
			Utils.isAuthorizedSection(rest.pathPermission, rest.userPermissions) ? (
				<Component {...rest} />
			) : (
				<Redirect to="/" />
			)
		}
	/>
);

export default ProtectedSectionRoute;
