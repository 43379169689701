import React from 'react';
import Icon from './Icon';

/**
 * CheckIcon component displays an icon representing check mark.
 *
 * @component
 * @param {Object} props - The component properties.
 * @param {number} [props.width=24] - Width of the icon.
 * @param {number} [props.height=24] - Height of the icon.
 * @param {string} [props.color] - Color of the icon.
 * @param {string} [props.className] - Additional CSS classes for styling.
 * @returns {JSX.Element} AccountManagementIcon component.
 */
const ReportsIcon = React.memo(({ width = 20, height = 20, color, className }) => {
	return (
		<Icon
			width={width}
			height={height}
			viewBox="0 0 16 17"
			color={color}
			className={className}
			path="M13.4 2.5H2.6C2.26667 2.5 2 2.76667 2 3.1V13.9C2 14.1667 2.26667 14.5 2.6 14.5H13.4C13.6667 14.5 14 14.1667 14 13.9V3.1C14 2.76667 13.6667 2.5 13.4 2.5ZM6 5.16667H4.66667V6.5H6V5.16667ZM11.3333 5.16667H7.33333V6.5H11.3333V5.16667ZM11.3333 7.83333H7.33333V9.16667H11.3333V7.83333ZM7.33333 10.5H11.3333V11.8333H7.33333V10.5ZM4.66667 7.83333H6V9.16667H4.66667V7.83333ZM6 10.5H4.66667V11.8333H6V10.5ZM3.33333 13.1667H12.6667V3.83333H3.33333V13.1667Z"
		/>
	);
});

export default ReportsIcon;
