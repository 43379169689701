import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import TagManager from 'react-gtm-module';
import config from './config';
import { loadUser, user } from './redux/user';
import { error } from './redux/error';
import Notification from './components/notification/Notification';
import LoadingSpinner from './components/spinner/LoadingSpinner';
import Login from './features/login/Login';
import ForgotPassword from './features/login/ForgotPassword';
import NewPassword from './features/login/NewPassword';
import Form530Download from './features/form-530/Form530Download';
import NotFound from './features/error/NotFound';
import PrivateSection from './sections/PrivateSection';

if (process.env.REACT_APP_GTM_ID) {
	TagManager.initialize({
		gtmId: process.env.REACT_APP_GTM_ID
	});
}

const App = () => {
	const dispatch = useDispatch();
	const userData = useSelector(user);
	const err = useSelector(error);

	useEffect(() => {
		dispatch(loadUser());
	}, [dispatch]);

	useEffect(() => {
		if (err && err.type === '403' && !window.location.pathname.startsWith('/private/')) {
			window.location.reload();
		}
	}, [err]);

	const loggedIn = !!userData.name && (userData.facilitiesEnabled?.length > 0 || userData.agencyId);
	const userLoaded = userData.loaded;

	return (
		<div className={classNames('App', { 'ie-10': config.IS_IE })}>
			<Notification />
			{userLoaded ? (
				<Switch>
					<PublicRoute path="/login" exact component={Login} loggedIn={loggedIn} />
					<PublicRoute path="/forgot-password" exact component={ForgotPassword} loggedIn={loggedIn} />
					<PublicRoute path="/new-password/:verificationToken" exact component={NewPassword} loggedIn={loggedIn} />
					<PrivateRoute path="/private" component={PrivateSection} userData={userData} loggedIn={loggedIn} />
					<PrivateRoute
						path="/form-530/:facilityId/:fileId"
						exact
						userData={userData}
						component={Form530Download}
						loggedIn={loggedIn}
					/>
					<Route
						path="/"
						exact
						render={() => {
							if (userData?.agencyId) {
								return <Redirect to="/private/agency/shifts" />;
							}
							return userData?.globalOverviewEnabled ? (
								<Redirect to="/private/global-overview" />
							) : (
								<Redirect to="/private/shifts" />
							);
						}}
					/>
					<Route path="/404" render={() => <NotFound userData={userData} />} />
					<Route path="/" render={() => <Redirect to="/404" />} />
				</Switch>
			) : (
				<LoadingSpinner />
			)}
		</div>
	);
};

export default App;

const PublicRoute = ({ component: Component, ...rest }) => (
	// eslint-disable-next-line react/jsx-props-no-spreading
	<Route {...rest} render={(props) => (!rest.loggedIn ? <Component {...props} /> : <Redirect to="/" />)} />
);

const PrivateRoute = ({ component: Component, ...rest }) => (
	// eslint-disable-next-line react/jsx-props-no-spreading
	<Route {...rest} render={() => (rest.loggedIn ? <Component {...rest} /> : <Redirect to="/login" />)} />
);
