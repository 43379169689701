import { useHistory, useLocation } from 'react-router-dom';
import { PRIVATE_PATH } from '../../constants';

/**
 * Custom hook that provides a function to navigate without certain URL search parameters.
 * This is useful for scenarios where you want to clean up the URL by removing unnecessary
 * or sensitive parameters before navigating.
 *
 * @returns {Function} A function that accepts an options object to navigate while removing specified search parameters.
 */
const useConditionalNavigation = () => {
	const history = useHistory();
	const location = useLocation();

	/**
	 * Navigates to the current URL with specified search parameters removed.
	 * This function modifies the URL search parameters based on the provided options,
	 * and then uses the history object to navigate to the new URL.
	 *
	 * @param {string[]} params - The search parameters to remove from the current URL.
	 * @param {boolean} shouldReplace - If true, replaces the current entry in the history stack.
	 *                                  If false, pushes a new entry onto the history stack.
	 */
	const navigateByRemovingSearchParams = (params = [], shouldReplace = false) => {
		if (location.search) {
			const newUrl = new URL(window.location.href);

			// Iterate over the list of parameters to remove and delete them from the URL's search parameters.
			params.forEach((param) => newUrl.searchParams.delete(param));

			// Determine the navigation method based on whether the navigation should replace the current history entry.
			const navigationMethod = shouldReplace ? 'replace' : 'push';

			history[navigationMethod]({
				pathname: newUrl.pathname,
				search: newUrl.searchParams.toString() ? `?${newUrl.searchParams}` : ''
			});
		}
	};

	const returnToGlobalOverview = () => history.replace(PRIVATE_PATH.GLOBAL_OVERVIEW);

	return { navigateByRemovingSearchParams, returnToGlobalOverview };
};

export default useConditionalNavigation;
