import React from 'react';

import './Dropdown.scss';
import classNames from 'classnames';

/**
 * Dropdown component to display a customizable user menu.
 *
 * @param {Object} props - Component props.
 * @param {boolean} props.open - Determines if the dropdown is visible.
 * @param {string} [props.header] - Optional header for the dropdown list.
 * @param {Array<Object>} props.menuItems - Array of objects representing menu items. Each item object is expected to have the following properties:
 *   @param {string} item.label - The text label for the menu item.
 *   @param {React.Element} [item.icon] - An optional React component for an icon to display alongside the menu item's label.
 *   @param {Function} item.onClick - The function to call when the menu item is clicked.
 *   @param {boolean} [item.selected] - An optional boolean indicating if the menu item is currently selected.
 * @returns {JSX.Element} The Dropdown component.
 */
const Dropdown = ({ className, open, header, menuItems }) => {
	return (
		<div className={classNames('dropdown-component', { open }, className)}>
			{header && <ul>{header}</ul>}
			{menuItems.map((item) => (
				<button
					key={item.label}
					type="button"
					className={classNames('menu-button', {
						selected: item.selected,
						disabled: item.disabled
					})}
					aria-hidden="true"
					onClick={item.onClick}
					tabIndex={0}
					disabled={item.disabled}
				>
					{item.icon && React.createElement(item.icon)}
					<span>{item.label}</span>
				</button>
			))}
		</div>
	);
};

export default Dropdown;
