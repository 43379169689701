import React, { memo } from 'react';
import classNames from 'classnames';
import { Link, NavLink } from 'react-router-dom';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { PERMISSION, PRIVATE_PATH } from '../../constants';
import { AccountManagementIcon, SignOutIcon } from '../icons';
import IsAuthorized from '../IsAuthorized/IsAuthorized';
import DailyScheduleIcon from '../icons/DailyScheduleIcon';
import MenuItemIconLabel from './MenuItemIconLabel';
import PastShiftsIcon from '../icons/PastShiftsIcon';
import SettingsIcon from '../icons/SettingsIcon';
import ProfessionalsIcon from '../icons/ProfessionalsIcon';
import UserAvatar from '../Avatar/UserAvatar';
import menuIcon from '../../assets/images/menu.svg';
import useElementHeight from '../hooks/useElementHeight';

import './AppHeaderMobile.scss';

const AppHeaderMobile = ({
	// User and facility data
	showFacilitiesDropdown,
	userData,
	activeFacility,
	selectedFacilityLabel,

	// Feature flags and access control
	isPastShiftsAllowed,
	hasProfessionalsFeature,
	hasSettingsAccess,

	// UI state
	showMobileMenu,
	setShowMobileMenu,
	menuOpen,
	setMenuOpen,
	showPastShiftsCircle,

	// Refs
	typeaheadRef,
	dropdownMenuRef,
	containerRef,
	headerRef,
	toggleMenuRef,
	facilitiesSelectorRef,

	// Custom components
	CustomToggle,
	CustomMenu,

	// Event handlers
	handleNavBarNotOnMobileClick,
	handleAccountManagementClick,
	onLogOut,

	// Miscellaneous
	shiftsToReview,
	professionalsPath,
	isOnGlobalOverview
}) => {
	const headerHeight = useElementHeight(headerRef);

	const navbarHeight = `calc(100vh - env(safe-area-inset-bottom) - ${headerHeight}px)`;
	return (
		<header className="app-header-mobile" key="app-header-mobile" ref={headerRef}>
			<Link to="/" className="logo" />
			<div
				className={classNames('facility-and-menu', { open: showMobileMenu })}
				ref={containerRef}
				style={{ height: navbarHeight, maxHeight: navbarHeight }}
			>
				<div className="top-content">
					<div className="user" key="user-section">
						{(userData.name || userData.email) && (
							<>
								<div className="user-section__avatar" key="user-avatar">
									<UserAvatar initials={userData.initials} />
								</div>
								<div key="user-info" className="user-data">
									<div className="user-name">{userData.name}</div>
									<div className="user-email">{userData.email}</div>
								</div>
							</>
						)}
					</div>

					<div className="facility-label-dropdown" ref={facilitiesSelectorRef}>
						{showFacilitiesDropdown ? (
							<Dropdown
								onClick={() => {
									setMenuOpen((prev) => ({ user: false, apps: false, facilities: !prev.facilities }));
									if (!menuOpen.facilities) {
										setTimeout(() => {
											typeaheadRef.current.focus();
											typeaheadRef.current.clear();
										}, 10);
									}
								}}
								show={menuOpen.facilities}
							>
								<Dropdown.Toggle id="facility-enabled-search-dropdown" as={CustomToggle}>
									{selectedFacilityLabel}
								</Dropdown.Toggle>
								<Dropdown.Menu ref={dropdownMenuRef} as={CustomMenu} />
							</Dropdown>
						) : (
							<div>{activeFacility?.label}</div>
						)}
					</div>
				</div>

				{!isOnGlobalOverview && (
					<>
						<div className="right-menu">
							{!userData.agencyId && (
								<NavLink
									to={PRIVATE_PATH.SHIFTS}
									className="shift-menu-item"
									onClick={() => setShowMobileMenu(false)}
									activeClassName="active"
								>
									<MenuItemIconLabel
										icon={<DailyScheduleIcon color="black" />}
										label="Daily&nbsp;Schedule"
										labelClass="mobile-black-text"
									/>
								</NavLink>
							)}

							{isPastShiftsAllowed && (
								<NavLink
									to={PRIVATE_PATH.PAST_SHIFTS}
									className="shift-menu-item"
									onClick={handleNavBarNotOnMobileClick}
									activeClassName="active"
								>
									<MenuItemIconLabel
										icon={<PastShiftsIcon color="black" />}
										label="Past&nbsp;shifts"
										showRedCircle={showPastShiftsCircle}
										redNumber={shiftsToReview}
										labelClass="mobile-black-text"
									/>
								</NavLink>
							)}

							{hasProfessionalsFeature && (
								<IsAuthorized
									exactPermission={[PERMISSION.PROFESSIONALS, PERMISSION.INTERNAL_STAFF]}
									readOnlyRender={
										<OverlayTrigger
											trigger={['hover', 'focus']}
											key="professionals-tooltip"
											placement="bottom"
											overlay={
												<Tooltip className="professionals-menu-item-tooltip" placement="bottom" show>
													You do not have permission to perform this action. Please contact your Customer Success Rep
													for details.
												</Tooltip>
											}
										>
											<div className="shift-menu-item">
												<MenuItemIconLabel
													icon={<ProfessionalsIcon color="black" />}
													label="Professionals"
													labelClass="mobile-black-text"
												/>
											</div>
										</OverlayTrigger>
									}
								>
									<NavLink
										to={professionalsPath}
										className="shift-menu-item"
										onClick={() => setShowMobileMenu(false)}
										activeClassName="active"
									>
										<MenuItemIconLabel
											icon={<ProfessionalsIcon color="black" />}
											label="Professionals"
											labelClass="mobile-black-text"
										/>
									</NavLink>
								</IsAuthorized>
							)}
							{hasSettingsAccess && (
								<NavLink
									to={{ pathname: PRIVATE_PATH.SETTINGS }}
									className="shift-menu-item"
									onClick={handleNavBarNotOnMobileClick}
									activeClassName="active"
								>
									<MenuItemIconLabel
										icon={<SettingsIcon color="black" width={26} height={26} />}
										label="Settings"
										labelClass="mobile-black-text"
									/>
								</NavLink>
							)}
						</div>
					</>
				)}
				<div className="flex-bottom">
					{!userData.agencyId && (
						<div
							className="shift-menu-item"
							onClick={handleAccountManagementClick}
							role="button"
							aria-hidden="true"
							tabIndex={0}
						>
							<MenuItemIconLabel
								icon={<AccountManagementIcon />}
								label="Manage Account"
								labelClass="mobile-black-text"
							/>
						</div>
					)}
					<div className="shift-menu-item" onClick={onLogOut} role="button" aria-hidden="true" tabIndex={0}>
						<MenuItemIconLabel icon={<SignOutIcon />} label="Sign Out" labelClass="mobile-black-text" />
					</div>
				</div>
			</div>
			<div
				ref={toggleMenuRef}
				className="menu-hamburguer cursor-pointer"
				onClick={() => {
					setShowMobileMenu(!showMobileMenu);
				}}
				aria-hidden
			>
				<img src={menuIcon} alt="" className="header-menu-icon" />
			</div>
		</header>
	);
};

export default memo(AppHeaderMobile);
