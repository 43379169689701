import React from 'react';

const BadgeIcon = ({ width = 24, height = 24, className }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			className={className}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20.9435 16.027C20.3123 17.7753 19.3576 19.2955 18.1056 20.5448C16.6806 21.9668 14.8146 23.0967 12.5594 23.9028C12.4854 23.9291 12.4082 23.9507 12.3306 23.9667C12.2281 23.9874 12.1238 23.9985 12.0204 24H12.0002C11.89 24 11.7792 23.9886 11.6694 23.9667C11.5918 23.9507 11.5157 23.9291 11.442 23.9033C9.18414 23.0986 7.31581 21.9692 5.88933 20.5472C4.63685 19.2978 3.68234 17.7786 3.05198 16.0303C1.90574 12.8516 1.97097 9.34991 2.02347 6.53594L2.02436 6.49273C2.03493 6.26037 2.04174 6.01629 2.04551 5.74676C2.06468 4.42345 3.09426 3.32866 4.38939 3.25487C7.08968 3.10088 9.17859 2.20092 10.9634 0.423144L10.979 0.408496C11.2754 0.130725 11.6488 -0.005506 12.0204 0.000170292C12.3788 0.00493105 12.7357 0.140978 13.0215 0.408496L13.0368 0.423144C14.8219 2.20092 16.9109 3.10088 19.6111 3.25487C20.9063 3.32866 21.9359 4.42345 21.955 5.74676C21.9588 6.01812 21.9656 6.26183 21.9762 6.49273L21.9767 6.51104C22.029 9.33031 22.0939 12.839 20.9435 16.027Z"
				fill="#00A698"
			/>
			<path
				d="M17.8621 11.6469C17.8621 14.9481 15.2433 17.6352 12.0207 17.647H12.0001C8.76799 17.647 6.13794 14.9552 6.13794 11.6469C6.13794 8.33872 8.76799 5.64697 12.0001 5.64697H12.0207C15.2433 5.65873 17.8621 8.34588 17.8621 11.6469Z"
				fill="white"
			/>
			<path
				d="M14.8877 11.1502L12.0711 13.992L11.4624 14.606C11.3186 14.751 11.1301 14.8234 10.9417 14.8234C10.7531 14.8234 10.5647 14.751 10.4207 14.606L9.11216 13.2853C8.82459 12.9952 8.82459 12.5253 9.11216 12.2351C9.39933 11.945 9.86571 11.945 10.1533 12.2351L10.9417 13.0304L13.8466 10.1C14.1342 9.80969 14.6005 9.80969 14.8877 10.1C15.1753 10.3901 15.1753 10.8605 14.8877 11.1502Z"
				fill="#00A698"
			/>
		</svg>
	);
};

export default BadgeIcon;
