import React, { useState, useEffect, useCallback, useReducer, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import './RecurringShiftsModal.scss';
import classNames from 'classnames';
import Checkbox from '../../form/Checkbox';
import tooltipIcon from '../../../assets/images/tooltip-icon.svg';
import RecurringShiftsTable from './RecurringShiftsTable';
import ConfirmationModal from '../ConfirmationModal';
import xhr from '../../../utils/xhr';
import { showNotification } from '../../../redux/notification';

const TODAY = moment().format('ddd');

const addToPayload = (payload, structureId, slots, currentDay) => {
	const copiedPayload = payload;

	const existentPayload = copiedPayload[currentDay.fullName].find(
		(structurePayload) => structurePayload.structureId === structureId
	);

	if (!existentPayload) {
		const structurePayload = {
			structureId,
			slots,
			isGuaranteed: false,
			isRecommended: false,
			modified: true
		};

		copiedPayload[currentDay.fullName].push(structurePayload);
	} else {
		existentPayload.slots = slots;
		existentPayload.modified = true;
	}

	return copiedPayload;
};

const removeFromPayload = (payload, structureId, currentDay) => {
	const copiedPayload = payload;

	copiedPayload[currentDay.fullName] = copiedPayload[currentDay.fullName].filter((structure) => {
		return structure.structureId !== structureId;
	});

	return copiedPayload;
};

const handleCheckboxToggle = (payload, structureId, newState, currentDay) => {
	const currentStructure = payload[currentDay.fullName].find((structure) => structure.structureId === structureId);

	currentStructure.isGuaranteed = newState;
	currentStructure.modified = true;

	return payload;
};

const handleInput = (state, data) => {
	const { type, structureId, amount, currentDay, newState, recurringShiftId } = data;

	if (type === 'input') {
		let newAmount = Number(amount);

		if (Number.isNaN(newAmount)) {
			return state;
		}

		if (newAmount > 0 || (newAmount >= 0 && recurringShiftId)) {
			if (newAmount > 20) {
				newAmount = 20;
			}

			return { ...addToPayload(state, structureId, newAmount, currentDay) };
		}

		return { ...removeFromPayload(state, structureId, currentDay) };
	}

	if (type === 'checkbox') {
		return { ...handleCheckboxToggle(state, structureId, newState, currentDay) };
	}

	if (type === 'overwrite') {
		return data.newState;
	}

	return state;
};

const RecurringShiftsModal = (props) => {
	const { shiftStructures } = props;

	const RECURRING_SHIFTS_STRUCTURE = useMemo(() => {
		return {
			Monday: [],
			Tuesday: [],
			Wednesday: [],
			Thursday: [],
			Friday: [],
			Saturday: [],
			Sunday: []
		};
	}, []);

	const WEEKDAYS = [
		{ name: 'Sat', fullName: 'Saturday', hasRecurringShifts: TODAY === 'Sat' },
		{ name: 'Sun', fullName: 'Sunday', hasRecurringShifts: TODAY === 'Sun' },
		{ name: 'Mon', fullName: 'Monday', hasRecurringShifts: TODAY === 'Mon' },
		{ name: 'Tues', fullName: 'Tuesday', hasRecurringShifts: TODAY === 'Tue' },
		{ name: 'Wed', fullName: 'Wednesday', hasRecurringShifts: TODAY === 'Wed' },
		{ name: 'Thurs', fullName: 'Thursday', hasRecurringShifts: TODAY === 'Thu' },
		{ name: 'Fri', fullName: 'Friday', hasRecurringShifts: TODAY === 'Fri' }
	];

	const dispatch = useDispatch();

	const [errors, setErrors] = useState([]);
	const [checkedConfirmation, setCheckedConfirmation] = useState(false);
	const [activeDay, setActiveDay] = useState(moment().format('ddd'));
	const [changesExist, setChangesExist] = useState(false);
	const [showWarningRecurringModal, setShowWarningRecurringModal] = useState(false);
	const [weekDays, setWeekDays] = useState(WEEKDAYS);
	const [currentDay, setCurrentDay] = useState(weekDays.find((day) => activeDay === day.name.slice(0, 3)));
	const [recurringShiftsPayload, setRecurringShiftsPayload] = useReducer(handleInput, RECURRING_SHIFTS_STRUCTURE);

	const changeDayRecurrentStatus = useCallback(
		(dayToChange, status) => {
			const newDays = [...weekDays];
			const dayToModify = newDays.find((day) => dayToChange === day.name.slice(0, 3));
			dayToModify.hasRecurringShifts = status;

			setWeekDays(newDays);
		},
		[weekDays]
	);

	const handleSave = async () => {
		try {
			await xhr.request('POST', '/recurring-shifts', recurringShiftsPayload);
			props.confirmModal();
			props.onClose();
		} catch (error) {
			if (error.response.data === 'Forbidden') return;
			if (
				(error.response.data.errors &&
					Array.isArray(error.response.data.errors) &&
					error.response.data.errors.length > 0 &&
					!error.response.data.errors[0].message.includes('Ops User')) ||
				!Array.isArray(error.response.data.errors)
			) {
				props.setRecurringShiftsErrors(error.response.data.errors);
				props.onClose();
			}
		}
	};

	const verifyRecurringShifts = (weekdays, recurringShifts) => {
		return weekdays.map((day) => ({
			...day,
			hasRecurringShifts:
				day.hasRecurringShifts ||
				(!!recurringShifts[day.fullName] && recurringShifts[day.fullName].some((shift) => shift.slots !== 0))
		}));
	};

	const loadRecurringShifts = useCallback(async () => {
		try {
			const recurringShiftsFetched = await xhr.request('GET', '/recurring-shifts');
			const recurringShifts = {
				Monday: [],
				Tuesday: [],
				Wednesday: [],
				Thursday: [],
				Friday: [],
				Saturday: [],
				Sunday: [],
				...recurringShiftsFetched.data
			};
			setRecurringShiftsPayload({
				type: 'overwrite',
				newState: recurringShifts
			});
			const verifyWeekdays = verifyRecurringShifts(weekDays, recurringShiftsFetched.data);
			setWeekDays(verifyWeekdays);
		} catch (error) {
			dispatch(showNotification('error', 'Failed to load recurring shifts. Please refresh!'));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, RECURRING_SHIFTS_STRUCTURE]);

	useEffect(() => {
		loadRecurringShifts();
	}, [loadRecurringShifts]);

	const triggerSingleShiftModal = (validateChanges = true) => {
		if (changesExist && validateChanges) {
			setShowWarningRecurringModal(true);
		} else {
			props.onClose();
			props.setShowRequestsShiftModal(true);
		}
		return true; // Placeholder for SonarCloud
	};

	const checkEmptySlots = (currentShiftStructure) => {
		return currentShiftStructure.every((recurrentShift) => recurrentShift.slots === 0);
	};

	const changeDay = (name) => {
		const currentShiftStructure = recurringShiftsPayload[currentDay.fullName];

		if (currentShiftStructure.length === 0 || checkEmptySlots(currentShiftStructure)) {
			changeDayRecurrentStatus(activeDay, false);
		}

		setActiveDay(name);
		changeDayRecurrentStatus(name, true);
	};

	useEffect(() => {
		const momentCurrentDay = moment().format('ddd');
		setActiveDay(momentCurrentDay);

		if (!shiftStructures.length) {
			let newErrors = [];
			if (errors.length) {
				newErrors = [...errors];
			}
			newErrors.push('No structure provided');
			setErrors(newErrors);
		}
	}, [shiftStructures.length]);

	useEffect(() => {
		const values = Object.values(recurringShiftsPayload);
		const state = values.some((value) => value.find((payload) => payload.modified));
		setChangesExist(state);
	}, [recurringShiftsPayload, changesExist, setChangesExist]);

	useEffect(() => {
		setCurrentDay(weekDays.find((day) => activeDay === day.name.slice(0, 3)));
	}, [activeDay, weekDays]);

	return [
		<Modal
			key="shift-recurring-modal"
			size="xl"
			show
			aria-labelledby="contained-modal-title-vcenter"
			centered
			scrollable={!!props.scrollable}
			className="recurring-shifts-modal"
			onHide={props.onClose}
			backdrop="static"
			keyboard={false}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					<h2>
						Recurring shifts
						<OverlayTrigger
							placement="right"
							trigger={['hover', 'click']}
							overlay={
								<Tooltip className="aditionalInformation info-tooltip" id="head-rec-shift-tooltip">
									Set your unfilled PPD into the future to be filled by ShiftMed Employees. The shifts below repeat
									weekly.
								</Tooltip>
							}
						>
							<img src={tooltipIcon} alt="tooltip-icon" className="tooltip-icon" />
						</OverlayTrigger>
					</h2>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="recurring-shifts-modal-body">
					<div className="recurring-shifts-modal-tabs">
						{weekDays.map((day) => (
							<OverlayTrigger
								placement="bottom"
								overlay={
									<Tooltip className="aditionalInformation info-tooltip" id={`weekday-${day.name}-tooltip`}>
										{day.hasRecurringShifts
											? `Recurring schedule is active for ${day.fullName}`
											: `Recurring schedule is not configured for ${day.fullName}`}
									</Tooltip>
								}
								key={`tab-${day.name}-tooltip`}
							>
								<div
									className={classNames(
										{ 'active-day': day.name.slice(0, 3) === activeDay },
										'recurring-shifts-modal-tab'
									)}
									onClick={() => changeDay(day.name.slice(0, 3))}
									aria-hidden
								>
									{day.hasRecurringShifts && (
										<svg className="recurring-shift-icon" viewBox="0 0 17 23" xmlns="http://www.w3.org/2000/svg">
											<path d="M8.49967 8.62504V5.75004C5.32759 5.75004 2.74967 8.32796 2.74967 11.5C2.74967 12.468 2.98926 13.388 3.42051 14.1834L2.02134 15.5825C1.27384 14.4038 0.833008 13.0046 0.833008 11.5C0.833008 7.26421 4.26384 3.83337 8.49967 3.83337V0.958374L12.333 4.79171L8.49967 8.62504ZM13.5788 8.8167L14.978 7.41753C15.7255 8.59628 16.1663 9.99545 16.1663 11.5C16.1663 15.7359 12.7355 19.1667 8.49967 19.1667V22.0417L4.66634 18.2084L8.49967 14.375V17.25C11.6718 17.25 14.2497 14.6721 14.2497 11.5C14.2497 10.5321 14.0005 9.6217 13.5788 8.8167Z" />
										</svg>
									)}
									{day.name}
								</div>
							</OverlayTrigger>
						))}
					</div>
					<hr />
					{shiftStructures.length ? (
						<RecurringShiftsTable
							weekDays={weekDays}
							activeDay={activeDay}
							recurringShiftsPayload={recurringShiftsPayload}
							setRecurringShiftsPayload={setRecurringShiftsPayload}
							shiftStructures={shiftStructures}
							tooltipPlacement={props.tooltipPlacement}
							currentDay={currentDay}
						/>
					) : (
						<div className="d-flex align-items-center justify-content-center">No shifts structure found</div>
					)}
					<div className="recurring-shifts-modal-footer mt-4">
						<div className="recurring-shifts-modal-terms">
							<Checkbox handleChange={setCheckedConfirmation} className="cursor-pointer" />
							<p>I acknowledge that I am creating shifts that will repeat weekly.</p>
						</div>

						<div className="buttons">
							<Button className="confirm-button" onClick={props.onClose} variant="secondary">
								CANCEL
							</Button>
							<Button
								className="confirm-button"
								onClick={handleSave}
								variant="primary"
								disabled={(errors.length === 0 && !checkedConfirmation) || !changesExist}
							>
								PUBLISH ALL
							</Button>
						</div>
						<button type="button" className="single-shift-button" onClick={triggerSingleShiftModal}>
							Add a single shift
						</button>
					</div>
				</div>
			</Modal.Body>
		</Modal>,

		showWarningRecurringModal && (
			<ConfirmationModal
				className="custom-confirmation"
				backdropClassName="custom-backdrop"
				key="logout-confirmation"
				onCancel={() => setShowWarningRecurringModal(false)}
				onConfirm={() => triggerSingleShiftModal(false)}
				title="Save changes?"
				cancelLabel="Go Back"
				confirmLabel="Single Shifts"
			>
				Do you want to go back to save your changes on Recurring Shifts before navigating to Single Shifts?
			</ConfirmationModal>
		)
	];
};

export default RecurringShiftsModal;
