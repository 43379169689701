import React from 'react';

/**
 * DisabledIcon component displays an icon representing a disabled action.
 *
 * @component
 * @param {Object} props - The component properties.
 * @param {number} [props.width=24] - Width of the icon.
 * @param {number} [props.height=25] - Height of the icon.
 * @param {string} [props.color='#000000'] - Color of the icon.
 * @param {string} [props.className] - Additional CSS classes for styling.
 * @returns {JSX.Element} SignOutIcon component.
 */
const DisabledIcon = ({ width = 24, height = 24, color = '#121315', className }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 15 16"
			className={className}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="7.5" cy="8" r="7" stroke={color} />
			<path
				d="M11.7975 4.73916L10.975 3.91666L7.71419 7.17749L4.45336 3.91666L3.63086 4.73916L6.89169 7.99999L3.63086 11.2608L4.45336 12.0833L7.71419 8.82249L10.975 12.0833L11.7975 11.2608L8.53669 7.99999L11.7975 4.73916Z"
				fill={color}
			/>
		</svg>
	);
};

export default DisabledIcon;
