import React, { memo, useEffect, useState } from 'react';
import moment from 'moment';
import { GuaranteedIcon } from '../../../components/icons';
import BaseModal from '../../../components/modals/BaseModal/BaseModal';
import Select from '../../../components/form/Select';

import '../EditUnitModal.scss';

const parseResourceAndSpecialtiesString = (resourceCode, specialtiesArray) => {
	if (!resourceCode) return ' - ';
	const specialtiesString = specialtiesArray?.length > 0 ? specialtiesArray.map(({ label }) => label).join(', ') : '';
	return `${resourceCode}${specialtiesString.length > 0 ? ' - ' : ''}${specialtiesString}`;
};

const EditUnitModal = ({
	onCancel,
	onEditAssignment,
	setShowModal,
	selectedUnit,
	setSelectedUnit,
	slot,
	availableUnits = []
}) => {
	const [primaryActionDisabled, setPrimaryActionDisabled] = useState(true);
	const unitOptions = availableUnits.map((unit) => ({ label: unit.unit_name, value: unit.id }));
	const emptyLabel = slot.unit_name ? '- Remove Unit -' : '- No Unit Selected -';
	const emptyOption = { label: emptyLabel, value: 0 };

	const onSelectOption = ({ unitId }) => {
		setSelectedUnit(availableUnits.find((_unit) => _unit?.id === unitId));
	};

	useEffect(() => {
		// eslint-disable-next-line camelcase
		const shouldDisable = (selectedUnit?.id || 0) === (slot?.unit_id || 0);
		setPrimaryActionDisabled(shouldDisable);
	}, [selectedUnit, slot]);

	useEffect(() => {
		// eslint-disable-next-line camelcase
		setSelectedUnit(availableUnits.find((_unit) => _unit?.id === slot?.unit_id));
		setPrimaryActionDisabled(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onSaveChanges = () => {
		if (!selectedUnit) {
			setShowModal('UNASSIGN_UNIT_MODAL');
		} else {
			setShowModal(null);
			onEditAssignment();
		}
	};

	const { caregiver, caregiver_name: caregiverName } = slot;
	const { highest_resource_type: resourceType, specialties } = caregiver || {};

	return (
		<BaseModal
			title="Edit Assignment"
			className="edit-unit-modal"
			primaryActionLabel="Save Changes"
			onPrimaryAction={onSaveChanges}
			primaryActionDisabled={primaryActionDisabled}
			secondaryActionLabel="Cancel"
			onSecondaryAction={onCancel}
			onHide={onCancel}
		>
			{caregiver && (
				<p>
					<b>Professional: </b>
					{caregiverName}
					<br />
					{parseResourceAndSpecialtiesString(resourceType, specialties)}
				</p>
			)}
			<p>
				<span className="shift-detail">
					<b>Shift Details: </b> {moment.utc(slot.raw_start_time).format('ddd. MMM. Do')}
				</span>
				<span className="shift-detail">
					{moment.utc(slot.raw_start_time).format('hh:mm A')} – {moment.utc(slot.raw_end_time).format('hh:mm A')}
				</span>
				{slot.is_guaranteed && (
					<span className="shift-detail guaranteed">
						<GuaranteedIcon /> Guaranteed
					</span>
				)}
			</p>
			<p>
				<span className="shift-detail">
					<b>Shift Type: </b> {slot.resource_type}
				</span>
				<span className="shift-detail">
					<b>Unit: </b> {slot.unit_name || '-'}
				</span>
			</p>
			<section>
				<h2>Unit Assignment</h2>
				<Select
					placeholder={emptyLabel}
					name="unitId"
					className="select"
					value={selectedUnit?.id || ''}
					handleChange={onSelectOption}
					options={[emptyOption, ...unitOptions]}
				/>
			</section>
		</BaseModal>
	);
};

export default memo(EditUnitModal);
