import React from 'react';
import Icon from './Icon';

const FacilityIcon = ({ width = 20, height = 20, color, className }) => {
	return (
		<Icon
			width={width}
			height={height}
			color={color}
			className={className}
			viewBox="0 0 16 17"
			path="M7.99992 5.16667H14.6666V14.5H1.33325V2.5H7.99992V5.16667ZM2.66659 13.1667H3.99992V11.8333H2.66659V13.1667ZM3.99992 10.5H2.66659V9.16667H3.99992V10.5ZM2.66659 7.83333H3.99992V6.5H2.66659V7.83333ZM3.99992 5.16667H2.66659V3.83333H3.99992V5.16667ZM5.33325 13.1667H6.66658V11.8333H5.33325V13.1667ZM6.66658 10.5H5.33325V9.16667H6.66658V10.5ZM5.33325 7.83333H6.66658V6.5H5.33325V7.83333ZM6.66658 5.16667H5.33325V3.83333H6.66658V5.16667ZM7.99992 13.1667H13.3333V6.5H7.99992V7.83333H9.33325V9.16667H7.99992V10.5H9.33325V11.8333H7.99992V13.1667ZM11.9999 7.83333H10.6666V9.16667H11.9999V7.83333ZM10.6666 10.5H11.9999V11.8333H10.6666V10.5Z"
		/>
	);
};

export default FacilityIcon;
