import React from 'react';
import CaregiverCard from './CaregiverCard';

import './CaregiverCard.scss';

/**
 * CaregiverCardOnDemand component.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.caregiver - The caregiver object.
 * @returns {JSX.Element} - JSX element representing the CaregiverCardOnDemand.
 */
const CaregiverCardOnDemand = ({ caregiver }) => {
	return <CaregiverCard caregiver={caregiver} isInternalStaff={false} />;
};

export default CaregiverCardOnDemand;
