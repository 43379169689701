import React, { memo } from 'react';
import MultiSelectSearch from '../../components/MultiSelectSearch/MultiSelectSearch';

const FiltersSection = ({ filterOptions, filters, updateFilters }) => {
	const handleMultiSelectChange = (_options, fieldName) => {
		// here, we want to get the selected options in a flat array, by getting the ones that are checked
		// and then getting its value. After that, we use the array lenth to validate if all the elements
		// are selected or not.
		const selected = _options.filter((option) => option.checked).map((option) => option.value);
		const isSelectAll = _options.length === selected.length;

		// we update dynamically the options array, updating the option that matches with the fieldName,
		// and then copying the rest of the elements in the object
		const updatedOptions = Object.fromEntries(
			Object.entries(filterOptions).map((option) => {
				if (option[0] === fieldName) {
					return [fieldName, _options];
				}
				return option;
			})
		);

		const _selected = isSelectAll ? undefined : selected;

		// we update dynamically the filters array, updating the filter that matches with the fieldName name,
		// and then copying the rest of the elements in the array
		const updatedFilters = Object.fromEntries(
			Object.entries(filters).map(([key, value]) => {
				return key === fieldName ? [key, _selected] : [key, value];
			})
		);

		// Update the state with the new options and filters
		updateFilters(updatedOptions, updatedFilters);
	};

	return (
		<>
			<MultiSelectSearch
				label="Facilities"
				options={filterOptions.facilities}
				onApply={(options) => handleMultiSelectChange(options, 'facilities')}
			/>
			<MultiSelectSearch
				label="Shift type"
				options={filterOptions.shiftTypes}
				onApply={(options) => handleMultiSelectChange(options, 'shiftTypes')}
			/>
			<div className="right-align-dropdown">
				<MultiSelectSearch
					label="Start time"
					options={filterOptions.startTimes}
					onApply={(options) => handleMultiSelectChange(options, 'startTimes')}
				/>
			</div>
		</>
	);
};

export default memo(FiltersSection);
