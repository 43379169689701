import React from 'react';
import Icon from './Icon';

/**
 * CheckIcon component displays an icon representing check mark.
 *
 * @component
 * @param {Object} props - The component properties.
 * @param {number} [props.width=24] - Width of the icon.
 * @param {number} [props.height=24] - Height of the icon.
 * @param {string} [props.color] - Color of the icon.
 * @param {string} [props.className] - Additional CSS classes for styling.
 * @returns {JSX.Element} AccountManagementIcon component.
 */
const AppsIcon = React.memo(({ width = 24, height = 24, color, className }) => {
	return (
		<Icon
			width={width}
			height={height}
			viewBox="0 0 24 24"
			color={color}
			className={className}
			path="M4 8H8V4H4V8ZM10 20H14V16H10V20ZM8 20H4V16H8V20ZM4 14H8V10H4V14ZM14 14H10V10H14V14ZM16 4V8H20V4H16ZM14 8H10V4H14V8ZM16 14H20V10H16V14ZM20 20H16V16H20V20Z"
		/>
	);
});

export default AppsIcon;
