import React from 'react';
import CaregiverCard from './CaregiverCard';

import { CheckIcon, DisabledIcon } from '../icons';
import BaseBadge from '../badges/BaseBadge';

import './CaregiverCard.scss';

/**
 * NameBadges component.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.caregiver - The caregiver object.
 * @returns {JSX.Element} - JSX element representing the NameBadges.
 */
const NameBadges = ({ caregiver }) => {
	if (caregiver.is_active) {
		if (caregiver.is_disabled) {
			return <BaseBadge badgeCopy="Disabled" className="cancelled-badge" icon={<DisabledIcon />} />;
		}
		return <BaseBadge badgeCopy="Enabled" className="confirmed-badge" icon={<CheckIcon />} />;
	}
	return <BaseBadge badgeCopy="Deactivated" className="deactivated-badge" icon={<DisabledIcon />} />;
};

/**
 * CaregiverCardInternalStaff component.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.caregiver - The caregiver object.
 * @returns {JSX.Element} - JSX element representing the CaregiverCardInternalStaff.
 */
const CaregiverCardInternalStaff = ({ caregiver, onCaregiverWorkerStatusAction }) => {
	return (
		<CaregiverCard
			caregiver={caregiver}
			hideStats
			hideContactInfo={!caregiver.is_active}
			nameBadge={<NameBadges caregiver={caregiver} />}
			onCaregiverWorkerStatusAction={onCaregiverWorkerStatusAction}
		/>
	);
};

export default CaregiverCardInternalStaff;
