export const SHIFT_STATUS = {
	CLAIMED: 'Claimed',
	UNCLAIMED: 'Unclaimed',
	CONFIRMED: 'Confirmed',
	ARRIVED: 'Arrived'
};

export const SHIFT_FEATURES = {
	BUNDLES: 'Bundles',
	ORIENTATION: 'Orientation',
	GUARANTEED: 'Guaranteed',
	BONUS: 'Bonus'
};

export default SHIFT_STATUS;
