/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment-timezone';
import guaranteedIcon from '../../assets/images/guaranteed-icon.svg';
import refreshIcon from '../../assets/images/refresh-purple.svg';
import DateRangeSelector from '../../components/range-selector/DateRangeSelector';
import { useExternalAgencyOpenShiftsQuery } from '../../redux/externalAgencyShifts';
import { applyFilters } from '../../utils/FilterFunctions';
import utils from '../../utils/utils';
import FiltersSection from './FiltersSection';
import ShiftsCountBanner from './ShiftsCountBanner';

const AgencyOpenShifts = () => {
	const DEFAULT_FILTER_OPTIONS = {
		facilities: [],
		shiftTypes: [],
		startTimes: []
	};
	const [filterOptions, setFilterOptions] = useState(DEFAULT_FILTER_OPTIONS);
	const [filters, setFilters] = useState(DEFAULT_FILTER_OPTIONS);
	const [rangeStart, setRangeStart] = useState(new Date());
	const [rangeEnd, setRangeEnd] = useState(moment(rangeStart).add(13, 'day').toDate());
	const [shifts, setShifts] = useState([]);
	const [assignProfessional, setAssignProfessional] = useState(null);
	const [loading, setLoading] = useState(true);

	const { data: openShifts, refetch: reloadOpenShifts, isFetching, error } = useExternalAgencyOpenShiftsQuery(
		{ start: moment(rangeStart).format('YYYY-MM-DD'), end: moment(rangeEnd).format('YYYY-MM-DD') },
		{ refetchOnMountOrArgChange: true }
	);

	useEffect(() => {
		if (error) {
			setLoading(false);
		}
	}, [error]);

	useEffect(() => {
		setRangeEnd(moment(rangeStart).add(13, 'day'));
	}, []);

	useEffect(() => {
		if (openShifts) {
			const addedFacilities = {};
			const addedTypes = {};
			const addedTimes = {};
			const _facilities = [];
			const _shiftTypes = [];
			const _startTimes = [];
			for (let i = 0; i < openShifts.length; i++) {
				const {
					facility_id: facilityId,
					facility_name: facilityName,
					resource_type: resourceType,
					specialty,
					start_hour_tz: startHour
				} = openShifts[i];
				if (!addedFacilities[facilityId]) {
					addedFacilities[facilityId] = 1;
					const _selected = !filters.facilities.length || filters.facilities.includes(facilityId);
					_facilities.push({ value: facilityId, label: facilityName, checked: _selected });
				}
				/* eslint-disable-next-line prefer-template */
				const shiftType = `${resourceType}${specialty ? ' - ' + specialty : ''}`;
				if (!addedTypes[shiftType]) {
					addedTypes[shiftType] = 1;
					const _selected = !filters.shiftTypes.length || filters.shiftTypes.includes(shiftType);
					_shiftTypes.push({ value: shiftType, label: shiftType, checked: _selected });
				}
				if (!addedTimes[startHour]) {
					addedTimes[startHour] = 1;
					const _selected = !filters.startTimes.length || filters.startTimes.includes(startHour);
					_startTimes.push({ value: startHour, label: startHour, checked: _selected });
				}
			}
			setFilterOptions({
				facilities: utils.sort(_facilities, 'value'),
				shiftTypes: utils.sort(_shiftTypes, 'value'),
				startTimes: utils.sortTimeStrings(_startTimes, 'value')
			});
		}
	}, [openShifts]);

	useEffect(() => {
		const filteredShifts = applyFilters({ items: openShifts || [], filters });
		setShifts(filteredShifts);
	}, [openShifts, filters]);

	const updateFilters = (updatedOptions, updatedFilters) => {
		setFilterOptions(updatedOptions);
		setFilters(updatedFilters);
	};

	const hasFiltersApplied = filters.facilities?.length || filters.shiftTypes?.length || !!filters.startTimes?.length;

	const shiftTypeTemplate = (item) => (
		<div className="d-flex">
			<div className="flex-one">
				{item.resource_type} {item.specialty && ` - ${item.specialty}`}
			</div>
			{item.is_guaranteed && <img src={guaranteedIcon} alt="Guaranteed" />}
		</div>
	);
	const estimatedPayTemplate = (item) => (
		<span>
			${item.estimated_pay} <br />
			(estimate)
		</span>
	);
	const rateTemplate = (item) => <span>${Number(item.rate).toFixed(2)}/hr</span>;
	const startDateTemplate = (item) => (
		<span>{moment.tz(item.start_time, item.facility_timezone).format('dddd, MMM D, YYYY')}</span>
	);
	const timePeriodTemplate = (item) => (
		<span>
			{item.start_hour_tz} - {item.end_hour_tz} {moment.tz(item.start_time, item.facility_timezone).format('z')}
			<br />({item.break_duration} min break)
		</span>
	);
	const professionalTemplate = (item) => (
		/* eslint-disable-next-line */
		<div className="assign-button" onClick={(e) => setAssignProfessional(item)}>
			+ Assign
		</div>
	);
	const facilityTemplate = (item) => (
		<OverlayTrigger
			trigger={['hover', 'focus']}
			key="facility-tooltip"
			placement="bottom"
			overlay={
				<Tooltip className="facility-menu-item-tooltip" placement="bottom" show>
					<span>{item.facility_address}</span>
					<br />
					{item.address2 && (
						<span>
							{item.address2}
							<br />
						</span>
					)}
					<span>
						{item.facility_city}, {item.facility_state} {item.facility_zip_code}
					</span>
				</Tooltip>
			}
		>
			<span>{item.facility_name}</span>
		</OverlayTrigger>
	);
	return (
		<>
			<div className="toolbar">
				<DateRangeSelector
					rangeStart={rangeStart}
					rangeEnd={rangeEnd}
					setRangeStart={setRangeStart}
					setRangeEnd={setRangeEnd}
					minDate={new Date()}
					proTip="Try selecting a date range that is within 2 weeks of the start and end date. If a range is too big it will only show the first 2000 shifts from the start date to ensure a smoother experience."
				/>

				<button type="button" className="btn refresh-button" onClick={reloadOpenShifts}>
					<img src={refreshIcon} alt="Refresh" />
					<div className="button-label">Refresh Feed</div>
				</button>
				<div className="flex-one" />
				<FiltersSection filterOptions={filterOptions} filters={filters} updateFilters={updateFilters} />
			</div>
			<div className="datatable-wrapper">
				<div className="wrapper-container">
					<DataTable
						value={shifts}
						scrollable
						scrollHeight="flex"
						sortMode="multiple"
						virtualScrollerOptions={{ itemSize: 65 }}
						tableStyle={{ minWidth: '100rem' }}
						size="small"
						stripedRows
						cellClassName="table-cell"
						footer={
							shifts?.length ? (
								<ShiftsCountBanner shiftsCount={shifts.length} filtersApplied={hasFiltersApplied} />
							) : null
						}
					>
						<Column field="start_time" header="Date" style={{ width: '10%' }} sortable body={startDateTemplate} />
						<Column field="shift_type" header="Shift Type" style={{ width: '15%' }} body={shiftTypeTemplate} />
						<Column field="slots_count" header="Shifts" style={{ width: '8%' }} sortable />
						<Column field="facility_name" header="Facility" style={{ width: '17%' }} sortable body={facilityTemplate} />
						<Column field="start_hour_tz" header="Time" style={{ width: '15%' }} body={timePeriodTemplate} />
						<Column
							field="estimated_pay"
							header="Est. pay"
							style={{ width: '10%' }}
							sortable
							body={estimatedPayTemplate}
						/>
						<Column field="rate" header="Rate" style={{ width: '8%' }} sortable body={rateTemplate} />
						<Column field="facility_city" header="City" style={{ width: '10%' }} sortable />
						<Column field="facility_state" header="State" style={{ width: '7%' }} sortable />
						<Column field="facility_zip_code" header="ZIP" style={{ width: '8%' }} sortable />
						<Column
							field="professional"
							header="Professional"
							style={{ width: '10%' }}
							alignFrozen="right"
							frozen
							body={professionalTemplate}
						/>
					</DataTable>
				</div>
			</div>
		</>
	);
};

export default AgencyOpenShifts;
