/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import classNames from 'classnames';
import LoadingSpinner from '../spinner/LoadingSpinner';
import CloseIcon from '../../assets/images/close.svg';

import './SignOutConfirmationModal.css';

const ConfirmationModal = (props) => {
	const {
		showCancel = true,
		size,
		onCancel,
		backdropClassName,
		title,
		customModalBodyClass,
		showLoadingSpinner,
		children,
		cancelLabel,
		onConfirm,
		confirmLabel,
		className,
		scrollable,
		reverseButtons = false
	} = props;
	const cancelButton = (
		<Button onClick={onCancel} variant={reverseButtons ? 'primary' : 'tertiary'}>
			{cancelLabel || 'GO BACK'}
		</Button>
	);
	return (
		<Modal
			size={size || 'md'}
			show
			aria-labelledby="contained-modal-title-vcenter"
			centered
			scrollable={!!scrollable}
			className={classNames('sign-out-confirmation-modal', className)}
			onHide={onCancel}
			backdropClassName={backdropClassName}
		>
			<Modal.Header>
				<Modal.Title id="contained-modal-title-vcenter">
					<div className="flex-grow-1">{title || 'Confirm'}</div>
					<img src={CloseIcon} alt="close" onClick={onCancel} />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className={customModalBodyClass}>
				{!showLoadingSpinner ? (
					children
				) : (
					<div className="spinner-wrapper">
						<LoadingSpinner key="loading-spinner" />
					</div>
				)}
			</Modal.Body>
			<Modal.Footer>
				{showCancel && !reverseButtons && cancelButton}
				<Button onClick={onConfirm} variant={reverseButtons ? 'secondary' : 'primary'} disabled={showLoadingSpinner}>
					{confirmLabel || 'CONFIRM'}
				</Button>
				{showCancel && reverseButtons && cancelButton}
			</Modal.Footer>
		</Modal>
	);
};

export default ConfirmationModal;
