/* eslint-disable */
import React, { useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import manage_account from '../../assets/images/manage_accounts.svg';
import { Menu } from 'primereact/menu';
import { useAgencyProfessionalsQuery } from '../../redux/external-agency';
import LoadingSpinner from '../../components/spinner/LoadingSpinner';

const mapCaregiversData = (data) =>
	data?.map((caregiver) => {
		const { id, name, status, specialties, certifications, state_code, city_name } = caregiver;

		const licenses = certifications?.map((certif) => certif?.resource_type) || [];
		const mappedSpecialties =
			specialties?.map((specialty) => `${specialty.code.split('_', 1)} - ${specialty.label}`) || [];

		return {
			id,
			name,
			status,
			licenses,
			specialties: mappedSpecialties.sort((a, b) => a.localeCompare(b)),
			state: state_code,
			city: city_name
		};
	});

const AgencyProfessionalsList = () => {
	const actionMenus = useRef({});
	const { data, isLoading } = useAgencyProfessionalsQuery(undefined, {
		refetchOnMountOrArgChange: true
	});
	const professionals = data ? mapCaregiversData(data) : [];

	const statusTemplate = (item) => {
		const cellClasses = item.status === 'enabled' ? 'column-status' : 'column-status disabled';
		return <span className={cellClasses}>{item.status}</span>;
	};

	const licenseTypeTemplate = (item) => <span>{item.licenses?.length > 0 ? item.licenses.join(', ') : '-'}</span>;
	const specialtyTemplate = (item) => {
		const hasSpecialties = item.specialties?.length > 0;
		const isEnabled = item.status === 'enabled';
		return (
			<span
				style={{
					textDecoration: hasSpecialties && !isEnabled ? 'line-through' : 'none'
				}}
			>
				{hasSpecialties ? item.specialties.join(', ') : '-'}
			</span>
		);
	};

	const professionalTemplate = (caregiver) => {
		if (!actionMenus.current[caregiver.id]) {
			actionMenus.current[caregiver.id] = React.createRef();
		}

		return (
			<>
				<button
					type="button"
					className="btn"
					onClick={(event) => actionMenus.current[caregiver.id].current.toggle(event)}
					aria-controls="popup_menu_left"
					aria-haspopup
				>
					<img src={manage_account} alt={'manage account'} />
				</button>
				<Menu
					className={'professionals-actions'}
					model={[{ label: 'Manage Profile', url: `/private/agency/professionals/employee-details/${caregiver.id}` }]}
					popup
					ref={actionMenus.current[caregiver.id]}
					id="popup_action"
				/>
			</>
		);
	};

	const defaultTemplate = (item, columnData) => {
		const { field } = columnData;
		return item[field] ? item[field] : '-';
	};

	const rowClass = (item) => ({
		'row-disabled': item.status === 'deactivated'
	});

	const cellClass = (item, columnData) => ({
		'table-cell': true,
		'no-line-through': ['status', 'actions'].includes(columnData.field)
	});

	return (
		<>
			<div className="toolbar">
				<p className={'total-count'}>
					{professionals.length} Professional{professionals.length > 1 ? 's' : ''}
				</p>
				<div className="flex-one" />
			</div>

			<DataTable
				value={professionals}
				scrollable
				scrollHeight="flex"
				removableSort
				sortField="name"
				virtualScrollerOptions={{ itemSize: 64 }}
				tableStyle={{ minWidth: '100rem' }}
				size="small"
				stripedRows
				cellClassName={cellClass}
				rowClassName={rowClass}
				loading={isLoading}
				loadingIcon={<LoadingSpinner />}
			>
				<Column field="name" header="Professional" style={{ width: '15%' }} sortable body={defaultTemplate} />
				<Column field="status" header="Status" style={{ width: '8%' }} sortable body={statusTemplate} />
				<Column field="licenses" header="License type" style={{ width: '8%' }} sortable body={licenseTypeTemplate} />
				<Column field="specialties" header="Specialty" style={{ width: '17%' }} sortable body={specialtyTemplate} />
				<Column field="city" header="City" style={{ width: '15%' }} sortable body={defaultTemplate} />
				<Column field="state" header="State" style={{ width: '4%' }} sortable body={defaultTemplate} />
				<Column
					field="actions"
					header="Actions"
					style={{ width: '8%' }}
					alignFrozen="right"
					body={professionalTemplate}
				/>
			</DataTable>
		</>
	);
};

export default AgencyProfessionalsList;
