import React, { useCallback, useEffect, useMemo, useRef, useState, useContext } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { FilterMatchMode, FilterService, PrimeReactProvider, PrimeReactContext } from 'primereact/api';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { MultiSelect } from 'primereact/multiselect';
import { OverlayPanel } from 'primereact/overlaypanel';

import LoadingSpinner from '../../components/spinner/LoadingSpinner';
import { useGetReportConfigQuery, useGetReportDataQuery } from '../../redux/reports';
import { showNotification } from '../../redux/notification';
import PRIVATE_PATH from '../../constants/Paths';

import 'primereact/resources/themes/lara-light-purple/theme.css';
import 'primereact/resources/primereact.min.css';
import './ReportScreen.scss';
import { ArrowRightIcon, CalendarIcon, CaretIcon, CheckIcon } from '../../components/icons';
import searchIcon from '../../assets/images/search-icon.svg';
import IsAuthorizedForManagementCompanyGlobal from '../../components/IsAuthorizedForManagementCompanyGlobal/IsAuthorizedForManagementCompanyGlobal';

const getBody = (column) => (row) => {
	if (column.isArray) {
		return (
			<>
				{row[column.field]?.map((value, index) => `${value}${row[column.field][index + 1] === undefined ? '' : ', '}`)}
			</>
		);
	}

	if (column.formatTimezone && row[column.field]) {
		return moment(row[column.field]).format(column.formatTimezone);
	}

	return row[column.field] || undefined;
};

const DateRange = React.memo(({ parameter, reportDates, onApply, maxRange }) => {
	const [localDates, setLocalDates] = useState(reportDates);
	const [calendarRange, setCalendarRange] = useState({ from: null, to: null });
	const { setNullSortOrder } = useContext(PrimeReactContext);
	const overlayPanelRef = useRef(null);

	useEffect(() => {
		setNullSortOrder(-1);
	}, [setNullSortOrder]);

	const handleApply = useCallback(() => {
		onApply(localDates);
		overlayPanelRef.current.hide();
	}, [onApply, localDates]);

	const handleCancel = useCallback(() => {
		setCalendarRange({ from: null, to: null });
		setLocalDates(reportDates);
	}, [reportDates]);

	const handleDateChange = useCallback(
		(e) => {
			const [from, to] = e.value;
			if (!to) {
				setCalendarRange({ from, to: moment(from).add(maxRange.value, maxRange.unit).toDate() });
			} else {
				setCalendarRange({ from: null, to: null });
			}
			setLocalDates({ from, to });
		},
		[maxRange]
	);

	const handleOverlayToggle = useCallback((e) => {
		overlayPanelRef?.current?.toggle(e);
	}, []);

	const isApplyDisabled = useMemo(() => {
		return !localDates.from || !localDates.to || localDates.from > localDates.to;
	}, [localDates.from, localDates.to]);

	const dateTemplate = useCallback(
		(date) => {
			const currentDate = new Date(date.year, date.month, date.day);

			let className = '';

			if (localDates.from && localDates.to) {
				if (currentDate.toDateString() === localDates.from.toDateString()) {
					className = 'from';
				} else if (currentDate.toDateString() === localDates.to.toDateString()) {
					className = 'to';
				} else if (currentDate > localDates.from && currentDate < localDates.to) {
					className = 'between';
				}
			}

			return <span className={className}>{date.day}</span>;
		},
		[localDates]
	);

	return (
		<div className="date-range-parameter">
			<Button
				className="date-range-button"
				onClick={handleOverlayToggle}
				aria-label={`Open date range filter for ${parameter.uniqueId}`}
			>
				<div className="date-range-button-label">
					<span>{localDates.from ? moment(localDates.from).format('MM/DD/YYYY') : ''}</span>
					<ArrowRightIcon width={18} height={18} />
					<span>{localDates.to ? moment(localDates.to).format('MM/DD/YYYY') : ''}</span>
					<CalendarIcon width={18} height={18} />
				</div>
			</Button>
			<OverlayPanel ref={overlayPanelRef} onHide={handleCancel}>
				<Calendar
					value={[localDates.from, localDates.to]}
					onChange={handleDateChange}
					selectionMode="range"
					showOtherMonths={false}
					numberOfMonths={2}
					minDate={calendarRange.from}
					maxDate={calendarRange.to}
					readOnlyInput
					inline
					dateTemplate={dateTemplate}
				/>
				<div className="date-range-actions">
					<Button text label="Reset" size="large" onClick={handleCancel} />
					<Button rounded label="Apply" size="large" onClick={handleApply} disabled={isApplyDisabled} />
				</div>
			</OverlayPanel>
		</div>
	);
});

const CustomMultiSelectHeader = React.memo(
	({ filterValue, filterPlaceholder, onSearchChange, onSelectAll, allSelected, searchValue }) => {
		const selectedCount = filterValue.length;
		const [localSearchValue, setLocalSearchValue] = useState('');

		return (
			<div className="p-multiselect-header custom-header">
				<div className="p-multiselect-filter-container">
					<span className="p-input-icon-right">
						<img src={searchIcon} alt="search" />
						<input
							className="p-multiselect-filter p-inputtext p-component"
							type="text"
							value={searchValue}
							onChange={(e) => {
								onSearchChange(e.target.value);
								setLocalSearchValue(e.target.value);
							}}
							placeholder={filterPlaceholder}
						/>
					</span>
				</div>
				<div className="selected-count">
					<strong>Selected ({selectedCount})</strong>
				</div>
				<div
					className="p-checkbox p-component"
					onClick={onSelectAll}
					onKeyDown={(e) => {
						if (e.key === 'Enter' || e.key === ' ') {
							onSelectAll();
						}
					}}
					role="checkbox"
					aria-checked={allSelected}
					tabIndex={0}
				>
					<div className={`p-checkbox-box ${allSelected ? 'p-highlight' : ''}`}>
						{allSelected && <CheckIcon color="#fff" />}
					</div>
					{localSearchValue ? 'Select All Search Results' : 'Select All'}
				</div>
			</div>
		);
	}
);

const Header = React.memo(
	({ uiConfig = {}, reportData = [], reportDates, updateReportDates, filters, setFilters, exportCsv }) => {
		const [filtersConfig, setFiltersConfig] = useState([]);
		const { reportConfig, report } = uiConfig;

		const onFilterChange = useCallback(
			(field, value) => {
				setFilters((prevFilters) => ({
					...prevFilters,
					[field]: { ...(prevFilters[field] || {}), value }
				}));
			},
			[setFilters]
		);

		const resetFilteredOptions = useCallback((field) => {
			setFiltersConfig((prevFiltersConfig) => {
				return prevFiltersConfig.map((filter) => {
					if (filter.field === field) {
						return { ...filter, filteredOptions: filter.availableOptions };
					}
					return filter;
				});
			});
		}, []);

		const onSearchChange = useCallback(
			(field, searchValue) => {
				const lowercaseSearchValue = searchValue.toLowerCase();
				const filtered = filtersConfig
					.find((filter) => filter.field === field)
					.availableOptions.filter((option) => option.label.toLowerCase().includes(lowercaseSearchValue));
				setFiltersConfig((prevFiltersConfig) => {
					const currentFilter = prevFiltersConfig.find((filter) => filter.field === field);
					if (!currentFilter) return prevFiltersConfig;

					return prevFiltersConfig.map((filter) =>
						filter.field === field ? { ...filter, filteredOptions: filtered } : filter
					);
				});
			},
			[filtersConfig]
		);

		useEffect(() => {
			if (reportConfig.columns && reportData) {
				const _filtersConfig = [];
				const newFilters = {};

				reportConfig.columns.forEach((column) => {
					if (column.filterable && column.dataType !== 'date') {
						const uniqueValues = new Set();
						reportData.forEach((item) => {
							const value = item[column.field];
							if (column.isArray && Array.isArray(value)) {
								value.forEach((v) => uniqueValues.add(v));
							} else {
								uniqueValues.add(value);
							}
						});
						const availableOptions = Array.from(uniqueValues).map((value) => ({ label: value || 'Empty', value }));
						availableOptions.sort((a, b) => {
							if (!a.value && !b.value) return 0;
							if (!a.value) return -1;
							if (!b.value) return 1;
							return a.label.localeCompare(b.label);
						});
						_filtersConfig.push({
							name: column.name,
							field: column.field,
							dataType: 'multiSelect',
							availableOptions,
							filteredOptions: availableOptions
						});

						newFilters[column.field] = {
							value: availableOptions.map((option) => option.value),
							matchMode: column.isArray ? FilterMatchMode.CUSTOM : FilterMatchMode.IN
						};
					}
				});

				setFiltersConfig(_filtersConfig);
				setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
			}
		}, [reportConfig, reportData, setFilters]);

		if (!reportConfig.columns || !reportConfig.parameters || !report.name) return null;

		return (
			<div className="report-screen-header" style={{ alignItems: 'flex-start', flexWrap: 'wrap' }}>
				<Link to={PRIVATE_PATH.REPORTS} className="back-button">
					{' '}
					<CaretIcon pointTo="left" height={16} width={16} /> Reports
				</Link>
				<div className="report-screen-title">
					<div className="report-screen-title-label">{report.name}</div>
					{reportConfig.parameters.map(
						(parameter) =>
							parameter.type === 'date-range' && (
								<DateRange
									key={parameter.uniqueId}
									parameter={parameter}
									reportDates={reportDates}
									onApply={updateReportDates}
									maxRange={parameter.maxRange}
									className="report-screen-date-range"
								/>
							)
					)}
					<IsAuthorizedForManagementCompanyGlobal exactPermission={report.download_permission}>
						<Button
							type="button"
							className="report-screen-download-btn"
							label="Download"
							outlined
							onClick={exportCsv}
							data-pr-tooltip="Download"
						/>
					</IsAuthorizedForManagementCompanyGlobal>
				</div>
				<div className="report-screen-filters">
					Filter by:
					{filtersConfig.map((filterConfig) => {
						const allSelected =
							filterConfig.filteredOptions.length > 0 &&
							filterConfig.filteredOptions.every((option) =>
								filters[filterConfig.field]?.value?.includes(option.value)
							);
						return (
							<div className="report-screen-filter">
								{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
								<label>{filters[filterConfig.field].value.length > 0 && filterConfig.name}</label>
								<MultiSelect
									key={filterConfig.field}
									filterPlaceholder={filterConfig.name}
									value={filters[filterConfig.field]?.value || []}
									options={filterConfig.filteredOptions}
									maxSelectedLabels={0}
									onChange={(e) => onFilterChange(filterConfig.field, e.value)}
									onHide={() => resetFilteredOptions(filterConfig.field)}
									optionLabel="label"
									placeholder={filterConfig.name}
									aria-label={`Multi-select filter for ${filterConfig.name}`}
									resetFilterOnHide
									filter
									panelHeaderTemplate={() => (
										<CustomMultiSelectHeader
											filterValue={filters[filterConfig.field]?.value || []}
											onSelectAll={() => {
												const allValues = filterConfig.filteredOptions.map((option) => option.value);
												onFilterChange(filterConfig.field, allSelected ? [] : allValues);
											}}
											allSelected={allSelected}
											filterPlaceholder={filterConfig.name}
											onSearchChange={(value) => onSearchChange(filterConfig.field, value)}
										/>
									)}
								/>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
);

const ReportScreen = (props) => {
	// Constants
	const headerHeight = 260;

	// Props and Redux
	const dispatch = useDispatch();
	const { reportCode } = props.match.params;

	// State declarations
	const [dateRangeParamUniqueId, setDateRangeParamUniqueId] = useState();
	const [reportDates, setReportDates] = useState({ from: null, to: null });
	const [filters, setFilters] = useState({});
	const [scrollHeight, setScrollHeight] = useState(() => window.innerHeight - headerHeight - 40);

	// Refs
	const containerRef = useRef(null);
	const dataTableRef = useRef(null);

	// Queries
	const {
		data: uiConfig,
		isLoading: isConfigLoading,
		isFetching: isConfigFetching,
		error: configError
	} = useGetReportConfigQuery(reportCode, {
		refetchOnMountOrArgChange: true
	});

	const {
		data: reportData,
		isLoading: isDataLoading,
		isFetching: isDataFetching,
		error: dataError
	} = useGetReportDataQuery(
		{
			reportCode,
			dateRangeParam: {
				uniqueId: dateRangeParamUniqueId,
				values: [moment(reportDates.from).format('YYYY-MM-DD'), moment(reportDates.to).format('YYYY-MM-DD')]
			}
		},
		{
			refetchOnMountOrArgChange: true,
			skip: !reportDates.from || !reportDates.to
		}
	);

	// Memoized values and callbacks
	const updateReportDates = useCallback((newReportDates) => setReportDates(newReportDates), []);
	const globalFilterFields = useMemo(() => uiConfig?.reportConfig?.columns.map((col) => col.field) || [], [uiConfig]);

	const columns = useMemo(
		() =>
			uiConfig?.reportConfig?.columns.map((column) => {
				if (column.isArray) {
					FilterService.register(`custom_${column.field}`, (values, filterValues) => {
						if (!filterValues || filterValues.length === 0) return true;
						return filterValues.some((filter) => (!values ? filter === null : values.includes(filter)));
					});
				}
				return (
					<Column
						key={column.field}
						field={column.field}
						header={column.name}
						sortable={column.sortable || false}
						body={getBody(column)}
						aria-label={`Sortable column for ${column.name}`}
					/>
				);
			}) || [],
		[uiConfig]
	);

	useEffect(() => {
		const calculateScrollHeight = () => {
			if (containerRef.current) {
				const windowHeight = window.innerHeight;
				const containerTop = containerRef.current.getBoundingClientRect().top;
				const availableHeight = windowHeight - containerTop;
				containerRef.current.style.height = `${availableHeight}px`;
				const newScrollHeight = availableHeight - headerHeight;
				setScrollHeight(Math.max(newScrollHeight, 300));
			}
		};

		calculateScrollHeight();
		window.addEventListener('resize', calculateScrollHeight);
		return () => window.removeEventListener('resize', calculateScrollHeight);
	}, []);

	useEffect(() => {
		if (uiConfig && uiConfig.report && uiConfig.reportConfig) {
			const { reportConfig } = uiConfig;
			if (reportConfig?.parameters) {
				const dateRangeParam = reportConfig.parameters.find((param) => param.type === 'date-range');
				if (dateRangeParam?.from && dateRangeParam?.to) {
					setDateRangeParamUniqueId(dateRangeParam.uniqueId);
					setReportDates({ from: new Date(dateRangeParam.from.value), to: new Date(dateRangeParam.to.value) });
				}
			}
		}
	}, [uiConfig]);

	useEffect(() => {
		if (configError && !isConfigLoading && !isConfigFetching) {
			dispatch(showNotification('error', 'Failed to load report configuration.'));
		}
		if (dataError && !isDataLoading && !isDataFetching) {
			dispatch(showNotification('error', 'Failed to load report data.'));
		}
	}, [configError, dataError, dispatch, isConfigFetching, isConfigLoading, isDataFetching, isDataLoading]);

	if (
		isConfigLoading ||
		isConfigFetching ||
		isDataLoading ||
		isDataFetching ||
		!uiConfig ||
		(!reportData && !configError && !dataError)
	) {
		return <LoadingSpinner />;
	}

	const exportCSV = () => {
		dataTableRef.current.exportCSV();
	};

	return (
		!configError &&
		!dataError && (
			<div id="report-screen-container" className="report-screen-container" ref={containerRef}>
				<DataTable
					ref={dataTableRef}
					value={reportData}
					paginator
					paginatorClassName="custom-paginator"
					paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
					filters={filters}
					rows={100}
					rowsPerPageOptions={[10, 25, 50, 100]}
					removableSort
					globalFilterFields={globalFilterFields}
					header={
						<Header
							uiConfig={uiConfig}
							reportData={reportData}
							reportDates={reportDates}
							updateReportDates={updateReportDates}
							filters={filters}
							setFilters={setFilters}
							exportCsv={exportCSV}
						/>
					}
					scrollable
					showGridlines
					scrollHeight={scrollHeight}
					filterDisplay="menu"
					tableStyle={{ minWidth: '50rem' }}
					className="report-screen-page"
					aria-label="Report data table"
					exportFilename={uiConfig.report.name}
					sortField={uiConfig.reportConfig.sort.primarySortField}
					sortOrder={uiConfig.reportConfig.sort.sortOrder}
					multiSortMeta={uiConfig.reportConfig.sort.multiSortMeta}
					currentPageReportTemplate="{first} to {last} of {totalRecords}"
				>
					{columns}
				</DataTable>
			</div>
		)
	);
};

const Wrapper = (props) => (
	<PrimeReactProvider>
		{/* eslint-disable-next-line react/jsx-props-no-spreading */}
		<ReportScreen {...props} />
	</PrimeReactProvider>
);

export default withRouter(Wrapper);
