import React from 'react';
import classNames from 'classnames';

import Checkbox from '../../../form/Checkbox';
import guaranteedIcon from '../../../../assets/images/guaranteed-icon.svg';
import orientationIcon from '../../../../assets/images/compass.svg';
import config from '../../../../config';
import Utils from '../../../../utils/utils';

import InvertedWarningTriangleIcon from '../../../../assets/images/warning-triangle-inverted.svg';
import BundleBadge from '../../../../features/shifts/BundleBadge';

const SlotView = (props) => {
	return [
		<div key={`day-${props.day.shifts[0].shift_id}`}>
			{props.slots.map((slot, index, slots) => {
				if (props.hasErrors.length > 0 && !props.hasErrors.includes(slot.slot_id)) {
					return null;
				}
				return [
					<SlotViewData
						key={`slot-data-${slot.slot_id}`}
						checkedSlots={props.checkedSlots}
						toggleSlotCheck={props.toggleSlotCheck}
						hideCheckbox={props.hideCheckbox}
						slot={slot}
						day={props.day}
						hasErrors={props.hasErrors}
					/>,
					slots[index + 1] && slots[index + 1].resource_type !== slot.resource_type && (
						<hr key={`day-separator-${props.day.shifts[0].shift_id}`} />
					)
				];
			})}
		</div>
	];
};

const SlotViewData = (props) => {
	const { slot, hasErrors } = props;

	const generateCredentialFileName = () => {
		const [firstName, lastName] = props.slot.caregiver_name.toLowerCase().split(' ');
		return `${firstName[0]}${lastName}.pdf`;
	};

	return (
		<div className="slot py-1 mb-2">
			<div className="d-flex flex-wrap align-items-center w-100 px-0">
				<ul className="d-flex justify-content-between w-100 px-0">
					<li className={classNames({ 'table-body-column-errored': hasErrors.length > 0 }, `table-body-column col-1`)}>
						{!props.hideCheckbox && (
							<Checkbox
								id={`checkbox-${slot.slot_id}`}
								className="slot-checkbox"
								handleChange={() => props.toggleSlotCheck(slot.slot_id)}
								checked={props.checkedSlots.includes(slot.slot_id)}
							/>
						)}
						{hasErrors.length > 0 && <img src={InvertedWarningTriangleIcon} alt="change to" className="arrow-icon" />}
					</li>
					<li
						className={classNames(
							{ 'table-body-column-errored': hasErrors.length > 0 },
							'table-body-column col-1 font-weight-bold'
						)}
					>
						{slot.resource_type}
					</li>
					<li
						className={classNames(
							{ 'table-body-column-errored': hasErrors.length > 0 },
							'table-body-column col-2 font-weight-bold'
						)}
					>
						{slot.caregiver_name}
					</li>
					<li
						className={classNames(
							{ 'table-body-column-errored': hasErrors.length > 0 },
							'table-body-column col-1 col-lg-2 font-weight-bold'
						)}
					>
						{Utils.formatToSimpleTime(slot.raw_start_time)} - {Utils.formatToSimpleTime(slot.raw_end_time)}
					</li>
					<li className={classNames({ 'table-body-column-errored': hasErrors.length > 0 }, 'table-body-column col-2')}>
						{slot.attachment_id && (
							<a
								className="credential-packet-download"
								href={`${config.HOST_NAME}${config.API_URL}/slot/${slot.slot_id}/credential-packet`}
								target="_blank"
								rel="noreferrer"
							>
								{generateCredentialFileName()}
							</a>
						)}
					</li>
					<li
						className={classNames(
							{ 'table-body-column-errored': hasErrors.length > 0 },
							'table-body-column col-1 font-weight-bold'
						)}
					>
						{slot.facility_payable_bonus && <span>${props.slot.facility_payable_bonus}</span>}
					</li>
					<li
						className={classNames(
							{ 'table-body-column-errored': hasErrors.length > 0 },
							'table-body-column col-1 text-center justify-content-center'
						)}
					>
						{slot.is_guaranteed && <img className="guaranteed-icon" src={guaranteedIcon} alt="Guaranteed shift" />}
					</li>
					<li className={classNames({ 'table-body-column-errored': hasErrors.length > 0 }, 'table-body-column col-2')}>
						<SlotViewBadges slot={props.slot} shift={props.shift} />
					</li>
				</ul>
			</div>
		</div>
	);
};

const SlotViewBadges = (props) => {
	return (
		<div className="d-flex align-items-center flex-wrap">
			{props.slot.contract_id && (
				<div className="bundle-badge-slot-view" key="bundle-badge">
					<BundleBadge shiftsCount={props.slot.contract_non_cancelled_slots_count} bundleId={props.slot.contract_id} />
				</div>
			)}
			{props.slot.is_oriented_worker && (
				<div className="oriented-worker-badge ml-4" key="oriented-badge">
					ORIENTED WORKER
				</div>
			)}
			{props.slot.is_returning_worker && <div className="ml-4 returning-worker-badge">Returning Worker</div>}
			{props.slot.is_orientation && (
				<span className="ml-4 guaranteed-badge-span orientation">
					<img className="orientation-icon" src={orientationIcon} alt="Orientation shift" />
					Orientation
				</span>
			)}
		</div>
	);
};

export default SlotView;
