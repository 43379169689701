// React and related imports
import React, { forwardRef, memo, useCallback, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Highlighter, Typeahead } from 'react-bootstrap-typeahead';

import { error, setError } from '../../redux/error';
import { showNotification } from '../../redux/notification';
import { setCurrentFacility, user } from '../../redux/user';
import { setPreventNavigation, navigate } from '../../redux/navigate';
import useConditionalNavigation from '../hooks/useConditionalNavigation';
import useMediaQuery, { MEDIUM_SCREEN_WIDTH_QUERY } from '../hooks/useMediaQuery';
import useOnClickOutside from '../hooks/useOnClickOutside';

import { ERROR_CODES, FEATURE, PERMISSION, PRIVATE_PATH } from '../../constants';
import Utils from '../../utils/utils';
import xhr from '../../utils/xhr';
import ConfirmationModal from '../modals/ConfirmationModal';
import SignOutConfirmationModal from '../modals/SignOutConfirmationModal';
import ViewOnComputerModal from '../modals/ViewOnComputerModal';
import RouterPrompt from '../modals/BaseModal/RouterPrompt';
import AppHeaderMobile from './AppHeaderMobile';
import AppHeaderDesktop from './AppHeaderDesktop';
import ExternalAgencyHeader from './ExternalAgencyHeader';
import { AccountManagementIcon, CaretIcon, ReportsIcon, SignOutIcon, GlobalScheduleIcon, FacilityIcon } from '../icons';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import './NavbarMenus.scss';

const AppHeader = ({ history, location }) => {
	// Redux hooks and selectors
	const dispatch = useDispatch();
	const err = useSelector(error);
	const userData = useSelector(user) || {};
	const navigateSelector = useSelector(navigate) || {};

	// Refs
	const facilitiesSelectorRef = useRef(null);
	const dropdownMenuRef = useRef(null);
	const typeaheadRef = useRef(null);
	const toggleMenuRef = useRef(null);
	const rightMenuRef = useRef(null);

	const mobileContainerRef = useRef(null);
	const mobileHeaderRef = useRef(null);

	// State hooks
	const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
	const [showMobileMenu, setShowMobileMenu] = useState(false);
	const [showViewOnComputerModal, setShowViewOnComputerModal] = useState(false);
	const [showPrompt, setShowPrompt] = useState(false);
	const [orderedFacilities, setOrderedFacilities] = useState([]);
	const [menuOpen, setMenuOpen] = useState({
		user: false,
		apps: false,
		facilities: false
	});

	// Derived state
	const activeFacility = userData.activeFacility || null;
	const [selectedFacility, setSelectedFacility] = useState(activeFacility);
	const [tempFacility, setTempFacility] = useState(activeFacility);

	// Custom hooks
	const { navigateByRemovingSearchParams } = useConditionalNavigation();
	const isMobile = useMediaQuery(MEDIUM_SCREEN_WIDTH_QUERY);

	const toggleMenu = (menuType) => {
		setMenuOpen((prevState) => ({
			user: menuType === 'user' ? !prevState.user : false,
			apps: menuType === 'apps' ? !prevState.apps : false,
			facilities: menuType === 'facilities' ? !prevState.facilities : false
		}));
	};

	// Utility functions
	const hideFacilitySearch = () => {
		setMenuOpen((prev) => ({ ...prev, facilities: false }));
	};

	// Menu-related functions and data
	const handleNavigation = useCallback(
		(navigationTarget) => () => {
			setMenuOpen({ user: false, apps: false, facilities: false });
			setShowMobileMenu(false);
			history.push(navigationTarget);
		},
		[history, setMenuOpen, setShowMobileMenu]
	);

	const onLogOut = () => setShowLogoutConfirmation(true);

	const menuItems = {
		UserSection: [
			...(!userData?.agencyId
				? [{ label: 'Manage Account', onClick: handleNavigation(PRIVATE_PATH.ACCOUNT), icon: AccountManagementIcon }]
				: []),
			{ label: 'Sign Out', onClick: onLogOut, icon: SignOutIcon }
		],
		AppsSection: [
			{
				label: 'Global Schedule',
				onClick: handleNavigation(PRIVATE_PATH.GLOBAL_OVERVIEW),
				icon: GlobalScheduleIcon,
				hidden: !userData.globalOverviewEnabled
			},
			{
				label: 'Reports',
				onClick: handleNavigation(PRIVATE_PATH.REPORTS),
				icon: ReportsIcon,
				hidden: !userData.reportsEnabled,
				disabled: !Utils.isGlobalManagementCompanyAuthorized(
					PERMISSION.REPORTS,
					'VIEW',
					userData.globalManagementCompanyPermissions
				)
			},
			{ label: 'Facility View', onClick: handleNavigation(PRIVATE_PATH.SHIFTS), icon: FacilityIcon }
		].filter((item) => !item.hidden)
	};

	useOnClickOutside(
		[rightMenuRef, facilitiesSelectorRef, dropdownMenuRef],
		() => setMenuOpen({ user: false, apps: false, facilities: false }),
		'mousedown'
	);

	useOnClickOutside([mobileContainerRef, mobileHeaderRef], () => setShowMobileMenu(false), 'mousedown');

	// Facility-related functions and data
	const { shiftsToReview } = userData;
	const showFacility =
		activeFacility &&
		activeFacility.value &&
		activeFacility.label &&
		userData.facilitiesEnabled &&
		userData.facilitiesEnabled.length;
	const isOnGlobalOverview =
		location.pathname === PRIVATE_PATH.GLOBAL_OVERVIEW || location.pathname.startsWith(PRIVATE_PATH.REPORTS);
	const showFacilitiesDropdown = (showFacility && userData.facilitiesEnabled.length > 1) || isOnGlobalOverview;
	const selectedFacilityLabel = isOnGlobalOverview ? 'Return to Facility ' : selectedFacility?.label;

	const switchFacility = useCallback(
		async (facility) => {
			const hasSwitchedFacility = await dispatch(setCurrentFacility(facility.id));
			if (hasSwitchedFacility) {
				navigateByRemovingSearchParams(['slotId', 'facilityId', 'fromGlobalOverview']);
				setSelectedFacility(facility);
			}
			hideFacilitySearch();
		},
		[navigateByRemovingSearchParams, dispatch]
	);

	const handleFacilityChange = useCallback(
		(facility) => {
			if (isOnGlobalOverview) {
				switchFacility(facility);
				history.push('/private/shifts');
			} else if (selectedFacility?.id !== facility.id) {
				if (!navigateSelector.preventNavigation) {
					switchFacility(facility);
				} else {
					setTempFacility(facility);
					setShowPrompt(true);
				}
				if (showMobileMenu) {
					setShowMobileMenu(false);
				}
			}
			hideFacilitySearch();
		},
		[
			isOnGlobalOverview,
			selectedFacility?.id,
			switchFacility,
			history,
			navigateSelector.preventNavigation,
			showMobileMenu
		]
	);

	const onCancelNavigation = () => {
		setShowPrompt(false);
	};

	const onConfirmNavigation = () => {
		dispatch(setPreventNavigation(false));
		setShowPrompt(false);
		switchFacility(tempFacility);
	};

	// Facility-related effects
	useEffect(() => {
		hideFacilitySearch();
		setSelectedFacility(activeFacility);
	}, [activeFacility]);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const urlFacilityId = urlParams.get('facilityId') || false;

		if (err.type === ERROR_CODES.FACILITY_NOT_PERMITTED && urlFacilityId) {
			navigateByRemovingSearchParams(['slotId', 'facilityId'], true);
			dispatch(setError(false));
		}
	}, [err.type, navigateByRemovingSearchParams, dispatch]);

	useEffect(() => {
		if (!userData.agencyId) {
			const sortByAlpha = (a, b) => a.label.localeCompare(b.label);

			const { pending, standard } = userData.facilitiesEnabled?.reduce(
				(acc, facility) => {
					acc[facility.pendingConfirmations ? 'pending' : 'standard'].push(facility);
					return acc;
				},
				{ pending: [], standard: [] }
			);

			const _orderedFacilities = [...pending.sort(sortByAlpha), ...standard.sort(sortByAlpha)];

			setOrderedFacilities(_orderedFacilities);
		}
	}, [userData.facilitiesEnabled]);

	// Authentication-related functions
	const logout = async (errorType = null) => {
		try {
			if (errorType === '403' || !errorType) {
				await xhr.request('POST', '/logout');
			}
			window.location.href = '/login';
		} catch (e) {
			setShowLogoutConfirmation(false);
			dispatch(showNotification('error', 'Failed to logout. Please refresh the page!'));
		}
	};

	// Mobile-related functions
	const handleNavBarNotOnMobileClick = useCallback(
		(e) => {
			if (showMobileMenu) {
				e.preventDefault();
				setShowViewOnComputerModal(true);
			}
		},
		[showMobileMenu]
	);

	const handleCloseViewOnComputerModal = useCallback(() => {
		setShowViewOnComputerModal(false);
		setShowMobileMenu(false);
		history.push('/private/shifts');
	}, [history]);

	// Permissions
	const isPastShiftsAllowed = Utils.isAuthorizedExact(PERMISSION.PAST_SHIFTS, userData.permissions);
	const isDisputesAllowed = Utils.isAuthorizedExact(PERMISSION.DISPUTES, userData.permissions);
	const hasProfessionalsFeature =
		(Utils.isFeatureEnabled(FEATURE.PROFESSIONALS, userData.features) &&
			Utils.isAuthorizedExact(PERMISSION.PROFESSIONALS, userData.permissions)) ||
		(Utils.isFeatureEnabled(FEATURE.FLEX_PROFESSIONALS, userData.features) &&
			Utils.isAuthorizedExact(PERMISSION.INTERNAL_STAFF, userData.permissions));
	const hasSettingsAccess = Utils.isAuthorizedSection(PERMISSION.SETTINGS, userData.permissions);
	const showPastShiftsCircle =
		isDisputesAllowed &&
		isPastShiftsAllowed &&
		shiftsToReview > 0 &&
		!history.location.pathname.includes(PRIVATE_PATH.PAST_SHIFTS);

	const permissionsToPaths = [
		{
			permission: PERMISSION.PROFESSIONALS,
			path: PRIVATE_PATH.PROFESSIONALS_ON_DEMAND,
			feature: FEATURE.PROFESSIONALS
		},
		{
			permission: PERMISSION.INTERNAL_STAFF,
			path: PRIVATE_PATH.PROFESSIONALS_INTERNAL_STAFF,
			feature: FEATURE.FLEX_PROFESSIONALS
		}
	];
	const professionalsPath =
		permissionsToPaths.find((path) => {
			return (
				Utils.isAuthorizedExact(path.permission, userData.permissions) &&
				Utils.isFeatureEnabled(path.feature, userData.features)
			);
		})?.path || '';

	const CustomToggle = React.forwardRef(({ children, onClick, 'aria-expanded': isOpen }, ref) => (
		<button
			type="button"
			className="toggle"
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		>
			<span className="toggle-content">{children}</span>
			<CaretIcon pointTo={isOpen ? 'up' : 'down'} className="toggle__caret" />
		</button>
	));

	const CustomMenu = memo(
		forwardRef(({ className, 'aria-labelledby': labeledBy }, ref) => {
			const renderMenuItemChildren = useCallback((option, props, index) => {
				return (
					// eslint-disable-next-line jsx-a11y/click-events-have-key-events
					<div
						key={index}
						onClick={() => handleFacilityChange(option)}
						className={classNames('text', { selected: selectedFacility?.id === option.id })}
						role="option"
						aria-selected={selectedFacility?.id === option.id}
						tabIndex={0}
					>
						<span>
							<Highlighter search={props.text}>{option.label}</Highlighter>
						</span>

						{!!option.pendingConfirmations && (
							<div className="badge badge-pill badge-danger pending-confirmation">{option.pendingConfirmations}</div>
						)}
					</div>
				);
			}, []);

			return (
				<div ref={ref} className={className} aria-labelledby={labeledBy}>
					<Typeahead
						paginate={false}
						maxResults={userData?.maxFacilitiesToShow || orderedFacilities?.length}
						id="facility-enabled-search-typeahead"
						ref={typeaheadRef}
						defaultOpen
						placeholder="Search Facility"
						options={orderedFacilities}
						onChange={([selected]) => handleFacilityChange(selected)}
						renderMenuItemChildren={renderMenuItemChildren}
					/>
				</div>
			);
		})
	);

	const mainMenuContent = () => {
		if (userData.agencyId) {
			// external agency view
			return (
				<ExternalAgencyHeader
					key="external-agency-header"
					userData={userData}
					rightMenuRef={rightMenuRef}
					menuOpen={menuOpen}
					menuItems={menuItems}
					toggleMenu={toggleMenu}
					handleNavBarNotOnMobileClick={handleNavBarNotOnMobileClick}
				/>
			);
		}
		if (isMobile) {
			// mobile view
			return (
				<AppHeaderMobile
					key="app-header-mobile"
					// User and facility data
					showFacilitiesDropdown={showFacilitiesDropdown}
					selectedFacilityLabel={selectedFacilityLabel}
					userData={userData}
					activeFacility={activeFacility}
					// Feature flags and access control
					isPastShiftsAllowed={isPastShiftsAllowed}
					hasProfessionalsFeature={hasProfessionalsFeature}
					hasSettingsAccess={hasSettingsAccess}
					// UI state
					showMobileMenu={showMobileMenu}
					setShowMobileMenu={setShowMobileMenu}
					menuOpen={menuOpen}
					setMenuOpen={setMenuOpen}
					showPastShiftsCircle={showPastShiftsCircle}
					// Refs
					typeaheadRef={typeaheadRef}
					dropdownMenuRef={dropdownMenuRef}
					containerRef={mobileContainerRef}
					headerRef={mobileHeaderRef}
					toggleMenuRef={toggleMenuRef}
					facilitiesSelectorRef={facilitiesSelectorRef}
					// Custom components
					CustomToggle={CustomToggle}
					CustomMenu={CustomMenu}
					// Event handlers
					handleNavBarNotOnMobileClick={handleNavBarNotOnMobileClick}
					handleAccountManagementClick={handleNavigation(PRIVATE_PATH.ACCOUNT)}
					onLogOut={onLogOut}
					// Miscellaneous
					shiftsToReview={shiftsToReview}
					professionalsPath={professionalsPath}
					isOnGlobalOverview={isOnGlobalOverview}
				/>
			);
		}

		// desktop
		return (
			<AppHeaderDesktop
				key="app-header-desktop"
				// User and facility data
				showFacilitiesDropdown={showFacilitiesDropdown}
				selectedFacilityLabel={selectedFacilityLabel}
				userData={userData}
				activeFacility={activeFacility}
				// Feature flags and access control
				isPastShiftsAllowed={isPastShiftsAllowed}
				hasProfessionalsFeature={hasProfessionalsFeature}
				hasSettingsAccess={hasSettingsAccess}
				// UI state
				menuOpen={menuOpen}
				setMenuOpen={setMenuOpen}
				showPastShiftsCircle={showPastShiftsCircle}
				// Refs
				facilitiesSelectorRef={facilitiesSelectorRef}
				typeaheadRef={typeaheadRef}
				dropdownMenuRef={dropdownMenuRef}
				rightMenuRef={rightMenuRef}
				// Custom components
				CustomToggle={CustomToggle}
				CustomMenu={CustomMenu}
				// Event handlers
				handleNavBarNotOnMobileClick={handleNavBarNotOnMobileClick}
				toggleMenu={toggleMenu}
				setShowMobileMenu={setShowMobileMenu}
				// Menu related
				menuItems={menuItems}
				// Miscellaneous
				shiftsToReview={shiftsToReview}
				professionalsPath={professionalsPath}
				isOnGlobalOverview={isOnGlobalOverview}
			/>
		);
	};

	return [
		mainMenuContent(),

		showMobileMenu && (
			<div className="sidebar-backdrop" key="show-mobile-menu" onClick={() => setShowMobileMenu(false)} aria-hidden />
		),

		showViewOnComputerModal && <ViewOnComputerModal onCancel={handleCloseViewOnComputerModal} key="view-on-pc" />,

		showLogoutConfirmation && (
			<SignOutConfirmationModal
				key="logout-confirmation"
				className="logout-confirmation"
				title="Sign out?"
				cancelLabel="No, stay signed in"
				confirmLabel="Yes, sign out"
				onConfirm={() => logout()}
				onCancel={() => setShowLogoutConfirmation(false)}
			>
				You will be signed out of this account on this device only.
			</SignOutConfirmationModal>
		),

		(err.type === '403' || err.type === 'Error') && (
			<ConfirmationModal
				key="reload-confirmation"
				title="Session Expired"
				confirmLabel="GO TO LOGIN"
				showCancel={false}
				onConfirm={() => logout(err.type)}
				onCancel={() => logout(err.type)}
			>
				Your session has expired. Please log in again to continue working.
			</ConfirmationModal>
		),
		showPrompt && (
			<RouterPrompt.BasePrompt
				key="facility-switch-prompt"
				showPrompt={showPrompt}
				handleCancel={onCancelNavigation}
				handleOK={onConfirmNavigation}
			/>
		)
	];
};

export default withRouter(memo(AppHeader));
