import api from './api';

const unitsApi = api.injectEndpoints({
	endpoints: (build) => ({
		updateUnit: build.mutation({
			query: ({ resourceRateId, slotId, unitId }) => ({
				url: `/slot/${slotId}/unit-assignment`,
				method: 'PUT',
				body: { resourceRateId, slotId, unitId }
			})
		}),
		updateFlexUnit: build.mutation({
			query: ({ facilityId, slotId, unitId }) => ({
				url: `/flex/slot/${slotId}/unit-assignment`,
				method: 'PUT',
				body: { facilityId, unitId }
			})
		})
	})
});

export const { useUpdateUnitMutation, useUpdateFlexUnitMutation, unwrapError } = unitsApi;

export default unitsApi;
