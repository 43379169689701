const PRIVATE_PATH = {
	ACCOUNT: '/private/account',
	CONTACT_INFORMATION: '/private/account/contact-information',
	NOTIFICATIONS: '/private/account/notifications',
	PAST_SHIFTS: '/private/past-shifts',
	PROFESSIONALS_ON_DEMAND: '/private/professionals/on-demand',
	PROFESSIONALS_INTERNAL_STAFF: '/private/professionals/internal-staff',
	SHIFTS: '/private/shifts',
	SETTINGS: '/private/settings',
	REPORTS: '/private/reports',
	GLOBAL_OVERVIEW: '/private/global-overview',
	PROFESSIONALS_EMPLOYEE_DETAILS: '/private/agency/professionals/employee-details'
};

export default PRIVATE_PATH;
