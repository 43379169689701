import React from 'react';
import Icon from './Icon';

const GlobalScheduleIcon = ({ width = 20, height = 20, color, className }) => {
	return (
		<Icon
			width={width}
			height={height}
			color={color}
			className={className}
			viewBox="0 0 16 17"
			path="M13.3335 2.50008H12.6668V1.16675H11.3335V2.50008H4.66683V1.16675H3.3335V2.50008H2.66683C1.9335 2.50008 1.3335 3.10008 1.3335 3.83341V14.5001C1.3335 15.2334 1.9335 15.8334 2.66683 15.8334H13.3335C14.0668 15.8334 14.6668 15.2334 14.6668 14.5001V3.83341C14.6668 3.10008 14.0668 2.50008 13.3335 2.50008ZM13.3335 14.5001H2.66683V7.16675H13.3335V14.5001ZM2.66683 5.83342H13.3335V3.83341H2.66683V5.83342Z"
		/>
	);
};

export default GlobalScheduleIcon;
