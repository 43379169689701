import React, { memo } from 'react';
import classNames from 'classnames';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { PERMISSION, PRIVATE_PATH } from '../../constants';
import IsAuthorized from '../IsAuthorized/IsAuthorized';
import DailyScheduleIcon from '../icons/DailyScheduleIcon';
import MenuItemIconLabel from './MenuItemIconLabel';
import PastShiftsIcon from '../icons/PastShiftsIcon';
import SettingsIcon from '../icons/SettingsIcon';
import ProfessionalsIcon from '../icons/ProfessionalsIcon';
import NavbarMenu from './NavbarMenu';

import './AppHeaderDesktop.scss';

const AppHeaderDesktop = ({
	// User and facility data
	showFacilitiesDropdown,
	userData,
	activeFacility,
	selectedFacilityLabel,

	// Feature flags and access control
	isPastShiftsAllowed,
	hasProfessionalsFeature,
	hasSettingsAccess,

	// UI state
	menuOpen,
	setMenuOpen,
	showPastShiftsCircle,

	// Refs
	facilitiesSelectorRef,
	typeaheadRef,
	dropdownMenuRef,
	rightMenuRef,

	// Custom components
	CustomToggle,
	CustomMenu,

	// Event handlers
	handleNavBarNotOnMobileClick,
	toggleMenu,
	setShowMobileMenu,

	// Menu related
	menuItems,

	// Miscellaneous
	shiftsToReview,
	professionalsPath,
	isOnGlobalOverview
}) => {
	const location = useLocation();

	return (
		<header className="app-header-desktop">
			<Link to="/" className="logo" />
			<div className="facility-and-menu">
				<div className="facility-label-dropdown" ref={facilitiesSelectorRef}>
					<div className="vertical-separator" />
					{showFacilitiesDropdown ? (
						<Dropdown
							onClick={() => {
								setMenuOpen((prev) => ({ user: false, apps: false, facilities: !prev.facilities }));
								if (!menuOpen.facilities) {
									setTimeout(() => {
										typeaheadRef.current.focus();
										typeaheadRef.current.clear();
									}, 10);
								}
							}}
							show={menuOpen.facilities}
						>
							<Dropdown.Toggle id="facility-enabled-search-dropdown" as={CustomToggle}>
								{selectedFacilityLabel}
							</Dropdown.Toggle>
							<Dropdown.Menu ref={dropdownMenuRef} as={CustomMenu} />
						</Dropdown>
					) : (
						<div>{activeFacility?.label}</div>
					)}
					<div className="flex-one" />
				</div>

				{!isOnGlobalOverview ? (
					<div className={classNames('right-menu ', { 'flex-one': isPastShiftsAllowed })}>
						{!userData.agencyId && (
							<NavLink
								to={PRIVATE_PATH.SHIFTS}
								className="shift-menu-item"
								onClick={() => setShowMobileMenu(false)}
								activeClassName="active"
							>
								<MenuItemIconLabel icon={<DailyScheduleIcon />} label="Daily&nbsp;Schedule" />
							</NavLink>
						)}

						{isPastShiftsAllowed && (
							<NavLink
								to={PRIVATE_PATH.PAST_SHIFTS}
								className="shift-menu-item"
								onClick={handleNavBarNotOnMobileClick}
								activeClassName="active"
							>
								<MenuItemIconLabel
									icon={<PastShiftsIcon />}
									label="Past&nbsp;shifts"
									showRedCircle={showPastShiftsCircle}
									redNumber={shiftsToReview}
								/>
							</NavLink>
						)}

						{hasProfessionalsFeature && (
							<IsAuthorized
								exactPermission={[PERMISSION.PROFESSIONALS, PERMISSION.INTERNAL_STAFF]}
								readOnlyRender={
									<OverlayTrigger
										trigger={['hover', 'focus']}
										key="professionals-tooltip"
										placement="bottom"
										overlay={
											<Tooltip className="professionals-menu-item-tooltip" placement="bottom" show>
												You do not have permission to perform this action. Please contact your Customer Success Rep for
												details.
											</Tooltip>
										}
									>
										<MenuItemIconLabel icon={<ProfessionalsIcon />} label="Professionals" />
									</OverlayTrigger>
								}
							>
								<NavLink
									to={professionalsPath}
									className="shift-menu-item"
									onClick={() => setShowMobileMenu(false)}
									activeClassName="active"
									isActive={() => {
										return [PRIVATE_PATH.PROFESSIONALS_ON_DEMAND, PRIVATE_PATH.PROFESSIONALS_INTERNAL_STAFF].includes(
											location.pathname
										);
									}}
								>
									<MenuItemIconLabel icon={<ProfessionalsIcon />} label="Professionals" />
								</NavLink>
							</IsAuthorized>
						)}
						{hasSettingsAccess && (
							<NavLink
								to={{ pathname: PRIVATE_PATH.SETTINGS }}
								className="shift-menu-item"
								onClick={handleNavBarNotOnMobileClick}
								activeClassName="active"
							>
								<MenuItemIconLabel icon={<SettingsIcon />} label="Settings" />
							</NavLink>
						)}
					</div>
				) : (
					<div className="flex-one" />
				)}

				<NavbarMenu
					userData={userData}
					rightMenuRef={rightMenuRef}
					menuOpen={menuOpen}
					menuItems={menuItems}
					toggleMenu={toggleMenu}
				/>
			</div>
		</header>
	);
};

export default memo(AppHeaderDesktop);
