import React from 'react';
import close from '../../assets/images/close-black.svg';
import searchIcon from '../../assets/images/search-icon.svg';

const SearchSection = ({ search = '', setSearch = () => {}, noResults = false }) => (
	<>
		<input type="text" placeholder="Search all" value={search} onChange={(e) => setSearch(e.target.value)} />
		{search.length ? (
			<img src={close} alt="delete" className="delete-icon" aria-hidden onClick={() => setSearch('')} />
		) : (
			<img src={searchIcon} alt="search" className="search-icon" />
		)}
		{noResults && (
			<div className="no-results">
				There are no results found for &quot;{search}&quot;. Please check your spelling and try again.
			</div>
		)}
	</>
);

export default SearchSection;
