/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, Switch, useParams } from 'react-router-dom';
import { FEATURE, PERMISSION, PRIVATE_PATH } from '../constants';
import { shouldShowLoadingSpinner } from '../redux/pastShifts';
import { selectedFacility } from '../redux/user';
import ProtectedRoute from './ProtectedRoute';
import ProtectedSectionRoute from './ProtectedSectionRoute';
import ProtectedManagementCompanySectionRoute from './ProtectedManagementCompanySectionRoute';
import LoadingSpinner from '../components/spinner/LoadingSpinner';
import ShiftsScreen from '../features/shifts/ShiftsScreen';
import PastShiftsScreen from '../features/shifts/PastShiftsScreen';
import ProfessionalsScreen from '../features/professionals/ProfessionalsScreen';
import AccountManagementScreen from '../features/account/AccountManagementScreen';
import SettingsScreen from '../features/settings/SettingsScreen';
import GlobalOverviewScreen from '../features/global-overview/GlobalOverviewScreen';
import ReportScreen from '../features/reports/ReportScreen';
import ReportsScreen from '../features/reports/ReportsScreen';

const ReportsComponent = () => {
	const { reportCode } = useParams();
	return reportCode ? <ReportScreen /> : <ReportsScreen />;
};

const FacilityRoutes = ({ userData }) => {
	const activeFacility = useSelector(selectedFacility) || null;
	const showLoadingSpinner = useSelector(shouldShowLoadingSpinner);
	return (
		<Switch>
			<Route path={PRIVATE_PATH.ACCOUNT}>
				<AccountManagementScreen />
			</Route>
			<Route exact path={PRIVATE_PATH.SHIFTS}>
				<ShiftsScreen />
			</Route>
			<ProtectedManagementCompanySectionRoute
				exact
				path={`${PRIVATE_PATH.REPORTS}/:reportCode?`}
				key={`reports-${activeFacility}`}
				component={ReportsComponent}
				pathPermission={PERMISSION.REPORTS}
				operation="VIEW"
				globalManagementCompanyPermissions={userData.globalManagementCompanyPermissions}
			/>
			<ProtectedRoute
				exact
				path={PRIVATE_PATH.PAST_SHIFTS}
				key={`past-shifts-${activeFacility}`}
				component={PastShiftsScreen}
				pathPermission={PERMISSION.PAST_SHIFTS}
				userPermissions={userData.permissions}
			/>
			<ProtectedRoute
				exact
				path={PRIVATE_PATH.PROFESSIONALS_ON_DEMAND}
				key={`professionals-on-demand-${activeFacility}`}
				component={ProfessionalsScreen}
				pathPermission={PERMISSION.PROFESSIONALS}
				userPermissions={userData.permissions}
				featureCode={FEATURE.PROFESSIONALS}
				features={userData.features}
			/>
			<ProtectedRoute
				exact
				path={PRIVATE_PATH.PROFESSIONALS_INTERNAL_STAFF}
				key={`professionals-internal-staff-${activeFacility}`}
				component={ProfessionalsScreen}
				pathPermission={PERMISSION.INTERNAL_STAFF}
				userPermissions={userData.permissions}
				featureCode={FEATURE.FLEX_PROFESSIONALS}
				features={userData.features}
			/>
			<ProtectedSectionRoute
				exact
				path={PRIVATE_PATH.SETTINGS}
				key={`settings-${activeFacility}`}
				component={SettingsScreen}
				pathPermission={PERMISSION.SETTINGS}
				userPermissions={userData.permissions}
			/>
			<Route
				path={PRIVATE_PATH.GLOBAL_OVERVIEW}
				exact
				render={(props) =>
					userData?.globalOverviewEnabled ? <GlobalOverviewScreen {...props} /> : <Redirect to="/404" />
				}
			/>
			<Route path="/" render={() => <Redirect to="/404" />} />
			{showLoadingSpinner && <LoadingSpinner />}
		</Switch>
	);
};

export default FacilityRoutes;
