import api from './api';

const apiWithTags = api.enhanceEndpoints({
	addTagTypes: ['Reports', 'Report', 'ReportData']
});

const reportsApi = apiWithTags.injectEndpoints({
	tagTypes: ['Reports', 'Report', 'ReportData'],
	endpoints: (build) => ({
		getAllReportsList: build.query({
			query: () => '/reports',
			providesTags: ['Reports']
		}),
		getReportConfig: build.query({
			query: (reportCode) => `/reports/${reportCode}/ui-config`,
			providesTags: ['Report']
		}),
		getReportData: build.query({
			query: ({ reportCode, dateRangeParam = {} }) => {
				const { uniqueId, values } = dateRangeParam;
				const urlParams = new URLSearchParams();
				if (uniqueId && values && Array.isArray(values)) {
					values.forEach((date) => urlParams.append(uniqueId, date));
				}
				return {
					url: `reports/${reportCode}/data?${urlParams.toString()}`
				};
			},
			providesTags: ['ReportData']
		})
	})
});

export const { useGetAllReportsListQuery, useGetReportConfigQuery, useGetReportDataQuery } = reportsApi;

export default reportsApi;
