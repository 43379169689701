import React, { useState, useCallback } from 'react';
import { withRouter } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { setShowPrompt } from '../../redux/settings';
import { user } from '../../redux/user';
import SignatureRule from './signature-rule/SignatureRule';
import SettingsSideBar from './SettingsSideBar';
import DnrPage from './dnr/DnrPage';
import FacilityInfoPage from './facility-info/FacilityInfo';
import LoadingSpinner from '../../components/spinner/LoadingSpinner';
import WithOnlyOnDesktop from '../../components/onlyOnDesktop/withOnlyOnDesktop';

import './SettingsScreen.scss';

const SETTINGS_TABS = ['Signature Approval', 'Do Not Return', 'Facility Information'];

const PERMISSIONS_MAP = {
	[SETTINGS_TABS[0]]: 'PORTAL.SETTINGS.SIGNATURE_RULE',
	[SETTINGS_TABS[1]]: 'PORTAL.SETTINGS.DNR',
	[SETTINGS_TABS[2]]: 'PORTAL.SETTINGS.FACILITY_INFO'
};

const SettingsScreen = () => {
	const dispatch = useDispatch();
	const userData = useSelector(user) || {};

	const getAvailableTabs = useCallback(() => {
		return SETTINGS_TABS.filter((tab) => userData.permissions.includes(PERMISSIONS_MAP[tab]));
	}, [userData.permissions]);

	const [activeTab, setActiveTab] = useState(getAvailableTabs()[0]); // Set first available tab as active
	const [shouldChangeActiveTab, setShouldChangeActiveTab] = useState(true);
	const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

	const activeFacility = userData.activeFacility || null;

	return (
		<div className="sidebar-wrapper__container settings-container ">
			<div className="sidebar-wrapper__page settings-page">
				<SettingsSideBar
					availableTabs={getAvailableTabs()}
					activeTab={activeTab}
					setActiveTab={shouldChangeActiveTab ? setActiveTab : () => dispatch(setShowPrompt(true))}
				/>
				<div
					className={classNames('sidebar-wrapper__screen settings-screen ', {
						'full-width': activeTab === 'Do Not Return'
					})}
				>
					{showLoadingSpinner && (
						<div className="spinner-wrapper" key="spinner-wrapper-key">
							<LoadingSpinner key="loading-spinner" />
						</div>
					)}
					<WithOnlyOnDesktop>
						<h2>{activeTab === 'Do Not Return' ? 'File a Do Not Return' : activeTab}</h2>
						{activeTab === 'Signature Approval' && (
							<SignatureRule activeFacility={activeFacility} setShowLoadingSpinner={setShowLoadingSpinner} />
						)}
						{activeTab === 'Do Not Return' && (
							<DnrPage
								userData={userData}
								activeFacility={activeFacility}
								setShowLoadingSpinner={setShowLoadingSpinner}
							/>
						)}
						{activeTab === 'Facility Information' && (
							<FacilityInfoPage
								userData={userData}
								activeFacility={activeFacility}
								setShouldChangeActiveTab={setShouldChangeActiveTab}
								setShowLoadingSpinner={setShowLoadingSpinner}
							/>
						)}
					</WithOnlyOnDesktop>
				</div>
			</div>
		</div>
	);
};

export default withRouter(SettingsScreen);
