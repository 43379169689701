/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useRef, useState } from 'react';
import moment from 'moment';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import arrowRightIcon from '../../assets/images/arrow-right-purple.svg';
import calendarIcon from '../../assets/images/calendar-purple.svg';
import closeIcon from '../../assets/images/close.svg';
import infoIcon from '../../assets/images/icons8-info.svg';

import './DateRangeSelector.scss';

const DateRangeSelector = ({ rangeStart, rangeEnd, setRangeStart, setRangeEnd, minDate, maxDate, proTip }) => {
	const overlayPanelRef = useRef(null);
	const [localRangeStart, setLocalRangeStart] = useState(rangeStart);
	const [localRangeEnd, setLocalRangeEnd] = useState(rangeEnd);

	const handleOverlayToggle = useCallback((e) => {
		overlayPanelRef?.current?.toggle(e);
	}, []);

	const hideOverlay = useCallback(() => {
		setLocalRangeStart(rangeStart);
		setLocalRangeEnd(rangeEnd);
		overlayPanelRef.current.hide();
	}, [rangeStart, rangeStart]);

	const dateTemplate = useCallback(
		(date) => {
			const currentDate = new Date(date.year, date.month, date.day);
			let _className = '';

			if (localRangeStart) {
				if (localRangeEnd && localRangeStart !== localRangeEnd) {
					if (currentDate.toDateString() === localRangeStart.toDateString()) {
						_className = 'from';
					} else if (currentDate.toDateString() === localRangeEnd.toDateString()) {
						_className = 'to';
					} else if (currentDate > localRangeStart && currentDate < localRangeEnd) {
						_className = 'between';
					}
				} else if (currentDate.toDateString() === localRangeStart.toDateString()) {
					_className = 'from-only';
				}
			}
			return <span className={_className}>{date.day}</span>;
		},
		[localRangeStart, localRangeEnd]
	);

	const handleDateChange = (e) => {
		const [from, to] = e.value;
		if (from < localRangeStart) {
			setLocalRangeEnd(null);
		} else {
			setLocalRangeEnd(to);
		}
		setLocalRangeStart(from);
	};

	const handleApply = useCallback(() => {
		setRangeStart(localRangeStart);
		setRangeEnd(localRangeEnd || localRangeStart);
		if (!localRangeEnd) {
			setLocalRangeEnd(localRangeStart);
		}
		overlayPanelRef.current.hide();
	}, [setRangeStart, setRangeEnd, localRangeStart, localRangeEnd]);

	return (
		<div className="date-range-selector">
			<div className="range-container" onClick={handleOverlayToggle}>
				<img src={calendarIcon} alt="Calendar" />
				<div className="range-date">{localRangeStart ? moment(localRangeStart).format('MMM D, YYYY') : ''}</div>
				<img src={arrowRightIcon} alt="To" />
				<div className="range-date">{localRangeEnd ? moment(localRangeEnd).format('MMM D, YYYY') : ''}</div>
			</div>
			<OverlayPanel ref={overlayPanelRef} className="date-range-selector-overlay" onHide={hideOverlay}>
				<div className="header">
					<div className="title">Select date or date range</div>
					<img src={closeIcon} alt="close" onClick={hideOverlay} />
				</div>
				<Calendar
					value={[localRangeStart, localRangeEnd]}
					onChange={handleDateChange}
					selectionMode="range"
					showOtherMonths={false}
					numberOfMonths={2}
					minDate={minDate}
					maxDate={maxDate}
					readOnlyInput
					inline
					dateTemplate={dateTemplate}
				/>
				{proTip && (
					<div className="pro-tip-banner">
						<img src={infoIcon} alt="info" />
						<div>
							<b>Pro tip:</b> {proTip}
						</div>
					</div>
				)}
				<div className={classNames('date-range-actions', { 'date-range-actions-top': proTip })}>
					<Button label="Apply" size="large" onClick={handleApply} />
				</div>
			</OverlayPanel>
		</div>
	);
};

export default DateRangeSelector;
