import './AgencyProfessionalsEmployeeDetails.css';
import React, { useState } from 'react';
import { CaretIcon } from '../../components/icons';

const AgencyProfessionalsSubtype = ({ subtypeTitle, subtypes, type }) => {
	const [open, setOpen] = useState(false);

	const displaySubtype = () => {
		const subtypesCount = subtypes && subtypes.length;

		if (subtypesCount > 1) {
			return (
				<button type="button" className="toggle-section" onClick={() => setOpen(!open)} aria-label="Toggle sidebar">
					<CaretIcon className="toggle-icon" pointTo={open ? 'up' : 'down'} />
				</button>
			);
		}

		return !subtypesCount ? <h4> - </h4> : <h4>{subtypes[0][type]}</h4>;
	};

	return (
		<>
			<div className="home-details home-details__subtype">
				<h3>
					{subtypeTitle} {subtypes && subtypes.length > 1 ? `(${subtypes.length})` : ''}
				</h3>
				{displaySubtype()}
			</div>
			{open && (
				<ul className="home-details home-details__subtype home-details__list">
					{subtypes.map((subtype) => (
						<li key={subtype.id}>{subtype[type]}</li>
					))}
				</ul>
			)}
		</>
	);
};

export default AgencyProfessionalsSubtype;
