import React from 'react';
import './SelectedProfessional.css';
import ProfessionalRow from './ProfessionalRow';

const SelectedProfessional = ({ selectedSectionTitle, selectedCaregivers, removeSelectedProfessional }) => {
	return (
		<section className="selected-professionals-container">
			<div className="selected-professionals-header">
				<h4>
					<span>{selectedSectionTitle}</span>
				</h4>
			</div>
			<div className="selected-professionals-body">
				{selectedCaregivers.length > 0 ? (
					<ul className="list-selected-caregivers">
						{selectedCaregivers.map((caregiver) => {
							return (
								<div key={`selected-caregiver-${caregiver.caregiver_id}`} className="selected-caregiver-container">
									<li>
										<ProfessionalRow professional={caregiver} />
									</li>
									<button type="button" onClick={(evt) => removeSelectedProfessional(evt, caregiver)}>
										x
									</button>
								</div>
							);
						})}
					</ul>
				) : null}
			</div>
		</section>
	);
};

export default SelectedProfessional;
