import React, { memo, useState, useEffect, useMemo, useCallback } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';
import Sticky from 'react-sticky-el';

import xhr from '../../utils/xhr';
import Utils from '../../utils/utils';
import { showNotification } from '../../redux/notification';
import PERMISSION from '../../constants/Permissions';

import orientationIcon from '../../assets/images/compass.svg';

import Button from '../../components/button/Button';
import WarningIcon from '../../components/icons/WarningIcon';
import BaseModal from '../../components/modals/BaseModal/BaseModal';
import BonusModal from '../../components/modals/BonusModal';
import GenericPermissionsModal from '../../components/modals/GenericPermissionsModal';
import EditWageModal from '../../components/modals/EditWageModal';
import ConfirmationModal from '../../components/modals/ConfirmationModal';
import CancelShiftModal from '../../components/modals/CancelShiftModal';
import VendorWarningModal from '../../components/modals/VendorWarningModal';
import InviteCaregiverModal from '../../components/modals/CaregiverShiftActionModals/InviteCaregiverModal';

import AddSlots from './AddSlots';
import UnitAssignment from './UnitAssignment/UnitAssignment';
import GuaranteeSlotsModal from './GuaranteeShift/GuaranteeSlotsModal';
import { SHIFT_STATUS } from '../../constants';
import GuaranteedCheckbox from './GuaranteeShift/GuaranteedCheckbox';
import ShiftSlot from './ShiftSlot/ShiftSlot';
import AssignCaregiverModal from '../../components/modals/CaregiverShiftActionModals/AssignCaregiver/AssignCaregiverModal';
import CancelFlexShiftModal from '../../components/modals/CancelFlexShiftModal';
import SingleActionModal from '../../components/modals/SingleActionModal';

const Shift = (props) => {
	const dispatch = useDispatch();

	const { units = [], data: shift, user, isFilterByCaregiver, hideLabel } = props;

	const [showConfirmConfirmation, setShowConfirmConfirmation] = useState(false);
	const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
	const [showGuaranteedModal, setShowGuaranteedModal] = useState(false);
	const [showAddSlotVendorWarningModal, setShowAddSlotVendorWarningModal] = useState(false);
	const [showConfirmShiftVendorWarningModal, setShowConfirmShiftVendorWarningModal] = useState(false);
	const [showCancelShiftWithCaregiverVendorWarningModal, setShowCancelShiftWithCaregiverVendorWarningModal] = useState(
		false
	);
	const [showInviteCaregiverModal, setShowInviteCaregiverModal] = useState(false);
	const [isGuaranteed, setIsGuaranteed] = useState(false);
	const [openSlotsCount, setOpenSlotsCount] = useState(0);
	const [showSlotsModal, setShowSlotsModal] = useState(false);
	const [showFlexSlotsModal, setShowFlexSlotsModal] = useState(false);
	const [shiftSelectedToAddSlots, setShiftSelectedToAddSlots] = useState({});
	const [flexShiftSelectedToAddSlots, setFlexShiftSelectedToAddSlots] = useState({});
	const [numberOfSlots, setNumberOfSlots] = useState(1);
	const [showBonusPermissionsModal, setShowBonusPermissionsModal] = useState(false);
	const [showEditWagePermissionModal, setShowEditWagePermissionModal] = useState(false);
	const [showEditWageModal, setShowEditWageModal] = useState(false);
	const [showBonusModal, setShowBonusModal] = useState(false);
	const [showCancelModal, setShowCancelModal] = useState(false);
	const [showCancelFlex, setShowCancelFlex] = useState(false);
	const [selectedSlotToCancel, setSelectedSlotToCancel] = useState({});
	const [showConfirmModalContractCancellation, setShowConfirmModalContractCancellation] = useState(false);
	const [showAssignCaregiverModal, setShowAssignCaregiverModal] = useState(false);
	const [cancelModalErrorMessage, setCancelModalErrorMessage] = useState(null);
	const [shiftId, setShiftId] = useState(shift.id);
	const [isRecurring, setIsRecurring] = useState(shift.isRecurring);
	const [showUnitAssignmentModal, setShowUnitAssignmentModal] = useState(false);
	const [slotsToGuarantee, setSlotsToGuarantee] = useState([]);
	const [unitAssignmentSlot, setUnitAssignmentSlot] = useState({});
	const [selectedSlotId, setSelectedSlotId] = useState(null);
	const [showRefreshModal, setShowRefreshModal] = useState(false);
	const slotRefs = {};

	const hasToggleGuaranteePermission = useMemo(
		() => Utils.isAuthorizedExact(PERMISSION.GUARANTEE, props.user.permissions),
		[props.user.permissions]
	);

	const getEndUserMessage = (e) => {
		const res = e.response;
		const validationError = res && res.data && res.data.validationError;
		if (validationError) {
			if (res && res.data && res.data.validationError) {
				const errorMessage = res.data.errors[0].message;
				if (errorMessage) {
					return errorMessage;
				}
			}
		}
		return null;
	};

	useEffect(() => {
		const openSlots = shift.slots.filter((slot) => !slot.caregiver_id && !slot.is_cancelled);
		const allOpenSlotsGuaranteed = openSlots.length > 0 && openSlots.every((slot) => slot.is_guaranteed);
		const _isGuaranteed = openSlots.length > 0 && allOpenSlotsGuaranteed;
		setIsGuaranteed(_isGuaranteed);
		setOpenSlotsCount(openSlots.length);
	}, [shift.slots]);

	const confirm = async () => {
		setShowConfirmShiftVendorWarningModal(false);
		props.setIsLoading(true);
		try {
			if (shift.is_flex) {
				await xhr.request('PUT', `/flex/slot/${selectedSlotId}/confirm`, {});
			} else {
				await xhr.request('PUT', `/slot/${selectedSlotId}/confirm`, {});
			}
			props.updateShiftStatus(shift.shift_date, selectedSlotId, 'Confirmed');
			setSelectedSlotId(null);
		} catch (e) {
			if (e.response.status !== 403) {
				dispatch(showNotification('error', getEndUserMessage(e) || 'Failed to confirm shift. Please try again!'));
				props.fetchData();
			}
			props.setIsLoading(false);
		} finally {
			props.fetchData();
		}
	};

	const updateIsGuaranteedSlots = async (slots, newGuaranteedStatus) => {
		props.setIsLoading(true);
		const slotValues = slots.reduce((acc, slot) => {
			acc[slot.slot_id] = newGuaranteedStatus;
			return acc;
		}, {});

		try {
			await xhr.request('PUT', `/shifts/${shiftId}/slots/guaranteed`, {
				isRecurring: shift.isRecurring,
				slots: slotValues,
				shiftDate: moment.utc(shift.raw_start_time).format('YYYY-MM-DD')
			});
			props.fetchData();
			setShowGuaranteedModal(false);
			dispatch(showNotification('success', `Guarantee ${newGuaranteedStatus ? 'set' : 'removed'} successfully.`));
		} catch (e) {
			const errorMessage = getEndUserMessage(e) || 'Failed to update shift. Please try again!';
			dispatch(showNotification('error', errorMessage));
			if (e.response.status === 403) {
				setShowGuaranteedModal(false);
			}
			props.setIsLoading(false);
		}
	};

	const onConfirm = (slotId) => {
		setShowConfirmConfirmation(true);
		setSelectedSlotId(slotId);
	};

	const onCancel = (_selectedSlotToCancel) => {
		setShowConfirmModalContractCancellation(false);

		if (_selectedSlotToCancel && _selectedSlotToCancel.caregiver_id) {
			setShowCancelShiftWithCaregiverVendorWarningModal(true);
		} else {
			setShowCancelConfirmation(true);
		}
	};

	const withdrawContractCancellation = () => {
		setShowConfirmModalContractCancellation(false);
		setSelectedSlotToCancel({});
	};

	const onPrepareCancel = (slot) => {
		setSelectedSlotToCancel(slot);
		if (slot.is_flex) {
			setShowCancelFlex(true);
		} else if (slot.contract_id) {
			// for slot with contract we need to show additional modal
			setShowConfirmModalContractCancellation(true);
		} else {
			onCancel(slot);
		}
	};

	const onGuaranteedCheckboxChange = (slots = []) => {
		setSlotsToGuarantee(slots.filter((slot) => !slot.caregiver_id && !slot.is_cancelled));
		setShowGuaranteedModal(true);
	};

	const handleEditBonusModalButton = useCallback(() => {
		if (!Utils.isAuthorizedExact(PERMISSION.BONUS, props.user.permissions)) {
			setShowBonusPermissionsModal(true);
		} else {
			setShowBonusModal(['EDIT', shift]);
		}
	}, [props.user.permissions, setShowBonusPermissionsModal, setShowBonusModal, shift]);

	const handleAddBonusModalButton = useCallback(() => {
		if (!Utils.isAuthorizedExact(PERMISSION.BONUS, props.user.permissions)) {
			setShowBonusPermissionsModal(true);
		} else {
			setShowBonusModal(['ADD', shift]);
		}
	}, [props.user.permissions, setShowBonusPermissionsModal, setShowBonusModal, shift]);

	const handleEditWageModalButton = useCallback(() => {
		if (!Utils.isAuthorizedExact(PERMISSION.EDIT_WAGE, props.user.permissions)) {
			setShowEditWagePermissionModal(true);
		} else {
			setShowEditWageModal(true);
		}
	}, [props.user.permissions, setShowEditWagePermissionModal, setShowEditWageModal]);

	const updateFacilityPayableBonus = async (bonusAmount, eventCode) => {
		props.setIsLoading(true);
		try {
			await xhr.request('PUT', `/shift/${shift.id}/facility-bonus`, {
				shiftId: shift.id,
				eventCode,
				facilityPayableBonus: bonusAmount,
				isRecurring: shift.isRecurring,
				shiftDate: moment.utc(shift.raw_start_time).format('YYYY-MM-DD')
			});
			props.fetchData();
			dispatch(showNotification('success', 'Bonus updated successfully'));
		} catch (e) {
			if (e.response.status !== 403) {
				let userMessage = getEndUserMessage(e);
				if (userMessage && userMessage.indexOf('is not empty') > 0) {
					userMessage = 'Data has changed. Please refresh the page.';
				}
				dispatch(showNotification('error', userMessage || 'Failed to edit bonus. Please try again!'));
			}
		} finally {
			props.setIsLoading(false);
			setShowBonusModal(false);
		}
	};

	const showModalToAddSlots = (shiftForModal) => {
		setShowAddSlotVendorWarningModal(false);
		if (shiftForModal.is_flex) {
			setFlexShiftSelectedToAddSlots({ ...shiftForModal });
			setShowFlexSlotsModal(true);
		} else {
			setShiftSelectedToAddSlots({ ...shiftForModal, shift_guaranteed: isGuaranteed });
			setShowSlotsModal(true);
		}
	};

	const hideSlotsModal = () => {
		setShowSlotsModal(false);
		setShiftSelectedToAddSlots({});
	};

	const hideFlexSlotsModal = () => {
		setShowFlexSlotsModal(false);
		setShiftSelectedToAddSlots({});
	};

	const handleInviteButton = () => {
		setShowInviteCaregiverModal(true);
	};

	const handleAddFlexWorkerButton = () => {
		setShowAssignCaregiverModal(true);
	};

	const handleCloseInviteCaregiverModal = () => {
		setShowInviteCaregiverModal(false);
	};

	const handleCloseAssignCaregiverModal = () => {
		setShowAssignCaregiverModal(false);
	};

	const handleOnAssign = (notificationType, message) => {
		dispatch(showNotification(notificationType, message));
		setShowAssignCaregiverModal(false);
		props.fetchData();
	};

	/**
	 * @param {String} notificationType
	 * @param {String} message
	 */
	const handleSendInvitation = (notificationType, message) => {
		dispatch(showNotification(notificationType, message));
		setShowInviteCaregiverModal(false);
	};

	const requestNewSlots = async () => {
		setShowSlotsModal(false);
		props.setIsLoading(true);
		try {
			await xhr.request('POST', `/slots`, {
				shiftId: shiftSelectedToAddSlots.id,
				numberOfSlots,
				isRecurring: shiftSelectedToAddSlots.isRecurring,
				shiftDate: moment.utc(shiftSelectedToAddSlots.raw_start_time).format('YYYY-MM-DD')
			});
			props.fetchData();
			dispatch(showNotification('success', 'Created successfully'));
		} catch (e) {
			if (e.response.status !== 403) {
				let userMessage = getEndUserMessage(e);
				if (userMessage && userMessage.indexOf('is not empty') > 0) {
					userMessage = 'Data has changed. Please refresh the page.';
				}
				dispatch(showNotification('error', userMessage || 'Failed to add shifts. Please try again!'));
			}
			props.setIsLoading(false);
		} finally {
			setShiftSelectedToAddSlots({});
			setNumberOfSlots(1);
		}
	};

	const requestNewFlexSlots = async () => {
		setShowFlexSlotsModal(false);
		props.setIsLoading(true);
		try {
			await xhr.request('POST', `/flex/slots`, {
				flexShiftId: flexShiftSelectedToAddSlots.id,
				numberOfSlots,
				shiftDate: moment.utc(flexShiftSelectedToAddSlots.raw_start_time).format('YYYY-MM-DD')
			});
			dispatch(showNotification('success', 'Created successfully'));
			props.fetchData();
			if (props.isSingleSlotView) {
				props.setIsLoading(false);
			}
		} catch (e) {
			if (e.response.status !== 403) {
				let userMessage = getEndUserMessage(e);
				if (userMessage && userMessage.indexOf('is not empty') > 0) {
					userMessage = 'Data has changed. Please refresh the page.';
				}
				dispatch(showNotification('error', userMessage || 'Failed to add shifts. Please try again!'));
			}
			props.setIsLoading(false);
		} finally {
			setShiftSelectedToAddSlots({});
			setNumberOfSlots(1);
		}
	};

	const updateSlotArrivalStatus = async (slotId, arrivalStatus) => {
		props.setIsLoading(true);
		try {
			await xhr.request('PUT', `/slot/${slotId}/arrival-status`, {
				arrivalStatus
			});
			props.fetchData();
			dispatch(showNotification('success', 'Arrival status updated successfully'));
		} catch (e) {
			if (e.response.status !== 403) {
				let userMessage = getEndUserMessage(e);
				if (userMessage && userMessage.indexOf('is not empty') > 0) {
					userMessage = 'Data has changed. Please refresh the page.';
				}
				dispatch(showNotification('error', userMessage || 'Failed to update arrival status shifts. Please try again!'));
			}
			props.setIsLoading(false);
		}
	};

	const getRowColor = (resourceType) => {
		let className;
		const parsedResourceType = resourceType.split('-')[0];

		switch (parsedResourceType) {
			case 'RNS':
				className = 'green';
				break;
			case 'LPN':
				className = 'purple';
				break;
			case 'RN':
				className = 'yellow';
				break;
			default:
				className = 'blue';
		}

		return className;
	};

	const computeFilledSlots = (slots) => {
		let filledSlots = 0;
		let totalSlots = 0;
		slots.forEach((slot) => {
			if ((slot.caregiver_id && slot.is_guaranteed && slot.is_cancelled) || slot.is_cancelled) {
				// A slot that has been already picked up, guaranteed and cancelled by facility should not be taken into account neither on the filled nor on the totalSlots.
				return;
			}
			if (
				(slot.caregiver_id && !slot.is_cancelled) ||
				(slot.integrated_shift_expired && slot.slot_status !== SHIFT_STATUS.CONFIRMED)
			) {
				filledSlots += 1;
			}
			totalSlots += 1;
		});
		return [filledSlots, totalSlots];
	};

	const getShiftRatio = (shiftForRatio) => {
		const [filledSlots, totalSlots] = computeFilledSlots(shiftForRatio.slots);
		return `${filledSlots} of ${totalSlots}`;
	};

	const hasSlotsAvailable = (shiftForCheck) => {
		const [filledSlots, totalSlots] = computeFilledSlots(shiftForCheck.slots);
		return totalSlots - filledSlots > 0;
	};

	const preventGuaranteeOrBonusUpdates = !!(shift.slots.length === 1 && shift.slots[0].caregiver_id);

	const handleCancelShiftModalButton = async (
		cancelationReason,
		needsReplacement,
		isLateCancelation,
		acknowledgmentAccepted,
		agencyName,
		isNonClaimedSlot = false
	) => {
		props.setIsLoading(true);

		const updateSlotId = shift.isRecurring ? shift.id : selectedSlotToCancel.slot_id;
		// The id passed to the cancel function for recurring shifts should be the shift.id instead of the slot.id

		let slotRelated;

		if (!shift.isRecurring) {
			slotRelated = shift.slots ? shift.slots.find((ss) => Number(ss.slot_id) === Number(updateSlotId)) : null;
			if (!slotRelated) {
				// This should not happen. Covering against bad or missing reference for slot id.
				dispatch(showNotification('error', 'Failed to cancel shift. Please try again!'));
				props.setIsLoading(false);
				return;
			}
		}

		try {
			if (shift.is_flex) {
				await xhr.request('PUT', `/flex/slot/${updateSlotId}/cancel`, {
					cancelationReason,
					needsReplacement,
					isLateCancelation,
					extraInfo: null
				});
			} else {
				await xhr.request('PUT', `/slot/${updateSlotId}/cancel`, {
					isGuaranteed: !!selectedSlotToCancel.is_guaranteed,
					isRecurring: !!shift.isRecurring,
					shiftDate: moment.utc(shift.raw_start_time).format('YYYY-MM-DD'),
					cancelationReason,
					needsReplacement,
					isLateCancelation,
					guaranteeAcknowledge: acknowledgmentAccepted,
					lateCancelationAcknowledge: acknowledgmentAccepted,
					extraInfo: agencyName ? { reason: agencyName } : null,
					facilityBillableCancellationHours: user.activeFacility.facility_billable_cancellation_hours,
					facilityWindowCancellationHours: user.activeFacility.facility_window_cancellation_hours
				});
			}

			dispatch(showNotification('success', 'Shift cancelled successfully.'));
			if (props.fromGlobalOverview) {
				props.history.goBack();
			} else {
				props.fetchData();
				if (isNonClaimedSlot) {
					// non-claimed slot needs to call updateShiftStatus and update the slot status to canceled
					props.updateShiftStatus(shift.shift_date, updateSlotId, 'Canceled');
					setShowCancelConfirmation(false);
				} else {
					setShowCancelModal(false);
					setSelectedSlotToCancel({});
					setShowCancelFlex(false);
				}
			}
		} catch (e) {
			if (e?.response?.status !== 403) {
				const error = e?.response?.data?.errors[0];
				if (error?.field === 'uber_ride_status') {
					setCancelModalErrorMessage(error?.message);
				} else {
					dispatch(showNotification('error', getEndUserMessage(e) || 'Failed update shift. Please try again!'));
				}
			}
			props.setIsLoading(false);
		}
	};

	const handleFlexShiftCancel = async (cancelationReason, needsReplacement, isClaimedSlot = false) => {
		props.setIsLoading(true);

		const updateSlotId = selectedSlotToCancel.slot_id;

		try {
			await xhr.request('PUT', `/flex/slot/${updateSlotId}/cancel`, {
				cancelationReason,
				needsReplacement,
				extraInfo: null
			});

			dispatch(showNotification('success', 'Shift cancelled successfully.'));

			if (props.fromGlobalOverview) {
				props.history.goBack();
			} else {
				props.fetchData();
				if (!isClaimedSlot) {
					// non-claimed slot needs to call updateShiftStatus and update the slot status to canceled
					props.updateShiftStatus(shift.shift_date, updateSlotId, 'Canceled');
				} else {
					setSelectedSlotToCancel({});
				}
				setShowCancelFlex(false);
			}
		} catch (e) {
			if (e?.response?.status !== 403) {
				const errorMessage = getEndUserMessage(e);
				if (errorMessage === 'Slot has already been cancelled, please refresh the page.') {
					setShowCancelFlex(false);
					setShowRefreshModal(true);
				} else {
					dispatch(showNotification('error', errorMessage || 'Failed update shift. Please try again!'));
				}
			}
			props.setIsLoading(false);
		}
	};

	const onCloseCancelShiftModal = (isNonClaimedSlot) => {
		if (isNonClaimedSlot) {
			setShowCancelConfirmation(false);
		} else {
			setShowCancelModal(false);
		}

		if (cancelModalErrorMessage) {
			setCancelModalErrorMessage(null);
		}
	};

	const sortSlots = (slots) => {
		if (!slots) return [];
		const sortedBy = slots.reduce(
			(total, slot) => {
				const _total = { ...total };
				if (slot.is_cancelled) {
					_total.cancelled.push(slot);
					return _total;
				}
				if (slot.caregiver_name && !slot.slot_status) {
					_total.pending.push(slot);
					return _total;
				}
				if (!slot.caregiver_name && !slot.slot_status) {
					_total.open.push(slot);
					return _total;
				}
				if (slot.caregiver_name && slot.slot_status) {
					_total.confirmed.push(slot);
					return _total;
				}
				_total.rest.push(slot);
				return _total;
			},
			{ cancelled: [], pending: [], open: [], confirmed: [], rest: [] }
		);

		const sortBySlotId = (_slots) => _slots.sort((s1, s2) => s1.slot_id - s2.slot_id);
		const { cancelled, pending, open, confirmed, rest } = sortedBy;

		return [
			...sortBySlotId(cancelled),
			...sortBySlotId(pending),
			...sortBySlotId(open),
			...sortBySlotId(confirmed),
			...sortBySlotId(rest)
		];
	};

	const AddShiftsButton = useCallback(
		() =>
			props.delinquentAccountReceivableStatus ? (
				<OverlayTrigger
					trigger={['hover', 'focus']}
					key="clock-out-tooltip"
					placement="top"
					overlay={
						<Tooltip show className="accounts-receivable-tooltip">
							This button is disabled due to non-payment
						</Tooltip>
					}
				>
					<div className="button-container">
						<Button
							exactPermission={PERMISSION.EDIT}
							className="add-slot-button text-nowrap read-only"
							onClick={() => setShowAddSlotVendorWarningModal(true)}
							title="+ Add Shifts"
						/>
					</div>
				</OverlayTrigger>
			) : (
				<div className="button-container">
					<Button
						exactPermission={shift.is_flex ? PERMISSION.FLEX_ADD_EDIT_SHIFT : PERMISSION.EDIT}
						className="add-slot-button text-nowrap"
						onClick={() => setShowAddSlotVendorWarningModal(true)}
						title="+ Add Shifts"
					/>
				</div>
			),
		[props.delinquentAccountReceivableStatus, shift.is_flex]
	);

	const openSlots = useMemo(() => shift.slots?.filter((slot) => !slot.caregiver_id).length || 0, [shift.slots]);

	const shouldShowAddButton = () => {
		return shift.is_flex
			? moment.utc(shift.end_time_at_utc).diff(moment.utc(), 'minutes') > 15
			: moment.utc(shift.start_time_at_utc).add(2, 'hours').isAfter(moment.utc());
	};

	const editBonusWageButton = useCallback(
		(facilityPayableBonus) =>
			props.isFixedMarkup ? (
				<Button
					exactPermission={PERMISSION.EDIT}
					className="edit-wage add-bonus-button text-nowrap"
					disabled={!openSlots}
					onClick={handleEditWageModalButton}
					title="Edit Wage"
					key="btnEditWage"
				/>
			) : (
				[
					!facilityPayableBonus && (
						<Button
							exactPermission={PERMISSION.EDIT}
							className="add-bonus-button text-nowrap"
							disabled={preventGuaranteeOrBonusUpdates}
							onClick={handleAddBonusModalButton}
							title="+ Add Bonus"
							key="btnAddBonus"
						/>
					),
					!!facilityPayableBonus && (
						<Button
							exactPermission={PERMISSION.EDIT}
							className="edit-bonus-button text-nowrap"
							disabled={preventGuaranteeOrBonusUpdates}
							onClick={handleEditBonusModalButton}
							title="Edit Bonus"
							key="btnEditBonus"
						/>
					)
				]
			),
		[
			props.isFixedMarkup,
			handleAddBonusModalButton,
			handleEditBonusModalButton,
			handleEditWageModalButton,
			openSlots,
			preventGuaranteeOrBonusUpdates
		]
	);

	return [
		<div
			className={classNames(
				`col-sm-2 col-lg-2 col-xl-2 p-0 d-none d-sm-flex with-border shift-status-cell ${getRowColor(
					shift.slots[0].resource_type
				)}`
			)}
			key={`${shift.start_time}${shift.end_time}`}
		>
			<div className="col p-0 d-flex d-sm-flex shift-interval-cell">
				<Sticky
					className="w-100 sticky-class"
					scrollElement=".virtuoso-container"
					boundaryElement=".shift-interval-cell > div"
					dontUpdateHolderHeightWhenSticky
					hideOnBoundaryHit={false}
					disabled={shift.slots.length < 3}
				>
					<div className="row no-gutters shift-info-container">
						<div className="col-sm-12 col-lg-12 col-xl-12  shift-block d-flex flex-column justify-content-around">
							<div className="row no-gutters flex-column align-items-center">
								<div className="d-flex w-100 align-items-center justify-content-between">
									{!isFilterByCaregiver && !shift.is_flex && (
										<GuaranteedCheckbox
											onGuaranteedCheckboxChange={onGuaranteedCheckboxChange}
											shift={shift}
											preventGuaranteeOrBonusUpdates={preventGuaranteeOrBonusUpdates}
											className="row no-gutter guaranteed-shift d-sm-flex"
											isGuaranteed={isGuaranteed}
											isAllowedToToggle={hasToggleGuaranteePermission && openSlotsCount > 0}
										/>
									)}
									{shift.is_orientation && (
										<OverlayTrigger
											placement="right"
											trigger={['hover', 'focus']}
											show
											overlay={
												<Tooltip delay={{ show: 150, hide: 400 }} className="orientation-tooltip">
													Orientation
												</Tooltip>
											}
										>
											<span className="orientation-block">
												<img className="orientation-icon" src={orientationIcon} alt="Orientation shift" />
												<span className="orientation-block-text">Orientation</span>
											</span>
										</OverlayTrigger>
									)}
									{shift.is_flex && <b>FLEX</b>}
								</div>

								<div className="shift-ratio resource-type">
									<span>{shift.slots[0].resource_type}</span>
								</div>
								{!isFilterByCaregiver && !hideLabel && (
									<div className="shift-ratio">
										<span>{getShiftRatio(shift)} filled</span>
									</div>
								)}
							</div>
						</div>
						{!isFilterByCaregiver && (
							<div className="col-sm-12 col-lg-12 col-xl-12 d-flex flex-column justify-content-around">
								<div className="row no-gutters mt-4 add-buttons-column">
									{shouldShowAddButton() && <AddShiftsButton />}
									<div className="action-buttons">
										{!shift.is_flex && editBonusWageButton(shift.facilityPayableBonus)}
										{!shift.is_flex && Utils.isAuthorizedExact(PERMISSION.INVITE, props.user.permissions) && (
											<Button
												exactPermission={PERMISSION.INVITE}
												className="add-bonus-button invite-hcp text-nowrap"
												onClick={handleInviteButton}
												title={`Invite ${shift.resourceType.split('-')[0]}`}
												disabled={!hasSlotsAvailable(shift)}
											/>
										)}
										{shift.is_flex &&
											Utils.isAuthorizedExact(PERMISSION.FLEX_ADD_CAREGIVER, props.user.permissions) && (
												<Button
													exactPermission={PERMISSION.FLEX_ADD_CAREGIVER}
													className="add-bonus-button invite-hcp text-nowrap"
													onClick={handleAddFlexWorkerButton}
													title={`Assign ${shift.resourceType.split('-')[0]}`}
													disabled={!hasSlotsAvailable(shift)}
												/>
											)}
									</div>
								</div>
							</div>
						)}
					</div>
				</Sticky>
			</div>
		</div>,

		<div className="col-sm-10 col-lg-10 col-xl-10 slots-container" key={`${shift.start_time}${shift.end_time}-slots`}>
			{sortSlots(shift.slots).map((slot) => (
				<ShiftSlot
					key={`${slot.slot_id}`}
					ref={(el) => {
						slotRefs[slot.slot_id] = el;
					}}
					slot={slot}
					shift={shift}
					getRowColor={getRowColor}
					isFixedMarkup={props.isFixedMarkup}
					setUnitAssignmentSlot={setUnitAssignmentSlot}
					units={units}
					updateSlotArrivalStatus={updateSlotArrivalStatus}
					onConfirm={onConfirm}
					onPrepareCancel={onPrepareCancel}
					user={user}
					onGuaranteedCheckboxChange={onGuaranteedCheckboxChange}
					onGuaranteeBundleChange={props.onGuaranteeBundleChange}
					setShowUnitAssignmentModal={setShowUnitAssignmentModal}
					preventGuaranteeOrBonusUpdates={preventGuaranteeOrBonusUpdates}
					handleEditBonusModalButton={handleEditBonusModalButton}
					handleAddBonusModalButton={handleAddBonusModalButton}
				/>
			))}
		</div>,

		user.hasUnitsEnabled && (
			<UnitAssignment
				key="unit-assignment"
				user={user}
				availableUnits={units}
				setIsLoading={props.setIsLoading}
				setShowModal={setShowUnitAssignmentModal}
				showModal={showUnitAssignmentModal}
				slot={unitAssignmentSlot}
			/>
		),

		showEditWagePermissionModal && (
			<GenericPermissionsModal
				key="bonus-permission-modal"
				onConfirm={() => setShowEditWagePermissionModal(false)}
				onClose={() => setShowEditWagePermissionModal(false)}
				permissionAction=" edit healthcare professional hourly wages"
			/>
		),

		showEditWageModal && (
			<EditWageModal
				key="edit-wage-modal"
				shift={shift}
				openSlots={openSlots}
				isGuaranteed={isGuaranteed}
				onClose={() => setShowEditWageModal(false)}
				fetchData={props.fetchData}
			/>
		),

		showBonusPermissionsModal && (
			<GenericPermissionsModal
				key="bonus-permission-modal"
				onConfirm={() => setShowBonusPermissionsModal(false)}
				onClose={() => setShowBonusPermissionsModal(false)}
				permissionAction=" add, edit & remove bonuses"
				showProTip
			/>
		),

		showBonusModal && (
			<BonusModal
				key="bonus-modal"
				type={showBonusModal[0]}
				shift={showBonusModal[1]}
				previousBonus={shift.facilityPayableBonus}
				onConfirm={updateFacilityPayableBonus}
				onClose={() => setShowBonusModal(false)}
			/>
		),

		showConfirmConfirmation && (
			<ConfirmationModal
				key="confirm-confirmation"
				onConfirm={() => {
					setShowConfirmConfirmation(false);
					setShowConfirmShiftVendorWarningModal(true);
				}}
				onCancel={() => setShowConfirmConfirmation(false)}
			>
				Are you sure you want to confirm the shift?
			</ConfirmationModal>
		),

		showCancelFlex && (
			<CancelFlexShiftModal
				key="cancel-flex"
				onClose={() => setShowCancelFlex(false)}
				onCancel={handleFlexShiftCancel}
				resourceType={selectedSlotToCancel.resource_type}
				caregiverName={selectedSlotToCancel.caregiver_name}
				date={moment(selectedSlotToCancel.shift_date, 'MMMM Do').format('MMM. D')}
				day={moment(selectedSlotToCancel.shift_day, 'dddd').format('ddd.')}
				startTime={selectedSlotToCancel.start_time}
				endTime={selectedSlotToCancel.end_time}
				cancelModalErrorMessage={cancelModalErrorMessage}
				totalSlotsWorked={selectedSlotToCancel.caregiver?.caregiverStatsForFacility?.arrived}
				isClaimedSlot={selectedSlotToCancel.caregiver_id !== null}
				isLoading={props.isLoading}
			/>
		),

		showCancelConfirmation && (
			<CancelShiftModal
				key="cancel-confirmation"
				onClose={() => onCloseCancelShiftModal(true)}
				onCancel={handleCancelShiftModalButton}
				isNonClaimedSlot
				isLoading={props.isLoading}
			/>
		),

		showCancelModal && (
			<CancelShiftModal
				key="cancel-shift-modal"
				onClose={() => onCloseCancelShiftModal(false)}
				onCancel={handleCancelShiftModalButton}
				resourceType={selectedSlotToCancel.resource_type}
				caregiverName={selectedSlotToCancel.caregiver_name}
				date={moment(selectedSlotToCancel.shift_date, 'MMMM Do').format('MMM. D')}
				day={moment(selectedSlotToCancel.shift_day, 'dddd').format('ddd.')}
				startTime={selectedSlotToCancel.start_time}
				endTime={selectedSlotToCancel.end_time}
				hourlyRate={selectedSlotToCancel.facility_price}
				timezone={selectedSlotToCancel.facility_timezone}
				bonus={selectedSlotToCancel.shift_facility_payable_bonus}
				isGuaranteed={selectedSlotToCancel.is_guaranteed}
				billableCancellationHours={user.activeFacility.facility_billable_cancellation_hours}
				windowCancellationHours={user.activeFacility.facility_window_cancellation_hours}
				startTimeAtUTC={selectedSlotToCancel.start_time_at_utc}
				cancelModalErrorMessage={cancelModalErrorMessage}
				checkinUberStatus={selectedSlotToCancel.checkin_uber_ride_status}
				totalSlotsWorked={selectedSlotToCancel.caregiver?.caregiverStatsForFacility?.arrived}
				caregiverTier={selectedSlotToCancel.caregiver?.caregiverStats?.tier}
				isReturningWorker={selectedSlotToCancel.is_returning_worker}
				isNonClaimedSlot={false}
				isLoading={props.isLoading}
			/>
		),

		showGuaranteedModal && (
			<GuaranteeSlotsModal
				key="guaranteed-modal"
				slots={slotsToGuarantee}
				onSaveChanges={updateIsGuaranteedSlots}
				resourceType={shift.slots[0].resource_type}
				dateAndTime={`${shift.shift_date}, ${shift.start_time} - ${shift.end_time}`}
				onCancel={() => setShowGuaranteedModal(false)}
			/>
		),

		showSlotsModal && (
			<ConfirmationModal
				key="add-slot-modal"
				size="xl"
				title="Add a new shift"
				onConfirm={requestNewSlots}
				onCancel={hideSlotsModal}
				closeButton={false}
				cancelLabel="CANCEL"
			>
				<AddSlots numberOfSlots={numberOfSlots} setNumberOfSlots={setNumberOfSlots} shift={shiftSelectedToAddSlots} />
			</ConfirmationModal>
		),

		showFlexSlotsModal && (
			<ConfirmationModal
				key="add-flex-slot-modal"
				size="xl"
				title="Add a new flex shift"
				onConfirm={requestNewFlexSlots}
				onCancel={hideFlexSlotsModal}
				closeButton={false}
				cancelLabel="CANCEL"
			>
				<AddSlots
					numberOfSlots={numberOfSlots}
					setNumberOfSlots={setNumberOfSlots}
					shift={flexShiftSelectedToAddSlots}
				/>
			</ConfirmationModal>
		),
		showInviteCaregiverModal && (
			<InviteCaregiverModal
				key="invite-caregiver-modal"
				size="xl"
				onCancel={handleCloseInviteCaregiverModal}
				onSendInvitation={handleSendInvitation}
				title={`Invite ${shift.resourceType}`}
				shift={shift}
				shiftId={shiftId}
				isRecurring={isRecurring}
				onShiftIdChange={setShiftId}
				onRecurringStatusChange={setIsRecurring}
				closeButton
				cancelLabel="Cancel"
				backdropClassName="invite-caregiver-backdrop"
			/>
		),

		showAssignCaregiverModal && (
			<AssignCaregiverModal
				key="assign-caregiver_modal"
				onCancel={handleCloseAssignCaregiverModal}
				onAssign={handleOnAssign}
				shift={shift}
				backdropClassName="invite-caregiver-backdrop"
			/>
		),

		<VendorWarningModal
			key="add-slot-vendor-warning"
			isVendorEnabled={props.isVendorEnabled}
			showModal={showAddSlotVendorWarningModal}
			onConfirm={() => showModalToAddSlots(shift)}
			onCancel={() => setShowAddSlotVendorWarningModal(false)}
		/>,

		<VendorWarningModal
			key="confirm-shift-vendor-warning"
			isVendorEnabled={props.isVendorEnabled}
			showModal={showConfirmShiftVendorWarningModal}
			onConfirm={confirm}
			onCancel={() => setShowConfirmShiftVendorWarningModal(false)}
		/>,

		<VendorWarningModal
			key="cancel-shift-with-caregiver-vendor-warning"
			isVendorEnabled={props.isVendorEnabled}
			showModal={showCancelShiftWithCaregiverVendorWarningModal}
			onConfirm={() => {
				setShowCancelShiftWithCaregiverVendorWarningModal(false);
				setShowCancelModal(true);
			}}
			onCancel={() => setShowCancelShiftWithCaregiverVendorWarningModal(false)}
		/>,

		showConfirmModalContractCancellation && (
			<BaseModal
				titleIcon={<WarningIcon width={80} height={80} />}
				key="show-confirm-modal-contract-cancellation"
				confirmModal
				title="Please Confirm"
				primaryActionLabel="Continue"
				onPrimaryAction={() => onCancel(selectedSlotToCancel)}
				primaryActionDisabled={false}
				secondaryActionLabel="Keep Shift"
				onSecondaryAction={withdrawContractCancellation}
				secondaryActionDisabled={false}
				onHide={withdrawContractCancellation}
			>
				This shift is a part of{' '}
				<b>
					{selectedSlotToCancel && selectedSlotToCancel.contract_non_cancelled_slots_count
						? selectedSlotToCancel.contract_non_cancelled_slots_count
						: 0}
					-shift contract
				</b>
				. Are you sure that you want to cancel the shift?
			</BaseModal>
		),
		showRefreshModal && (
			<SingleActionModal
				confirmModal
				key="show-refresh-modal"
				title="Refresh the Page"
				actionLabel="Refresh Now"
				onAction={() => {
					setShowRefreshModal(false);
					props.fetchData();
				}}
				className="refresh-modal"
				onHide={() => setShowRefreshModal(false)}
				disableClickOutside
			>
				The shift you selected has changed, refresh now to reflect the latest changes.
			</SingleActionModal>
		)
	];
};

export default memo(Shift);
