import React, { memo } from 'react';

import './ConfirmAssignCaregiverModal.scss';
import BaseModal from '../BaseModal/BaseModal';

const ConfirmAssignCaregiverModal = ({ onCancel, onConfirm }) => {
	return (
		<BaseModal
			className="already-arrived-modal"
			confirmModal
			title="Assign Professional?"
			primaryActionLabel="Assign Shift"
			onPrimaryAction={onConfirm}
			secondaryActionLabel="Go back"
			onSecondaryAction={onCancel}
			onHide={onCancel}
		>
			This professional seems to be unavailable, are <br /> you sure you want to assign them to this shift?
		</BaseModal>
	);
};

export default memo(ConfirmAssignCaregiverModal);
