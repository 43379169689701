/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */

import { createSlice } from '@reduxjs/toolkit';
import xhr from '../utils/xhr';
import { showNotification } from './notification';
import { loadFeed, loadShiftStructures } from './feed';
import { loadPastShifts, setShiftsFetched, setShowLoadingSpinner } from './pastShifts';
import { setError } from './error';
import { ERROR_CODES } from '../constants';
import Utils from '../utils/utils';
import store from './store';

export const slice = createSlice({
	name: 'user',
	initialState: {
		name: '',
		initials: '',
		email: '',
		facility_name: '',
		timezone: 'UTC',
		loaded: false,
		permissionsByFacility: {},
		permissions: [],
		activeFacility: '',
		facilitiesEnabled: [],
		marketplacePartnerIntegrated: false,
		hasUnitsEnabled: false,
		globalOverviewEnabled: false,
		features: [],
		phone: {}
	},
	reducers: {
		setUser: (state, action) => {
			/* eslint no-param-reassign: ["error", { "props": false }] */
			state.name = action.payload.name;
			state.initials = action.payload.initials;
			state.email = action.payload.email;
			state.timezone = action.payload.facility_timezone;
			state.loaded = true;
			state.permissionsByFacility = action.payload.permissions;
			state.activeFacility = action.payload.activeFacility;
			state.facility_name = action.payload.activeFacility ? action.payload.activeFacility.name : '';
			state.isRecurringScheduleEnabled = action.payload.is_recurring_schedule_enabled || false;
			state.pastShiftsMonthsRange = action.payload.pastShiftsMonthsRange;
			state.effectiveDateApprovalsDisputes = action.payload.effectiveDateApprovalsDisputes;
			state.maxShiftsToCreate = action.payload.maxShiftsToCreate;
			state.maxCaregiverWage = action.payload.maxCaregiverWage;
			state.shiftsToReview = action.payload.shiftsToReview;
			state.maxFacilitiesToShow = action.payload.maxFacilitiesToShow;
			state.pastShiftsWeek = action.payload.pastShiftsWeek;
			state.reportsEnabled = action.payload.reportsEnabled;
			state.globalOverviewEnabled = action.payload.globalOverviewEnabled;
			state.globalManagementCompanyPermissions = action.payload.globalManagementCompanyPermissions;
			state.managementCompanyPermissions = action.payload.managementCompanyPermissions;
			// sort the facilities by name, in order to
			// not require existing logged-in users to log in again
			state.facilitiesEnabled = action.payload.facilitiesEnabled?.sort((a, b) => {
				if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
				if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
				return 0;
			});
			state.permissions =
				action.payload.permissions && state.activeFacility ? action.payload.permissions[state.activeFacility.id] : [];
			state.marketplacePartnerIntegrated = action.payload.marketplace_partner_integrated;
			state.hasUnitsEnabled = action.payload.activeFacility?.portal_units_enabled;
			state.features = action.payload.activeFacility?.features.map((feature) => feature.code);
			state.phone = action.payload.phone;
			state.facilityContactMethodCode = action.payload.contact_method_code;
			state.allowFlexShifts = action.payload.allow_flex_shifts;
			state.allowOnDemandShifts = action.payload.allow_on_demand_shifts;
			// Agency related values
			state.agencyId = action.payload.agency_id;
			state.agencyName = action.payload.agency_name;
		}
	}
});

export const loadUser = () => async (dispatch) => {
	try {
		const userResponse = await xhr.request('GET', '/user');
		dispatch(slice.actions.setUser(userResponse.data));
	} catch (error) {
		dispatch(slice.actions.setUser({}));
	}
};

export const setCurrentFacility = (facilityId) => async (dispatch) => {
	try {
		dispatch(setShiftsFetched(false));
		dispatch(setShowLoadingSpinner(true));
		await xhr.request('PUT', '/user/active-facility', { new_facility_id: facilityId });
		await dispatch(loadUser());
		await dispatch(loadFeed());
		const { permissions } = store.getState().user;
		if (Utils.isAuthorizedExact('PORTAL.SHIFTS.PAST', permissions)) {
			// Prevent triggering request if permission no longer applies for current facility
			await dispatch(loadPastShifts());
		}
		await dispatch(loadShiftStructures());
		return true;
	} catch (error) {
		const { code, message } = Utils.handleNetworkError(error, 'Failed to change active facility. Please try again.');
		dispatch(showNotification('error', message));

		if (code === ERROR_CODES.FACILITY_NOT_PERMITTED) {
			dispatch(setError(ERROR_CODES.FACILITY_NOT_PERMITTED));
		}
		return false;
	} finally {
		dispatch(setShowLoadingSpinner(false));
	}
};

export const user = (state) => state.user;

export const selectedFacility = (state) => (state.user.activeFacility ? state.user.activeFacility.id : null);

export default slice.reducer;
