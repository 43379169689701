import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AgencySchedule from '../features/external-agency/AgencySchedule';
import AgencyProfessionals from '../features/external-agency/AgencyProfessionals';
import AgencyProfessionalsDetails from '../features/external-agency/AgencyProfessionalsDetails';

/* eslint-disable-next-line no-unused-vars */
const AgencyRoutes = ({ userData }) => {
	return (
		<Switch>
			<Route path="/private/agency/shifts" exact component={AgencySchedule} />
			<Route path="/private/agency/professionals" exact component={AgencyProfessionals} />
			<Route
				path="/private/agency/professionals/employee-details/:caregiverId"
				exact
				component={AgencyProfessionalsDetails}
			/>
			<Route path="/" render={() => <Redirect to="/404" />} />
		</Switch>
	);
};

export default AgencyRoutes;
