import { useState, useEffect, useRef } from 'react';

const useOverflow = (slot) => {
	const [isOverflowing, setIsOverflowing] = useState(false);
	const ref = useRef(null);

	useEffect(() => {
		const checkOverflow = () => {
			if (ref.current) {
				const { scrollHeight, offsetHeight } = ref.current;
				setIsOverflowing(scrollHeight > offsetHeight);
			}
		};

		checkOverflow();
		window.addEventListener('resize', checkOverflow);

		return () => {
			window.removeEventListener('resize', checkOverflow);
		};
	}, [slot]);

	return { ref, isOverflowing };
};

export default useOverflow;
