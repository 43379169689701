/* eslint-disable prefer-template */
import moment from 'moment-timezone';
import config from '../config';

const utils = {
	ALLOWED_MIME_TYPES: ['application/pdf', 'image/jpeg', 'image/png'],

	emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

	getURLQueryParams: () => {
		const url = window.location.href; // Use the "location.href" property because the "search" property doesn't work with the "#" inserted by HashRouter in the URL.
		const query = url.substring(url.lastIndexOf('?') + 1);
		const vars = query.split('&');
		const queryParamsObject = {};

		for (let i = 0; i < vars.length; i++) {
			const pair = vars[i].split('=');
			queryParamsObject[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
		}
		return queryParamsObject;
	},

	openAttachment: (slotId) => {
		window.open(`${config.HOST_NAME}${config.API_URL}/slot/${slotId}/credential-packet`, '_blank');
		return false;
	},

	handleNetworkError: (e, customMessage = null) => {
		let code;
		let field;
		let message = customMessage || 'Operation failed, please try again!';

		const res = e.response;
		const validationError = res && res.data && res.data.validationError;

		if (validationError) {
			const [error] = res.data.errors;
			code = error.code;
			field = error.field;
			message = error.message || message;
		}

		return { code, field, message };
	},

	handleNetworkErrorMessage: (e, customMessage = null) => {
		const res = e.response;
		const validationError = res && res.data && res.data.validationError;
		let endUsermessage = customMessage || 'Operation failed, please try again!';
		if (validationError) {
			if (res && res.data && res.data.validationError) {
				const errorMessage = res.data.errors[0].message;
				if (errorMessage) {
					endUsermessage = errorMessage;
				}
			}
		}
		return endUsermessage;
	},

	isAuthorized: (section, operation, permissions) => {
		if (!permissions || !Array.isArray(permissions)) {
			return false;
		}

		return permissions.some((permission) => {
			const permissionSections = permission.toLowerCase();

			if (operation) {
				return (
					permissionSections.startsWith(`${section.toLowerCase()}.`) &&
					permissionSections.endsWith(`.${operation.toLowerCase()}`)
				);
			}
			return permissionSections === section.toLowerCase(); // Exact match
		});
	},

	formatToSimpleTime: (time) => {
		const momentParsed = moment.utc(time);
		const minutes = momentParsed.get('minutes');

		if (minutes > 0) {
			return momentParsed.format('h:mm A');
		}

		return momentParsed.format('h A');
	},

	isAuthorizedExact: (permission, permissions) => utils.isAuthorized(permission, null, permissions),

	isAuthorizedSection: (section, permissions) => {
		if (!permissions || !Array.isArray(permissions)) {
			return false;
		}

		return permissions.some((permission) => {
			const permissionSections = permission.toLowerCase();
			return permissionSections.startsWith(`${section.toLowerCase()}.`);
		});
	},

	isGlobalManagementCompanyAuthorized: (section, operation, permissions) =>
		utils.isAuthorized(section, operation, permissions),

	isFeatureEnabled: (featureCode, features = []) => features.some((f) => f.toLowerCase() === featureCode.toLowerCase()),

	sort: (arr, fieldName, reversed) => {
		const r = reversed ? -1 : 1;
		arr.sort((a, b) => {
			if (a[fieldName] !== null) {
				if (b[fieldName] === null) {
					return r * -1;
				}

				if (a[fieldName] < b[fieldName]) return -1 * r;
				if (a[fieldName] > b[fieldName]) return r;

				return 0;
			}
			if (b[fieldName] !== null) {
				return r;
			}
			return 0;
		});
		return arr;
	},

	sortTimeStrings: (arr, fieldName) =>
		arr.sort((a, b) => {
			const aDate = new Date('1970/01/01 ' + a[fieldName].replace(/(AM|PM)/, ' $1'));
			const bDate = new Date('1970/01/01 ' + b[fieldName].replace(/(AM|PM)/, ' $1'));
			return aDate - bDate;
		}),

	getPayrollFromDate: (date) => date.day(date.day() <= 5 ? 5 : 12),

	bytesToMegaBytes: (bytes) => bytes / (1024 * 1024),

	roundWithPrecision: (number, precision) => {
		const shift = (numberToShift, exponent) => {
			const numArray = `${numberToShift}`.split('e');
			return +`${numArray[0]}e${numArray[1] ? +numArray[1] + exponent : exponent}`;
		};

		return shift(Math.round(shift(number, +precision)), -precision);
	},

	validateFileType: (file) => {
		if (file && utils.ALLOWED_MIME_TYPES.indexOf(file.type.toLowerCase()) === -1) {
			return 'Error uploading files. Only PDF, JPG and PNG files are allowed';
		}
		return null;
	},

	formatPhoneNumber: (number) => {
		const setDashes = (pos) => (str) =>
			str.length > pos && str[pos] !== '-' ? [str.slice(0, pos), '-', str.slice(pos)].join('') : str;
		const firstN = (n) => (str) => str.substring(0, n);
		const onlyNumbers = (str) => str.replace(/\D/g, '');
		const pipe = (...functions) => (args) => functions.reduce((arg, fn) => fn(arg), args);

		return pipe(onlyNumbers, setDashes(3), setDashes(7), firstN(12))(number);
	},

	pluralize: (word, suffix, count) => {
		return count === 0 || count > 1 ? word + suffix : word;
	},

	parseRTKQueryValidationError: (error, fallbackMessage) => {
		let message;
		if (error?.data) {
			if (error.data.validationError === true) {
				if (Array.isArray(error.data.errors)) {
					message = error.data.errors[0]?.message;
				}
			}
		}
		return message || fallbackMessage;
	},

	formatNumber: (currentNumber) => {
		return currentNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	},
	isStructureEndingSoon: (structure, timezone, date, thresholdTime = 15, timeUnit = 'minutes') => {
		const dateSelected = moment.tz(date, timezone);
		const today = moment.tz(timezone);
		const dateSelectedIsToday = dateSelected.isSame(today, 'day');

		const startTime = moment.tz(structure.from_time, 'HH:mm:ss', true, timezone);
		const endTime = moment.tz(structure.to_time, 'HH:mm:ss', true, timezone);

		if (startTime.isSameOrAfter(endTime)) {
			endTime.add(1, 'day');
		}

		return dateSelectedIsToday && endTime.diff(today, timeUnit) <= thresholdTime;
	},
	isSctructureAlreadyStarted: (structure, timezone, date) => {
		const structureStartTime = moment.tz(structure.from_time, 'HH:mm:ss', true, timezone);
		const dateSelected = moment.tz(date, timezone);
		const today = moment.tz(timezone);
		const dateSelectedIsToday = dateSelected.isSame(today, 'day');
		return dateSelectedIsToday && today.isAfter(structureStartTime, 'minutes');
	},
	groupDataByKey: (array, key) => {
		return array.reduce((accumulator, item) => {
			accumulator[item[key]] = accumulator[item[key]] || [];
			accumulator[item[key]].push(item);
			return accumulator;
		}, {});
	}
};

export default utils;
