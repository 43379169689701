import React, { memo } from 'react';
import moment from 'moment';
import './ReopenShiftModal.scss';
import BaseModal from '../../components/modals/BaseModal/BaseModal';

const ReopenShiftModal = ({ onCancel, onConfirm, shiftToReopen }) => {
	return (
		<BaseModal
			className="reopen-shift-modal"
			title="Reopen Shift"
			primaryActionLabel="Reopen Shift"
			onPrimaryAction={onConfirm}
			secondaryActionLabel="Cancel"
			onSecondaryAction={onCancel}
			onHide={onCancel}
		>
			<div className="reopen-shift-modal__container">
				<div className="reopen-shift-modal__title">Are you sure you want to reopen this shift?</div>
				<div className="reopen-shift-modal__facility">
					<span className="reopen-shift-modal__facility__name"> Facility:</span> {shiftToReopen.facility_name}
				</div>
				<div className="reopen-shift-modal__shift-details">
					<div className="reopen-shift-modal__shift-details__resource">
						<span className="reopen-shift-modal__shift-details__resource__name">Shift Details: </span>
						{shiftToReopen.shift_specialty
							? `${shiftToReopen.shift_resource_type}-${shiftToReopen.shift_specialty}`
							: shiftToReopen.shift_resource_type}
					</div>
					<div className="reopen-shift-modal__shift-details__date">{moment().format('dddd[,] MMMM Do YYYY')}</div>
					<div className="reopen-shift-modal__shift-details__time">{`${shiftToReopen.formatted_start_time} - ${shiftToReopen.formatted_end_time}`}</div>
				</div>
			</div>
		</BaseModal>
	);
};

export default memo(ReopenShiftModal);
