import React, { memo } from 'react';
import searchCheckIcon from '../../assets/images/search-check.svg';
import infoIcon from '../../assets/images/icons8-info.svg';

const ShiftsCountBanner = ({ shiftsCount, filtersApplied }) =>
	shiftsCount === 2000 ? (
		<div className="shift-count-warning">
			<img src={infoIcon} alt="info" className="hift-count-warning-icon" />
			<div>
				<b>Results are showing up to the first {shiftsCount} shifts only.</b> To see the rest of the shifts, try
				updating the start date to a later date within your current selected range.
			</div>
		</div>
	) : (
		<div className="shift-count-info">
			<img src={searchCheckIcon} alt="info" />
			<div>
				Showing all {shiftsCount} shifts within the selected date range{filtersApplied && ' and applied filters'}
			</div>
		</div>
	);

export default memo(ShiftsCountBanner);
