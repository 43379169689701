const AVAILABILITY_BADGES = {
	AVAILABLE: {
		name: 'Available',
		style: 'available',
		status: 'AVAILABLE'
	},
	UNAVAILABLE: {
		name: 'Unavailable',
		style: 'unavailable',
		status: 'UNAVAILABLE'
	},
	ASSIGNED: {
		name: 'Assigned',
		style: 'assigned',
		status: 'ASSIGNED'
	},
	NOT_PROVIDED: {
		name: 'Not Provided',
		style: 'not_provided',
		status: 'NOT_PROVIDED'
	}
};

export default AVAILABILITY_BADGES;
