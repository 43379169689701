import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { showNotification } from './notification';
import { setError } from './error';
import config from '../config';

// Creating a getCookie function to be reused across the system
const getCookie = () => {
	const cookie = document.cookie
		.split(';')
		.map((_cookie) => _cookie.trim().split('='))
		.find(([name]) => name === 'csrfToken');

	return cookie ? cookie[1] : null;
};

const baseQuery = fetchBaseQuery({
	baseUrl: config.API_URL,
	prepareHeaders: (headers) => {
		const token = getCookie();
		if (token) {
			headers.set('x-csrf-token', token);
		}
		return headers;
	},
	headers: {
		'X-Requested-With': 'XMLHttpRequest',
		'Content-Type': 'application/json',
		Accept: 'application/json'
	},
	credentials: 'include'
});

const baseQueryWithErrorHandling = async (args, api, extraOptions) => {
	const result = await baseQuery(args, api, extraOptions);
	if (result.error) {
		const { data = {}, originalStatus, status } = result.error;
		if (
			(originalStatus === 401 || status === 401) &&
			!data.validationError &&
			(window.location.href.includes('/private/') || window.location.href.includes('/form-530/'))
		) {
			window.location.href = `/login?url=${encodeURIComponent(window.location.href)}`;
		} else if (originalStatus === 403 || status === 403) {
			if (data.errors) {
				api.dispatch(showNotification('error', data.errors[0].message));
			} else {
				api.dispatch(setError(data === 'Forbidden' ? '403' : 'Error'));
			}
		} else if (originalStatus === 404 || status === 404) {
			window.location.href = '/404';
		}
	}
	return result;
};

// initialize an empty api service that we'll inject endpoints into later as needed
const api = createApi({
	baseQuery: baseQueryWithErrorHandling,
	endpoints: () => ({})
});

// utility to unwrap the error if there's one coming from the API's response
api.unwrapError = (_error) => {
	if (
		!_error ||
		!_error.data ||
		!_error.data.validationError ||
		!_error.data.errors ||
		!Array.isArray(_error.data.errors) ||
		!_error.data.errors.length
	) {
		// If _error or its nested properties are missing or empty, return an Unexpected Error
		return 'An unexpected error occurred. Please try again or contact ShiftMed if this issue persists.';
	}
	const {
		data: {
			validationError,
			errors: [firstError]
		}
	} = _error;
	if (validationError) {
		return firstError.message;
	}
	return null;
};

export default api;
