import React from 'react';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';

import './BaseModal/BaseModal.scss';

const SingleActionModal = ({
	className,
	onHide,
	titleIcon,
	title,
	children,
	actionDisabled,
	onAction,
	actionLabel,
  disableClickOutside
}) => {
	return (
		<Modal
			size="md"
			show
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className={classNames('base-modal confirm-modal', className)}
			onHide={onHide ?? null}
			backdropClassName="modal-backdrop-no-show"
			backdrop={disableClickOutside ? 'static' : true}
		>
			<Modal.Header closeButton={!!onHide}>
				<div className="header-content">
					{titleIcon}
					<h1>{title}</h1>
				</div>
			</Modal.Header>
			<Modal.Body>{children}</Modal.Body>

			<Modal.Footer>
				<button
					type="button"
					className={classNames('footer-buttons primary-button', className)}
					disabled={actionDisabled}
					onClick={onAction}
				>
					{actionLabel}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default SingleActionModal;
