/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Utils from '../utils/utils';

const ProtectedManagementCompanySectionRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={() =>
			Utils.isGlobalManagementCompanyAuthorized(
				rest.pathPermission,
				rest.operation,
				rest.globalManagementCompanyPermissions
			) ? (
				<Component {...rest} />
			) : (
				<Redirect to="/" />
			)
		}
	/>
);

export default ProtectedManagementCompanySectionRoute;
