import React, { memo, useState, useRef, useEffect } from 'react';
import { enableES5, produce } from 'immer';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';

import xhr from '../../utils/xhr';
import utils from '../../utils/utils';
import { showNotification } from '../../redux/notification';
import BaseModal from './BaseModal/BaseModal';
import ConfirmationModal from './ConfirmationModal';
import ShiftsRequiredByDate from '../../features/shiftsToRequest/ShiftsRequiredByDate';
import ShiftsConfirmation from '../../features/shiftsToRequest/ShiftsConfirmation';
import { user } from '../../redux/user';
import FlexRequestShiftConfirmationModal from './FlexRequestShiftConfirmationModal/FlexRequestShiftConfirmationModal';

import './RequestShiftsModal.css';

enableES5();

const INITIAL_SHIFT_GROUPS = [
	{
		date: new Date(),
		shifts: [
			{
				resourceType: null,
				shiftStructureId: null,
				numberOfProviders: 1,
				isGuaranteed: false,
				id: `id-${Math.random().toString()}`
			}
		]
	}
];

const BACKEND_FIELDS_TO_ERROR_KEY_MAP = {
	is_disabled: 'shiftStructureId',
	structure_id: 'shiftStructureId',
	slots: 'numberOfProviders'
};

const getShiftsToRequest = (shiftsGroups, isFlex) => {
	const shifts = [];
	shiftsGroups.forEach((shiftsGroup) => {
		shiftsGroup.shifts.forEach((shift) => {
			const dataObj = {
				structure_id: shift.shiftStructureId,
				slots: shift.numberOfProviders,
				start_date: moment(shiftsGroup.date).format('YYYY-MM-DD')
			};
			if (!isFlex) {
				dataObj.is_guaranteed = shift.isGuaranteed;
				dataObj.portalShiftId = shift.id;
			}
			shifts.push(dataObj);
		});
	});
	return shifts;
};

const useStateWithCallback = (initialState) => {
	const [state, setState] = useState(initialState);
	const ref = useRef();

	const setStateCallback = (newState, callback) => {
		ref.current = callback;
		setState(newState);
	};

	useEffect(() => {
		if (ref.current) {
			ref.current(state);
			ref.current = null;
		}
	}, [state]);
	return [state, setStateCallback];
};

const createNewShift = () => {
	const newShift = { ...INITIAL_SHIFT_GROUPS[0].shifts[0] };
	newShift.id = `id-${Math.random().toString()}`;
	return newShift;
};

const computeTotalShiftsToCreate = (shiftsGroups) => {
	let count = 0;
	shiftsGroups.forEach((shiftGroup) => {
		count += shiftGroup.shifts.length;
	});
	return count;
};

const RequestShiftsModal = (props) => {
	const userData = useSelector(user);
	const dispatch = useDispatch();

	const [showLoadingSpinner, setShowLoadingSpinner] = useStateWithCallback(false);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [showFlexStructuresAlreadyStartedModal, setShowFlexStructuresAlreadyStartedModal] = useState(false);

	const [shiftsGroups, setShiftsGroups] = useState(INITIAL_SHIFT_GROUPS);
	const [errors, setErrors] = useState({});
	const { shiftStructures } = props;

	const addEmptyShiftGroup = () => {
		if (computeTotalShiftsToCreate(shiftsGroups) >= Number(userData.maxShiftsToCreate)) {
			dispatch(
				showNotification(
					'error',
					`Sorry, you can only post ${userData.maxShiftsToCreate} different shifts per each request.`
				)
			);
			return;
		}
		const nextState = produce(shiftsGroups, (draftState) => {
			const latestExistingShift = draftState[draftState.length - 1];
			draftState.push({
				date: moment.utc(latestExistingShift.date).add(1, 'day'),
				shifts: [createNewShift()]
			});
		});
		setShiftsGroups(nextState);
	};

	const addEmptyShift = (shiftsGroupIndex) => {
		if (computeTotalShiftsToCreate(shiftsGroups) >= Number(userData.maxShiftsToCreate)) {
			dispatch(
				showNotification(
					'error',
					`Sorry, you can only post ${userData.maxShiftsToCreate} different shifts per each request.`
				)
			);
			return;
		}
		const nextState = produce(shiftsGroups, (draftState) => {
			draftState[shiftsGroupIndex].shifts.push(createNewShift());
		});
		setShiftsGroups(nextState);
	};

	const updateShiftGroupDate = (shiftsGroupIndex, newShiftGroupDate) => {
		const nextState = produce(shiftsGroups, (draftState) => {
			// eslint-disable-next-line no-param-reassign
			draftState[shiftsGroupIndex].date = newShiftGroupDate;
		});
		setShiftsGroups(nextState);
	};

	const clearErrors = (updatedObject, shiftId) => {
		const newErrors = { ...errors };
		const modifier = {};
		Object.keys(updatedObject).forEach((key) => {
			if (key === 'shiftStructureId' && updatedObject[key] === null) return;
			if (typeof updatedObject[key] !== 'undefined') {
				newErrors[shiftId] = {
					...newErrors[shiftId],
					[key]: !updatedObject[key], // Each key of the updated object should set its own error value
					...modifier
				};
			}
		});
		setErrors(newErrors);
	};

	const removeShiftError = (shiftId) => {
		const newErrors = { ...errors };
		delete newErrors[shiftId];
		setErrors(newErrors);
	};

	const updateShift = (shiftsGroupIndex, shiftIndex, patchObject, shiftId) => {
		const nextState = produce(shiftsGroups, (draftState) => {
			const shift = draftState[shiftsGroupIndex].shifts[shiftIndex];
			Object.keys(shift).forEach((key) => {
				if (typeof patchObject[key] !== 'undefined') {
					shift[key] = patchObject[key];
				}
			});
		});
		clearErrors(patchObject, shiftId);
		setShiftsGroups(nextState);
	};

	/* removing a shift either deletes the shift or deletes the entire day from the state */
	const removeShift = (shiftsGroupIndex, shiftIndex, shiftGeneralIndex) => {
		const nextState = produce(shiftsGroups, (draftState) => {
			const shiftsTarget = draftState[shiftsGroupIndex].shifts;
			if (shiftsTarget.length === 1) {
				draftState.splice(shiftsGroupIndex, 1);
			} else {
				shiftsTarget.splice(shiftIndex, 1);
			}
		});
		removeShiftError(shiftGeneralIndex);
		setShiftsGroups(nextState);
	};

	/* we can only remove shifts as long as the total shifts is greater than 1 */
	const getAllowRemove = () => {
		return (
			shiftsGroups.reduce((acc, shiftsGroup) => {
				return acc + shiftsGroup.shifts.length;
			}, 0) > 1
		);
	};

	const handleBackendErrors = (backendErrors) => {
		const newErrors = {};
		/* Transform error response into an object that has each portalShiftId property as key */
		if (Array.isArray(backendErrors) && backendErrors.length > 0) {
			backendErrors.forEach((error) => {
				newErrors[error.portalShiftId] = {
					[BACKEND_FIELDS_TO_ERROR_KEY_MAP[error.field]]: true
				};
			});
		}

		setErrors(newErrors);
	};

	const postShiftsRequested = async () => {
		setShowLoadingSpinner(true, async () => {
			try {
				props.setIsLoading(true);
				const shiftsToRequest = getShiftsToRequest(shiftsGroups, props.isFlex);

				let postUrl = '/shifts';
				if (props.isFlex) {
					postUrl = '/flex/shifts';
				}
				const shiftsPosted = await xhr.request('POST', postUrl, shiftsToRequest);
				if (shiftsPosted) {
					dispatch(showNotification('success', 'Shifts requested successfully'));
					props.updateFeed();
					props.onClose();
				}
			} catch (e) {
				if (e.response.status === 403) {
					props.onClose();
				} else {
					const errorMessage = utils.handleNetworkErrorMessage(e, 'Failed to save requested shifts. Please try again.');

					if (e.response && e.response.data) {
						handleBackendErrors(e.response.data.errors);
					}

					dispatch(showNotification('error', errorMessage));
				}
				props.setIsLoading(false);
			} finally {
				setShowConfirmationModal(false);
				setShowLoadingSpinner(false);
			}
		});
	};

	const checkIfSctructuresAreNearToEnd = (shiftStructuresData) => {
		const structures = utils.groupDataByKey(shiftStructures, 'id');
		return shiftStructuresData.some((data) => {
			const [currentStructure] = structures[data.structureId];
			return utils.isStructureEndingSoon(currentStructure, userData.timezone, data.date);
		});
	};

	const checkIfSctructuresAlreadyStarted = (shiftStructuresData) => {
		const structures = utils.groupDataByKey(shiftStructures, 'id');
		return shiftStructuresData.some((data) => {
			const [currentStructure] = structures[data.structureId];
			return utils.isSctructureAlreadyStarted(currentStructure, userData.timezone, data.date);
		});
	};
	const checkIntegrity = async () => {
		const newErrors = {};
		const shiftSctructuresIds = [];
		shiftsGroups.forEach((shiftsGroup) => {
			shiftsGroup.shifts.forEach((shift) => {
				shiftSctructuresIds.push({ structureId: shift.shiftStructureId, date: shiftsGroup.date });
				if (!shift.shiftStructureId || !shift.numberOfProviders) {
					newErrors[shift.id] = {
						resourceType: !shift.resourceType,
						shiftStructureId: !shift.shiftStructureId,
						numberOfProviders: !shift.numberOfProviders
					};
				}
			});
		});
		const errorKeys = Object.keys(newErrors).length;
		if (errorKeys) {
			dispatch(showNotification('error', 'Please complete all fields.'));
		} else if (props.isFlex && checkIfSctructuresAreNearToEnd(shiftSctructuresIds)) {
			dispatch(
				showNotification(
					'error',
					'One or more of the requested shifts has ended. Please update your request and try again.'
				)
			);
		} else if (props.isFlex && checkIfSctructuresAlreadyStarted(shiftSctructuresIds)) {
			setShowFlexStructuresAlreadyStartedModal(true);
		} else {
			setShowConfirmationModal(true);
		}

		setErrors(newErrors);
	};

	const triggerRecurringShiftsModal = () => {
		if (props.user.isRecurringScheduleEnabled) {
			props.onClose();
			props.setShowRecurringShiftsModal(true);
		} else {
			dispatch(
				showNotification(
					'error',
					'This feature is coming soon. If you would like early access, please email PortalSupport@ShiftMed.com'
				)
			);
		}
		return true; // placeholder for sonarcloud
	};

	return [
		<Modal
			key="shift-requests-modal"
			size="xl"
			show={!showConfirmationModal && !showFlexStructuresAlreadyStartedModal}
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className="shift-requests-modal"
			onHide={props.onClose}
			backdrop="static"
			keyboard={false}
		>
			<Modal.Header closeButton className="modal-request-header">
				<Modal.Title id="contained-modal-title-vcenter" className="request-shift-title">
					{props.title || 'Single shifts'}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className="body-request-modal">
				<div className="steps">
					<div className="step-one">
						<p className="step-title">Step 1</p>
						<p className="step-sub">Choose the date</p>
					</div>
					<div className="step-two">
						<p className="step-title">Step 2</p>
						<p className="step-sub">Add shift(s) details</p>
						{!props.isFlex && (
							<div className="guaranteed-section">
								<p className="guaranteed-bold">Guarantee your shifts to attract more professionals.</p>
								<p className="guaranteed-text">
									If this shift is claimed, you are committing to pay for it even if you choose to cancel it later.
								</p>
							</div>
						)}
					</div>
				</div>
				{shiftsGroups.map((shiftsGroup, index) => (
					<ShiftsRequiredByDate
						/* TODO: fix eslint */
						/* eslint-disable-next-line react/no-array-index-key */
						key={`${moment(shiftsGroup.date).format('YYYY-MM-DD')}-${index}`}
						addAnotherDate={addEmptyShiftGroup}
						showAddButton={index === shiftsGroups.length - 1}
						shiftStructures={shiftStructures}
						updateShiftGroupDate={updateShiftGroupDate}
						shiftsGroup={shiftsGroup}
						shiftGroupIndex={index}
						allowRemove={getAllowRemove()}
						addEmptyShift={addEmptyShift}
						updateShift={updateShift}
						removeShift={removeShift}
						errors={errors}
						isOddIndex={index % 2 !== 0}
						isFlex={props.isFlex}
					/>
				))}
			</Modal.Body>
			<Modal.Footer>
				<div className="buttons">
					<Button className="cancel-shifts-button" onClick={props.onClose} variant="secondary">
						{props.cancelLabel || 'CANCEL'}
					</Button>
					<Button className="req-shifts-button" onClick={checkIntegrity} variant="primary">
						{props.confirmLabel || 'POST'}
					</Button>
				</div>
				{!props.isFlex && (
					<button type="button" className="single-shift-button" onClick={triggerRecurringShiftsModal}>
						Add a recurring shift
					</button>
				)}
			</Modal.Footer>
		</Modal>,

		showConfirmationModal && !props.isFlex && (
			<ConfirmationModal
				key="confirmation-modal-request"
				size="xl"
				title="Confirmation"
				scrollable
				customModalBodyClass="modal-body-shift-confirmation"
				onConfirm={postShiftsRequested}
				onCancel={() => setShowConfirmationModal(false)}
				showLoadingSpinner={showLoadingSpinner}
				closeButton={false}
			>
				<ShiftsConfirmation
					shiftsGroups={shiftsGroups}
					shiftStructures={shiftStructures}
					shiftsByDay={props.shiftsByDay}
				/>
			</ConfirmationModal>
		),

		showConfirmationModal && props.isFlex && (
			<BaseModal
				confirmModal
				className="primary-colors"
				key="confirmation-modal-request-flex"
				title="Request Shifts?"
				primaryActionLabel="Request"
				onPrimaryAction={postShiftsRequested}
				secondaryActionLabel="Go Back"
				onSecondaryAction={() => setShowConfirmationModal(false)}
				onHide={() => setShowConfirmationModal(false)}
				primaryActionDisabled={showLoadingSpinner}
				secondaryActionDisabled={showLoadingSpinner}
			>
				Do you want to confirm this shift request?
			</BaseModal>
		),

		showFlexStructuresAlreadyStartedModal && props.isFlex && (
			<FlexRequestShiftConfirmationModal
				key="confirmation-modal-request-flex"
				title="Request shifts? Shifts have started."
				textContent="One or more of the shifts you selected have already started. Do you want to confirm this shift request?"
				boxTextContent={
					<>
						<strong>Reminder:</strong> Shift modifications must be made before their scheduled end time
					</>
				}
				primaryActionLabel="Request shifts"
				onPrimaryAction={postShiftsRequested}
				secondaryActionLabel="Go Back"
				onSecondaryAction={() => setShowFlexStructuresAlreadyStartedModal(false)}
				onHide={() => setShowFlexStructuresAlreadyStartedModal(false)}
				primaryActionDisabled={showLoadingSpinner}
				secondaryActionDisabled={showLoadingSpinner}
			/>
		)
	];
};

export default memo(RequestShiftsModal);
