/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import BaseModal from './BaseModal/BaseModal';
import Select from '../form/Select';
import Radio from '../form/Radio';
import { showNotification } from '../../redux/notification';
import xhr from '../../utils/xhr';
import 'react-bootstrap/cjs/divWithClassName';

import './CancelShiftModal.css';

const REPLACEMENT_WORKER_OPTIONS = [
	{ label: 'Yes', value: 'yes', id: 'yes' },
	{ label: 'No', value: 'no', id: 'no' }
];

const CANCELATION_REASONS_WITH_REPLACEMENT = [
	'SCHEDULED_BY_MISTAKE',
	'CALLED_OUT',
	'RESCHEDULED_TO_DIFFERENT_SHIFT',
	'UNEXPECTED_EVENT'
];

const CancelFlexShiftModal = (props) => {
	const {
		resourceType,
		caregiverName,
		date,
		day,
		startTime,
		endTime,
		cancelModalErrorMessage,
		totalSlotsWorked,
		isClaimedSlot,
		onClose,
		isLoading
	} = props;

	const [cancellationReasons, setCancellationReasons] = useState([]);
	const [selectedReason, setSelectedReason] = useState('');
	const [replacementWorkerNeeded, setReplacementWorkerNeeded] = useState(null);
	const slotsWorked = parseFloat(totalSlotsWorked ?? 0);
	const dispatch = useDispatch();

	const isReadyToSubmit = () => {
		if (!selectedReason) {
			return false;
		}

		if (
			isClaimedSlot &&
			CANCELATION_REASONS_WITH_REPLACEMENT.includes(selectedReason) &&
			replacementWorkerNeeded === null
		) {
			return false;
		}

		if (cancelModalErrorMessage) {
			return false;
		}

		return true;
	};

	useEffect(() => {
		const getPortalCancellationReasons = async () => {
			try {
				const { data } = await xhr.request('GET', `/entity/slot-cancelation-reasons?filterQuery=FLEX_SLOTS`);
				setCancellationReasons(data);
			} catch {
				dispatch(showNotification('error', 'Failed to fetch portal cancellation reasons.'));
			}
		};
		getPortalCancellationReasons();
	}, [dispatch]);

	const handleCancellationReasonChange = (e) => {
		setSelectedReason(e.cancellationReason);
		setReplacementWorkerNeeded(null);
	};

	const handleCancelButton = () => {
		props.onCancel(selectedReason, replacementWorkerNeeded === 'yes', isClaimedSlot);
	};

	return (
		<BaseModal
			title={!isClaimedSlot ? 'Cancel Shift' : `Are you sure you want to cancel ${caregiverName}?`}
			className="cancel-shift-modal"
			primaryActionLabel="Cancel Shift"
			onPrimaryAction={isReadyToSubmit() ? handleCancelButton : null}
			primaryActionDisabled={!isReadyToSubmit() || isLoading}
			secondaryActionLabel="Go Back"
			onSecondaryAction={onClose}
			secondaryActionDisabled={isLoading}
			onHide={onClose}
		>
			{!isClaimedSlot && (
				<span>Please select the reason and fill in the necessary details to cancel the following shift</span>
			)}
			{isClaimedSlot && (
				<div className="cancellation-information">
					<span className="sub-title">Facility shifts worked: {slotsWorked}</span>
					<div className="shift-details-container">
						<span className="sub-title">Shift details: </span>
						<span className="cancellation-info">{resourceType}</span>
						<span className="cancellation-info">
							{day} {date}
						</span>
						<span className="cancellation-info">
							{startTime} - {endTime}
						</span>
					</div>
				</div>
			)}
			<hr />
			<div className="row no-gutters justify-content-between reason-section mb-4">
				<div className="col-12 col-md-5">
					<div className="mb-3">
						<span>Select a reason for the cancellation</span>
					</div>
					<Select
						name="cancellationReason"
						placeholder="Select one..."
						value={selectedReason}
						options={cancellationReasons}
						handleChange={handleCancellationReasonChange}
						isDisabled={isLoading}
					/>
				</div>
				<div className="reason-feedback col-12 col-md-5">
					{isClaimedSlot && CANCELATION_REASONS_WITH_REPLACEMENT.includes(selectedReason) && (
						<div className="reason-feedback-checkbox">
							<div className="mb-3">
								<span>Do you need a replacement worker?</span>
							</div>
							<div className="">
								<Radio
									options={REPLACEMENT_WORKER_OPTIONS}
									name="replacement-worker-needed"
									selectedOption={replacementWorkerNeeded}
									handleChange={(e) => setReplacementWorkerNeeded(e)}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
			{cancelModalErrorMessage && <div className="error-message"> {cancelModalErrorMessage} </div>}
		</BaseModal>
	);
};

export default CancelFlexShiftModal;
