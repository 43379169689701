import React from 'react';
import './AgencyProfessionalsEmployeeDetails.css';
import WithOnlyOnDesktop from '../../components/onlyOnDesktop/withOnlyOnDesktop';
import AgencyProfessionalsSubtype from './AgencyProfessionalsSubtype';
import { useAgencyProfessionalQuery } from '../../redux/external-agency';
import LoadingSpinner from '../../components/spinner/LoadingSpinner';

const mapCaregiversCertifications = (professional) => {
	if (professional?.certifications?.length) {
		return professional?.certifications.map((certif) => {
			const certifSpecializations = professional.specialties
				?.filter((spec) => spec.resource_type_id === certif.resource_type_id)
				.sort((a, b) => a.label.localeCompare(b.label));
			const states = [...certif.states];
			states.sort((a, b) => a.code.localeCompare(b.code));
			return {
				...certif,
				states,
				specialties: certifSpecializations
			};
		});
	}

	return [{ resource_type: '-', specialties: [], states: [] }];
};

const AgencyProfessionalsEmployeeDetails = ({ caregiverId }) => {
	const { data: professional, isLoading } = useAgencyProfessionalQuery(caregiverId, {
		refetchOnMountOrArgChange: true
	});
	const professionalCertifications = mapCaregiversCertifications(professional);

	if (isLoading) {
		return (
			<div className="spinner-center-wrapper" key="spinner">
				<LoadingSpinner className="saving-spinner" />
			</div>
		);
	}

	return (
		<WithOnlyOnDesktop>
			<div className="employee-details__wrapper">
				<div className="name-and-licenses__wrapper">
					<h2>Name and License(s)</h2>
					<div className="details__wrapper">
						<h3>Name</h3>
						<h4>{professional.name || '-'}</h4>
					</div>
					<div className="home-details__wrapper">
						{professionalCertifications.map((certif) => (
							<div className="home-details-license__wrapper">
								<div className="home-details license-type">
									<h3>License Type</h3>
									<h4>{certif.resource_type}</h4>
								</div>
								<AgencyProfessionalsSubtype subtypeTitle="State" subtypes={certif.states} type="code" />
								<AgencyProfessionalsSubtype subtypeTitle="Specialties" subtypes={certif.specialties} type="label" />
							</div>
						))}
					</div>
				</div>
				<div className="name-and-licenses__wrapper">
					<h2>Home Address</h2>
					<div className="home-details__wrapper">
						<div className="home-details">
							<h3>Street Address</h3>
							<h4>{professional.address || '-'}</h4>
						</div>
						<div className="home-details">
							<h3>City, State</h3>
							<h4>
								{professional.city_name || '-'}, {professional.state_code || '-'}
							</h4>
						</div>
						<div className="home-details">
							<h3>ZIP</h3>
							<h4>{professional.zip_code || '-'}</h4>
						</div>
					</div>
				</div>
				<div className="name-and-licenses__wrapper">
					<h2>Contact Information</h2>
					<div className="details__wrapper">
						<h3>Preferred contact method</h3>
						<h4>Call and text</h4>
					</div>
					<div className="details__wrapper">
						<h3>Phone</h3>
						<h4>{professional.phone_number || '-'}</h4>
					</div>
					<div className="details-email__wrapper">
						<h3>Email</h3>
						<h4>{professional.email || '-'}</h4>
					</div>
				</div>
			</div>
		</WithOnlyOnDesktop>
	);
};

export default AgencyProfessionalsEmployeeDetails;
