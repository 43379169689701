import React, { useMemo } from 'react';
import { OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap';
import { useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames';

import { user } from '../../redux/user';
import utils from '../../utils/utils';
import Button from '../../components/button/Button';
import useConditionalNavigation from '../../components/hooks/useConditionalNavigation';
import './Shifts.css';
import IsAuthorized from '../../components/IsAuthorized/IsAuthorized';

const DailyScheduleActions = ({
	shiftStructures,
	slotsToConfirm,
	delinquentAccountReceivableStatus,
	setShowConfirmationsVendorWarningModal = () => {},
	setShowRequestShiftsVendorWarningModal = () => {},
	setShowFlexRequestsShiftModal = () => {},
	showGoBackButton = false,
	allowFlexShifts,
	allowOnDemandShifts,
	fromGlobalOverview
}) => {
	const { permissions } = useSelector(user, shallowEqual);
	const { navigateByRemovingSearchParams, returnToGlobalOverview } = useConditionalNavigation();
	const hasFlexShiftsAccess = allowFlexShifts && utils.isAuthorizedExact('PORTAL.FLEX.ADD_EDIT_SHIFT', permissions);
	const hasOnDemandShiftsAccess = allowOnDemandShifts && utils.isAuthorizedExact('PORTAL.EDIT', permissions);
	const tooltipTrigger = hasFlexShiftsAccess === false && hasOnDemandShiftsAccess === false ? ['hover', 'focus'] : [];
	const canPostShifts = hasOnDemandShiftsAccess === true || hasFlexShiftsAccess === true;
	const canPostBothShifts = hasOnDemandShiftsAccess && hasFlexShiftsAccess;
	const requiredPermission = hasOnDemandShiftsAccess ? 'PORTAL.EDIT' : 'PORTAL.FLEX.ADD_EDIT_SHIFT';
	const handleReturn = () => {
		if (fromGlobalOverview) {
			returnToGlobalOverview();
		} else {
			navigateByRemovingSearchParams(['slotId', 'facilityId']);
		}
	};

	const requestShiftsButton = useMemo(
		() =>
			// eslint-disable-next-line no-nested-ternary
			delinquentAccountReceivableStatus ? (
				<OverlayTrigger
					trigger={['hover', 'focus']}
					key="clock-out-tooltip"
					placement="top"
					overlay={
						<Tooltip show className="accounts-receivable-tooltip">
							This button is disabled due to non-payment
						</Tooltip>
					}
				>
					<div className="request-shifts-button-container">
						<Button
							exactPermission="PORTAL.EDIT"
							className="secondary-button font-style text-nowrap read-only"
							onClick={() => setShowRequestShiftsVendorWarningModal(true)}
							title="Request Shifts"
						/>
					</div>
				</OverlayTrigger>
			) : canPostBothShifts ? (
				<div className="request-shifts-button-container">
					<OverlayTrigger
						placement="top"
						trigger={tooltipTrigger}
						overlay={
							<Tooltip show className="invited-tooltip">
								You do not have permission to perform this action. Please contact your Customer Success Rep for details.
							</Tooltip>
						}
					>
						<Dropdown className={classNames({ disabled: !canPostShifts })}>
							<Dropdown.Toggle
								className={classNames('request-shifts', 'secondary-button', 'font-style', 'text-nowrap', {
									'read-only': !canPostShifts
								})}
								id="dropdown-basic"
							>
								Request Shifts
							</Dropdown.Toggle>
							{canPostShifts && (
								<>
									<Dropdown.Menu className="request-shifts__menu">
										{allowOnDemandShifts && (
											<IsAuthorized exactPermission="PORTAL.EDIT">
												<Dropdown.Item
													className="request-shifts__menu-item"
													onClick={() => setShowRequestShiftsVendorWarningModal(true)}
												>
													Request On Demand Shift
												</Dropdown.Item>
											</IsAuthorized>
										)}
										{allowFlexShifts && (
											<IsAuthorized exactPermission="PORTAL.FLEX.ADD_EDIT_SHIFT">
												<Dropdown.Item
													className="request-shifts__menu-item"
													onClick={() => setShowFlexRequestsShiftModal(true)}
												>
													Request Internal Flex Shift
												</Dropdown.Item>
											</IsAuthorized>
										)}
									</Dropdown.Menu>
								</>
							)}
						</Dropdown>
					</OverlayTrigger>
				</div>
			) : (
				<OverlayTrigger
					placement="top"
					trigger={tooltipTrigger}
					overlay={
						<Tooltip show className="invited-tooltip">
							You do not have permission to perform this action. Please contact your Customer Success Rep for details.
						</Tooltip>
					}
				>
					<div className="request-shifts-button-container">
						<Button
							exactPermission={requiredPermission}
							className={classNames('secondary-button font-style text-nowrap', {
								'read-only': tooltipTrigger.length > 0
							})}
							onClick={() => {
								if (hasOnDemandShiftsAccess) {
									setShowRequestShiftsVendorWarningModal(true);
								}
								if (hasFlexShiftsAccess) {
									setShowFlexRequestsShiftModal(true);
								}
							}}
							title="Request Shifts"
						/>
					</div>
				</OverlayTrigger>
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			delinquentAccountReceivableStatus,
			canPostShifts,
			allowOnDemandShifts,
			allowFlexShifts,
			canPostBothShifts,
			requiredPermission,
			hasOnDemandShiftsAccess,
			tooltipTrigger
		]
	);

	return (
		<div className="actions">
			{showGoBackButton ? (
				<button type="button" className="secondary-button font-style text-nowrap" onClick={handleReturn}>
					{`\u276E Return to ${fromGlobalOverview ? 'Global Schedule' : 'Daily Schedule'}`}
				</button>
			) : (
				<>
					{shiftStructures.length && (
						<button
							type="button"
							className={classNames('d-none d-sm-flex primary-button font-style', {
								'no-confirms-pending': slotsToConfirm === 0
							})}
							onClick={() => setShowConfirmationsVendorWarningModal(true)}
						>
							{`${slotsToConfirm} Pending Confirmation${slotsToConfirm > 1 ? 's' : ''} \u276F`}
						</button>
					)}
					{shiftStructures.length > 0 && requestShiftsButton}
				</>
			)}
		</div>
	);
};

export default DailyScheduleActions;
