import React, { memo } from 'react';
import Utils from '../../utils/utils';
import PaperIcon from '../../components/icons/PaperIcon';
import BaseBadge from '../../components/badges/BaseBadge';

const BundleBadge = ({ shiftsCount, bundleId }) => {
	const bundlePhrase = shiftsCount ? `${shiftsCount} ${Utils.pluralize('shift', 's', shiftsCount)} bundle` : 'bundle';
	const tooltipHtml = (
		<>
			This shift is a part <br />
			of a {bundlePhrase}. ID - {bundleId}
		</>
	);

	return <BaseBadge tooltipHtml={tooltipHtml} badgeCopy="BUNDLE" className="bundle-badge" icon={<PaperIcon />} />;
};

export default memo(BundleBadge);
