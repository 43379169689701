import React from 'react';

import { PRIVATE_PATH } from '../../constants';

import AgencyProfessionalsEmployeeDetails from './AgencyProfessionalsEmployeeDetails';

import SidebarWrapper from '../../sections/SidebarWrapper';

const AgencyProfessionalsDetails = (props) => {
	const professionalId = props.match.params.caregiverId;

	const sidebarItems = [
		{
			label: 'Employee Details',
			path: `${PRIVATE_PATH.PROFESSIONALS_EMPLOYEE_DETAILS}/${professionalId}`
		}
	];

	return (
		<SidebarWrapper sidebarTitle="Manage Professional" sidebarItems={sidebarItems} backTitle="Back To Professionals">
			<AgencyProfessionalsEmployeeDetails caregiverId={professionalId} />
		</SidebarWrapper>
	);
};

export default AgencyProfessionalsDetails;
