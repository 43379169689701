import React from 'react';

const ProfessionalsList = ({
	showLoadingSpinner,
	filteredCaregivers,
	Counter,
	CaregiverCard,
	onCaregiverWorkerStatusAction
}) => {
	return (
		!showLoadingSpinner &&
		filteredCaregivers && (
			<>
				{Counter}
				{filteredCaregivers.map((caregiver) => (
					<CaregiverCard
						key={`caregiver-card-${caregiver.id}`}
						caregiver={caregiver}
						onCaregiverWorkerStatusAction={onCaregiverWorkerStatusAction}
					/>
				))}
			</>
		)
	);
};

export default ProfessionalsList;
