import React, { useMemo } from 'react';
import Avatar from '../../../Avatar/CaregiverAvatar';
import Utils from '../../../../utils/utils';
import { ReactComponent as Dot } from '../../../../assets/images/badge-dot.svg';
import AVAILABILITY_BADGES from '../../../../constants/AvailabilityBadges';

import './ProfessionalRow.css';

const ProfessionalRow = ({ professional }) => {
	const shiftsWorked = professional.shifts_worked
		? `${Utils.pluralize(`${professional.shifts_worked} shift`, 's', parseInt(professional.shifts_worked, 10))} worked`
		: '';
	const distance = professional.distance ? `${Math.round(professional.distance)} mi` : null;

	const ProfessionalBadge = ({ status }) => {
		const badgeStyle = AVAILABILITY_BADGES[status].style;
		return (
			<div className="professional-row__badge">
				<div className={`professional-row__badge__pill professional-row__badge__pill--${badgeStyle}`}>
					<Dot className={`professional-row__badge__dot professional-row__badge__dot--${badgeStyle}`} />
					{AVAILABILITY_BADGES[status].name}
				</div>
			</div>
		);
	};

	const shouldDisable = useMemo(() => professional.status === AVAILABILITY_BADGES.ASSIGNED.status, [professional]);

	return (
		<div disabled={shouldDisable} className="professional-row__container">
			<Avatar caregiverId={professional.caregiver_id} />
			<div disabled={shouldDisable} className="professional-row__info">
				<span className="professional-row__info__name">
					{professional.caregiver_name}
					{professional.certification_type ? `, ${professional.certification_type}` : ''}
				</span>
				<span className="professional-row__info__data">
					{shiftsWorked}
					{distance ? `; ${Utils.formatNumber(distance)}` : ''}
				</span>
			</div>
			<ProfessionalBadge status={[professional.status]} />
		</div>
	);
};
export default ProfessionalRow;
