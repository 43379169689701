import React from 'react';
import { Modal } from 'react-bootstrap';
import warningImage from '../../assets/images/alert-warning.svg';

import './ViewOnComputerModal.css';

const ViewOnComputerModal = ({ onCancel, size, customModalBodyClass }) => (
	<Modal
		size={size || 'ms'}
		show
		aria-labelledby="contained-modal-title-vcenter"
		centered
		className="confirmation-modal"
		onHide={onCancel}
	>
		<Modal.Body className={customModalBodyClass}>
			<div className="information">
				<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCancel}>
					<span aria-hidden="true">&times;</span>
				</button>
				<img src={warningImage} alt="View on computer please" />
				<div className="title">View on Larger Screen</div>
				<p className="subtitle">
					This feature is not supported on devices with smaller screens. To view, please use a larger screen or maximize
					the browser window.
				</p>
			</div>
		</Modal.Body>
		<Modal.Footer className="d-flex justify-content-center">
			<button type="button" className="close-button" onClick={onCancel}>
				Close
			</button>
		</Modal.Footer>
	</Modal>
);

export default ViewOnComputerModal;
