import React, { memo, useMemo } from 'react';
import classNames from 'classnames';
import { Dropdown } from 'react-bootstrap';
import moment from 'moment-timezone';
import moreIcon from '../../assets/images/more-horizontal.svg';
import SLOT_STATUSES from '../../constants/slotStatuses';
import MANAGEMENT_COMPANIES_PERMISSIONS from '../../constants/ManagementCompaniesPermissions';
import IsAuthorizedForManagementCompany from '../../components/IsAuthorizedForManagementCompany/IsAuthorizedForManagementCompany';
import Tooltip from '../../components/Tooltip/Tooltip';
import useOverflow from '../../hooks/useOverflow';

export const SLOT_ACTIONS = {
	GO_TO_SHIFT: 'Go to shift',
	REOPEN_SHIFT: 'Reopen shift'
};

const GlobalOverviewSlot = memo(({ shift, slot, customClass = '', showRefreshModal, handleSlotAction, isLastDay }) => {
	const isCancelled = useMemo(() => slot.slot_status === SLOT_STATUSES.FACILITY_CANCELLED, [slot]);
	const { ref: unitNameRef, isOverflowing } = useOverflow(slot);

	const getUnit = (shiftSlot) => {
		return isOverflowing ? (
			<Tooltip label={`${shiftSlot.unit_name}`} placement={isLastDay ? 'left' : 'right'}>
				<div className="slot-title__unit_name" ref={unitNameRef}>
					Unit: <span className="bold">{shiftSlot.unit_name}</span>
				</div>
			</Tooltip>
		) : (
			<div className="slot-title__unit_name" ref={unitNameRef}>
				Unit: <span className="bold">{shiftSlot.unit_name}</span>
			</div>
		);
	};

	const getTitle = (shiftSlot) => {
		if (isCancelled) {
			return shiftSlot.caregiver_name ? (
				<div className="slot-title">
					<span className="slot-title__cancelled-name">{`${shiftSlot.caregiver_name}, ${shiftSlot.caregiver_highest_ranked_licence}`}</span>
					{shiftSlot.unit_name && getUnit(shiftSlot)}
					<span className="slot-title__cancelled-title">Cancelled</span>
				</div>
			) : (
				<span className="slot-title__cancelled-title">Cancelled</span>
			);
		}
		return (
			<div className="slot_title">
				<span>
					{shiftSlot.caregiver_name
						? `${shiftSlot.caregiver_name}, ${shiftSlot.caregiver_highest_ranked_licence}`
						: 'Open'}
				</span>
				{shiftSlot.unit_name && getUnit(shiftSlot)}
			</div>
		);
	};

	const handleClick = (event) => {
		const now = moment.utc();
		if (moment.utc(shift.end_time).isBefore(now)) {
			event.preventDefault();
			showRefreshModal();
			return;
		}
		const action = isCancelled ? SLOT_ACTIONS.REOPEN_SHIFT : SLOT_ACTIONS.GO_TO_SHIFT;
		handleSlotAction(action, shift, slot);
	};

	return (
		<div className={classNames('slot-box', customClass)}>
			<div className="slot-label flex-grow-1">{getTitle(slot)}</div>

			{shift.isUpcomingShift &&
				(isCancelled ? (
					<IsAuthorizedForManagementCompany
						exactPermission={MANAGEMENT_COMPANIES_PERMISSIONS.REOPEN_CANCELLED_SLOT}
						managementCompanyId={slot.management_company_id}
					>
						<Dropdown>
							<Dropdown.Toggle className="action-menu">
								<img src={moreIcon} alt="Show More" className="action-menu-icon" />
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item className="action-menu-item" onClick={handleClick}>
									Reopen Shift
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</IsAuthorizedForManagementCompany>
				) : (
					<Dropdown>
						<Dropdown.Toggle className="action-menu">
							<img src={moreIcon} alt="Show More" className="action-menu-icon" />
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item className="action-menu-item" onClick={handleClick}>
								Go to Shift
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				))}
		</div>
	);
});

export default GlobalOverviewSlot;
